import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import MatchDotsV2 from "../../components/ExcerciseTypes/LineTo/MatchDotsV2";

const json = {
  1: {
    // Exercise num
    unit: "Unit 9",
    id: "4WB-U9-P66-E1",
    component: T6,
    hideBtnFooter: true,
    // exerciseKey: 'img/FriendsPlus/Page65/E1/Key/E1answerKey.png',
    inputSize: 40,
    textAlign: "center",
    maxLength: 30,
    stylesTextInput: {
      background: "none",
      fontSize: 23,
      height: 30,
      width: 250,
      borderBottom: "none",
    },
    titleQuestion: [
      {
        num: "1",
        title: "Read.",
        color: "#234090",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='position:relative'>
        <img style='width:22cm' src="img/FriendsPlus/Page66/E1/1.jpg"/>
        </div>
            
          `,
        answer: [""],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 9",
    id: "4WB-U9-P66-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page66/E2/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: '<div style="font-size:23px">Read again and circle.</div>',
        color: "#234090",
      },
    ],
    component: Circle_Write,
    question: {
      Write: {
        inputStyle: { width: 150, color: "black", textAlign: "center" },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px 5px",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
        },
        selectWordStyle: {
          padding: "5px 5px",

          border: "2px solid black",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          " police_officer / doctor", //0
          "o’clock / fifteen", //1
          "goes_to_work / gets_up", //2
          "Hieu / Hieu’s_dad", //3
          "nine / eleven", //4
        ],
        answers: ["0-6", "1-0", "2-0", "3-4", "4-4"],
        initialValue: [],
      },
      Layout: `
			<img style='width:17cm' src="img/FriendsPlus/Page66/E1/1.jpg"/>
      <div style='display:flex;line-height:50px; font-size: 22px'>
              <div>
                  <div>
                    <b>1</b>&ensp;The boy’s name is <span style='padding:10px; border:2px solid gray; border-radius:50% '><b>Hieu</b></span> / <b>Chang</b>.<br>
                  </div>
                  <div>
                    <b>2</b>&ensp;His dad is a <input id='0' type='Circle'/>.<br>
                    
                  </div>
                  <div>
                    <b>3</b>&ensp;Hieu gets up at six <input id='1' type='Circle'/> in the morning.
                   
                  </div>
                  <div>
                    <b>4</b>&ensp;His dad <input id='2' type='Circle'/> at six o’clock.
                   
                  </div>
                  <div>
                    <b>5</b>&ensp;<input id='3' type='Circle'/> goes home at five forty-five in the afternoon.
                    
                  </div>
                  <div>
                    <b>6</b>&ensp;Hieu’s dad goes to bed at <input id='4' type='Circle'/> o’clock in the evening.
                    
                  </div>
              </div>
      </div>
        
      `,
    },
  },
  3: {
    // Exercise num
    unit: "Unit 9",
    id: "4WB-U9-P66-E3",
    component: MatchDotsV2,
    // hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page66/E3/Key/E3answerKey.png",
    inputSize: 40,
    textAlign: "center",
    maxLength: 30,
    stylesTextInput: {
      background: "none",
      fontSize: 23,
      height: 30,
      width: 250,
      borderBottom: "none",
    },
    titleQuestion: [
      {
        num: "",
        title: "<div style='margin-left:80px'><span style='color:#234090;font-size:30px;font-weight:700'>3</span>&ensp;Draw the times on the clocks.</div>",
        color: "#234090",
      },
    ],
    isHiddenCheck: true,
    question: {
      DrawLines: {
        fontWeight: "120px",
        multipleLine: true,
        boxMatch: [
          //Clock 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "284px",
              left: "975px",
              width: 15,
              height: 15,
              backgroundColor: 'black',
              borderRadius: "50px",
            },
          }, //0/12
          {
            boxMatchStyle: {
              position: "absolute",
              top: "246px",
              left: "974px",
              width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //0/12
          {
            boxMatchStyle: {
              position: "absolute",
              top: "250px",
              left: "994px",
              width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "262px",
              left: "1006px",
                width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "282px",
              left: "1011px",
                width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "302px",
              left: "1007px",
                width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "317px",
              left: "996px",
                width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "325px",
              left: "976px",
                width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "320px",
              left: "957px",
                width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "309px",
              left: "944px",
                width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "287px",
              left: "940px",
                width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "269px",
              left: "944px",
                width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "254px",
              left: "957px",
                width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //11

          //CLOCK 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "637px",
              left: "583px",
                width: 15,
              height: 15,
              backgroundColor: 'black',
              borderRadius: "50px",
            },
          }, //tt
          {
            boxMatchStyle: {
              position: "absolute",
              top: "601px",
              left: "582px",
                width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //12/12
          {
            boxMatchStyle: {
              position: "absolute",
              top: "605px",
              left: "601px",
              width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //13
          {
            boxMatchStyle: {
              position: "absolute",
              top: "617px",
              left: "614px",
              width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //14
          {
            boxMatchStyle: {
              position: "absolute",
              top: "636px",
              left: "620px",
                width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //15
          {
            boxMatchStyle: {
              position: "absolute",
              top: "655px",
              left: "616px",
                width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //16
          {
            boxMatchStyle: {
              position: "absolute",
              top: "671px",
              left: "604px",
                width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //17
          {
            boxMatchStyle: {
              position: "absolute",
              top: "677px",
              left: "584px",
                width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //18
          {
            boxMatchStyle: {
              position: "absolute",
              top: "674px",
              left: "565px",
                width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //19
          {
            boxMatchStyle: {
              position: "absolute",
              top: "661px",
              left: "550px",
                width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //20
          {
            boxMatchStyle: {
              position: "absolute",
              top: "639px",
              left: "544px",
                width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //21
          {
            boxMatchStyle: {
              position: "absolute",
              top: "620px",
              left: "551px",
                width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //22
          {
            boxMatchStyle: {
              position: "absolute",
              top: "606px",
              left: "562px",
                width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //23

          //	CLOCK 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "638px",
              left: "970px",
                width:15,
              height: 15,
              backgroundColor: 'black',
              borderRadius: "50px",
            },
          }, //tt
          {
            boxMatchStyle: {
              position: "absolute",
              top: "602px",
              left: "969px",
              width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //0/8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "606px",
              left: "987px",
              width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //25
          {
            boxMatchStyle: {
              position: "absolute",
              top: "618px",
              left: "1000px",
              width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //26
          {
            boxMatchStyle: {
              position: "absolute",
              top: "637px",
              left: "1008px",
              width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //27
          {
            boxMatchStyle: {
              position: "absolute",
              top: "656px",
              left: "1005px",
              width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //28
          {
            boxMatchStyle: {
              position: "absolute",
              top: "672px",
              left: "992px",
              width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //29
          {
            boxMatchStyle: {
              position: "absolute",
              top: "679px",
              left: "971px",
              width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //30
          {
            boxMatchStyle: {
              position: "absolute",
              top: "673px",
              left: "952px",
              width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //31
          {
            boxMatchStyle: {
              position: "absolute",
              top: "662px",
              left: "937px",
              width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //32
          {
            boxMatchStyle: {
              position: "absolute",
              top: "641px",
              left: "931px",
              width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //33
          {
            boxMatchStyle: {
              position: "absolute",
              top: "622px",
              left: "936px",
              width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //34
          {
            boxMatchStyle: {
              position: "absolute",
              top: "609px",
              left: "950px",
              width: 8,
              height: 8,
              backgroundColor: 'rgb(67,79,161)',
              borderRadius: "50px",
            },
          }, //35



          // kim phút đồng hồ 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "225px",
              left: "974px",
              width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //36
          {
            boxMatchStyle: {
              position: "absolute",
              top: "233px",
              left: "1004px",
              width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //37
          {
            boxMatchStyle: {
              position: "absolute",
              top: "252px",
              left: "1025px",
                width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //38
          {
            boxMatchStyle: {
              position: "absolute",
              top: "283px",
              left: "1034px",
                width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //39
          {
            boxMatchStyle: {
              position: "absolute",
              top: "313px",
              left: "1026px",
                width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //40
          {
            boxMatchStyle: {
              position: "absolute",
              top: "337px",
              left: "1006px",
                width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //41
          {
            boxMatchStyle: {
              position: "absolute",
              top: "347px",
              left: "975px",
                width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //42
          {
            boxMatchStyle: {
              position: "absolute",
              top: "338px",
              left: "945px",
                width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //43
          {
            boxMatchStyle: {
              position: "absolute",
              top: "319px",
              left: "924px",
                width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //44
          {
            boxMatchStyle: {
              position: "absolute",
              top: "286px",
              left: "915px",
                width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //45
          {
            boxMatchStyle: {
              position: "absolute",
              top: "260px",
              left: "930px",
                width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //46
          {
            boxMatchStyle: {
              position: "absolute",
              top: "235px",
              left: "944px",
                width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //47


          //kim phút go home
          {
            boxMatchStyle: {
              position: "absolute",
              top: "580px",
              left: "582px",
                width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, // 48
          {
            boxMatchStyle: {
              position: "absolute",
              top: "590px",
              left: "611px",
                width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //49
          {
            boxMatchStyle: {
              position: "absolute",
              top: "606px",
              left: "632px",
                width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //50
          {
            boxMatchStyle: {
              position: "absolute",
              top: "637px",
              left: "642px",
                width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //51
          {
            boxMatchStyle: {
              position: "absolute",
              top: "668px",
              left: "634px",
                width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //52
          {
            boxMatchStyle: {
              position: "absolute",
              top: "691px",
              left: "614px",
                width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //53
          {
            boxMatchStyle: {
              position: "absolute",
              top: "701px",
              left: "583px",
                width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //54
          {
            boxMatchStyle: {
              position: "absolute",
              top: "692px",
              left: "553px",
                width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //55
          {
            boxMatchStyle: {
              position: "absolute",
              top: "673px",
              left: "532px",
                width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //56
          {
            boxMatchStyle: {
              position: "absolute",
              top: "639px",
              left: "523px",
                width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //57
          {
            boxMatchStyle: {
              position: "absolute",
              top: "616px",
              left: "530px",
                width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //58
          {
            boxMatchStyle: {
              position: "absolute",
              top: "590px",
              left: "548px",
                width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //59


          // kim phút go to bed
          
          {
            boxMatchStyle: {
              position: "absolute",
              top: "579px",
              left: "969px",
              width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //61
          {
            boxMatchStyle: {
              position: "absolute",
              top: "588px",
              left: "997px",
              width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //62
          {
            boxMatchStyle: {
              position: "absolute",
              top: "606px",
              left: "1020px",
              width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //63
          {
            boxMatchStyle: {
              position: "absolute",
              top: "637px",
              left: "1030px",
              width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //64
          {
            boxMatchStyle: {
              position: "absolute",
              top: "669px",
              left: "1021px",
              width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //65
          {
            boxMatchStyle: {
              position: "absolute",
              top: "691px",
              left: "1002px",
              width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //66
          {
            boxMatchStyle: {
              position: "absolute",
              top: "701px",
              left: "970px",
              width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //67
          {
            boxMatchStyle: {
              position: "absolute",
              top: "691px",
              left: "940px",
              width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //68
          {
            boxMatchStyle: {
              position: "absolute",
              top: "673px",
              left: "918px",
              width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //69
          {
            boxMatchStyle: {
              position: "absolute",
              top: "639px",
              left: "910px",
              width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //70
          {
            boxMatchStyle: {
              position: "absolute",
              top: "615px",
              left: "914px",
              width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //71
          {
            boxMatchStyle: {
              position: "absolute",
              top: "590px",
              left: "936px",
              width: 8,
              height: 8,
              backgroundColor: 'gray',
              borderRadius: "50px",
            },
          }, //72

         
        ],
        answers: ['0-8', '0-45', '13-18', '13-51', '26-35', '26-63'],
        initialValue: [],
      },
      Layout: `
      
      <div style='position:relative'>
      <div><i style='font-size:25px; color: rgb(67,79,161)'>*Please select the hour first and the minute after.</i></div>
      <img src="img/FriendsPlus/Page66/E3/1.jpg" style='width:114%;margin-left:-70px'/>
      <input id='0' type='boxMatch' />
      <input id='1' type='boxMatch' />
      <input id='2' type='boxMatch' />
      <input id='3' type='boxMatch' />
      <input id='4' type='boxMatch' />
      <input id='5' type='boxMatch' />
      <input id='6' type='boxMatch' />
      <input id='7' type='boxMatch' />
      <input id='8' type='boxMatch' />
      <input id='9' type='boxMatch' />
      <input id='10' type='boxMatch' />
      <input id='11' type='boxMatch' />
      <input id='12' type='boxMatch' />
      
      
      <input id='13' type='boxMatch' />
      <input id='14' type='boxMatch' />
      <input id='15' type='boxMatch' />
      <input id='16' type='boxMatch' />
      <input id='17' type='boxMatch' />
      <input id='18' type='boxMatch' />
      <input id='19' type='boxMatch' />
      <input id='20' type='boxMatch' />
      <input id='21' type='boxMatch' />
      <input id='22' type='boxMatch' />
      <input id='23' type='boxMatch' />
      <input id='24' type='boxMatch' />

      
      <input id='25' type='boxMatch' />
      <input id='26' type='boxMatch' />
      <input id='27' type='boxMatch' />
      <input id='28' type='boxMatch' />
      <input id='29' type='boxMatch' />
      <input id='30' type='boxMatch' />
      <input id='31' type='boxMatch' />
      <input id='32' type='boxMatch' />
      <input id='33' type='boxMatch' />
      <input id='34' type='boxMatch' />
      <input id='35' type='boxMatch' />
      <input id='36' type='boxMatch' />


      <input id='37' type='boxMatch' />
      <input id='38' type='boxMatch' />
      <input id='39' type='boxMatch' />
      <input id='40' type='boxMatch' />
      <input id='41' type='boxMatch' />
      <input id='42' type='boxMatch' />
      <input id='43' type='boxMatch' />
      <input id='44' type='boxMatch' />
      <input id='45' type='boxMatch' />
      <input id='46' type='boxMatch' />
      <input id='47' type='boxMatch' />
      <input id='48' type='boxMatch' />
      <input id='49' type='boxMatch' />
      <input id='50' type='boxMatch' />

      <input id='51' type='boxMatch' />
      <input id='52' type='boxMatch' />
      <input id='53' type='boxMatch' />
      <input id='54' type='boxMatch' />
      <input id='55' type='boxMatch' />
      <input id='56' type='boxMatch' />
      <input id='57' type='boxMatch' />
      <input id='58' type='boxMatch' />
      <input id='59' type='boxMatch' />
      <input id='60' type='boxMatch' />
      <input id='61' type='boxMatch' />
      <input id='62' type='boxMatch' />


      <input id='63' type='boxMatch' />
      <input id='64' type='boxMatch' />
      <input id='65' type='boxMatch' />
      <input id='66' type='boxMatch' />
      <input id='67' type='boxMatch' />
      <input id='68' type='boxMatch' />
      <input id='69' type='boxMatch' />
      <input id='70' type='boxMatch' />
      <input id='71' type='boxMatch' />
      <input id='72' type='boxMatch' />
      <input id='73' type='boxMatch' />
      <input id='74' type='boxMatch' />
     
      
      
     
      

      </div>


      `,
    },
  },
};

export default json;
