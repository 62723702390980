// import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
// import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    // Exercise num
    unit: "Stater",
    id: "4WB-S-P5-E1",
    audio: "",
    video: "",
    hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page5/E1/Key/answerKey.png",
    component: Circle_Write,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 22,
      height: 27,
      // textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Write your friend’s name. Circle the correct word and write. Then draw your friend.",
        color: "#5870a9",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 150, color: "black", textAlign: "center" },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "3px 3px",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
        },
        selectWordStyle: {
          padding: "3px 3px",

          border: "2px solid black",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          "seven eight nine", //1
          "brown black red", //2
          "long short", //3
          "straight curly",
          "brown blue gray green",
        ],
        answers: ["0-0", "1-4", "2-0", "3-4", "4-0", "5-0"],
        initialValue: [],
      },
      Layout: `

      <div style='display:flex;line-height:43px; font-size: 22px'>
              <div>
                  <div>
                    <b>1</b>&ensp;His / Her name’s <input id='0'></input>.<br>
                  </div>
                  <div>
                    <b>2</b>&ensp;He’s / She’s <input id='1'></input>.<br>
                    <div style='border: 2px solid rgb(26 183 238);border-radius: 10px;text-align:center;width:70%'><input id='0' type='Circle'/></div>
                  </div>
                  <div>
                    <b>3</b>&ensp;He / She has <input id='2'></input> hair.<br>
                    <div style='border: 2px solid rgb(26 183 238);border-radius: 10px;text-align:center;width:70%'><input id='1' type='Circle'/></div>
                  </div>
                  <div>
                    <b>4</b>&ensp;He / She has <input id='3'></input> hair.<br>
                    <div style='border: 2px solid rgb(26 183 238);border-radius: 10px;text-align:center;width:70%'><input id='2' type='Circle'/></div>
                  </div>
                  <div>
                    <b>5</b>&ensp;He / She has <input id='4'></input> hair.<br>
                    <div style='border: 2px solid rgb(26 183 238);border-radius: 10px;text-align:center;width:70%'><input id='3' type='Circle'/></div>
                  </div>
                  <div>
                    <b>6</b>&ensp;He / She has <input id='5'></input> eyes.<br>
                    <div style='border: 2px solid rgb(26 183 238);border-radius: 10px;text-align:center;'><input id='4' type='Circle'/></div>
                  </div>
              </div>

              <img style='height:10cm; margin-left:15px' src='img/FriendsPlus/Page5/E1/1.jpg' />
      </div>
        
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Stater",
    id: "4WB-S-P5-E2",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page5/E2/Key/E2answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 250,
    textAlign: "center",
    maxLength: 20,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "2",
        title: '<div style="font-size:23px">Order the words. Match.</div>',
        color: "#5870a9",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 22,
      height: 25,
    },
    questions: [
      {
        title: `
					<div style='position: relative'>
						<img style="width:22cm;" src='img/FriendsPlus/Page5/E2/1.jpg' />
						<div style="position: absolute; top:264px; left: 444px "><input id='0' width='320px'></input></div>
						<div style="position: absolute; top:345px; left: 447px"><input id='1' width='320px'></input></div>
						<div style="position: absolute; top:264px; left: 776px"><input id='2' width='50px'></input></div>
						<div style="position: absolute; top:345px; left: 776px"><input id='3' width='50px'></input></div>
					</div>
 
          `,
        answer: [`This is Rosy's dad.`, `Tim is Rosy's cousin.`, `b`, `a`],
      },
    ],
  },
};

export default json;
