import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 11',
		id: '4WB-U11-P79-E1',
		audio: 'Audios/Track 40.mp3',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page79/E1/Key/E1answerKey.png',
		inputSize: 105,
		textAlign: 'center',
		maxLength: 1,
		stylesTextInput: {
			background: 'none',
			// borderBottom: 'none',
			fontSize: 23,
			height: 30,
			width: 33,
		},
		titleQuestion: [
			{
				num: '1',
				title:
					"Complete the words with <i>er, ir</i> or <i>ur</i>. Listen and check. <headphone name='40' src='Audios/Track 40.mp3'></headphone>",
				color: '#234090',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `

        <div style='position: relative; max-width:800px' >
            <img style='width:23cm' src="img/FriendsPlus/Page79/E1/1.jpg" />    
            <div style='display: flex; justify-content: center; width: 860px;'>
              <div style="width:20%; margin-left:40px">h<u style='color:gray'>ur</u>t</div>
              <div style="width:29%;">g##ms</div>

           <div style="width:25%; margin-left:28px">b##d</div>
              <div style="width:24%; margin-left: 22px">f##n</div>
              <div style="width:23%; margin-left:26px">g##l</div>
              <div style="width:25%; margin-left:16px">n##se</div>
            </div>
        </div>
            
          `,
				answer: ['e', 'r', 'i', 'r', 'e', 'r', 'i', 'r', 'u', 'r'],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 11',
		id: '4WB-U11-P79-E2',
		audio: 'Audios/Track 41.mp3',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page79/E2/Key/E2answerKey.png',
		inputSize: 105,
		textAlign: 'center',
		maxLength: 6,
		stylesTextInput: {
			background: 'none',
			// borderBottom: 'none',
			fontSize: 22,
			height: 30,
			width: 150,
		},
		titleQuestion: [
			{
				num: '2',
				title:
					"Write. Listen and chant. <headphone name='41' src='Audios/Track 41.mp3'></headphone>",
				color: '#234090',
			},
		],
		hintBox: [
			{
				src: [
					'bird',
					'fern',
					'<s>hurts</s>',
					'girl',
					'germs',
					'nurse',
					'purse',
				],
				width: 750,
				borderColor: '#234090',
				bgColor: 'none',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
        <div style='margin-bottom: 10px'><hintbox id='0'></hintbox></div>
        <div style='position: relative; max-width:800px; font-size: 22px' >   
            <div style='display: flex; justify-content: center; width: 860px;'>
              <div style='width:50%'>
                <div>The girl <sup>1 </sup><u style='color:gray'>hurts</u> her fingers.</div>
                <div>She goes to the <sup>2 </sup># .</div>
                <div>The nurse gets some band aids</div>
                <div>From her very big <sup>3 </sup># .</div>
                <div>Here is a talking <sup>4 </sup># .</div>
                <div>It’s perching in a <sup>5 </sup># ,</div>
              </div>
              <div style='width:50%'>
                <div>"Be careful, little <sup>6 </sup># ,</div>
                <div>You don’t want <sup>7 </sup># !"</div>
              </div>
            </div>
            <img style='width:13cm; margin-left: 100px' src="img/FriendsPlus/Page79/E2/1.jpg" /> 
        </div>
            
          `,
				answer: ['nurse', 'purse', 'bird', 'fern', 'girl', 'germs'],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 11',
		id: '4WB-U11-P79-E3',
		audio: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page79/E3/Key/E3answerKey.png',
		inputSize: 200,
		textAlign: 'center',
		maxLength: 10,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '3',
				title: ' Write the words in the correct boxes.',
				color: '#234090',
			},
		],
		hintBox: [
			{
				src: [
					"<s style='color:gray'>birthday</s>",
					'fern',
					'germs',
					'purse',
					'skirt',
					'Thursday',
				],
				width: 750,
				borderColor: '#234090',
			},
		],
		questions: [
			{
				title: `
        <div style='position:relative;'></div>
        <hintbox id='0' ></hintbox>
        <div style='display:flex; position: absolute; top: 153px'>
          <div style='width: 50px; border-radius:5px; margin:18px; margin-left:90px; text-align:center ;background-color:rgb(141,216,248); border:none; color:rgb(81,128,190); padding-right:10x; position:absolute; top: 14px; left:10px'>er</div>
          <div style='width: 50px; border-radius:5px; margin:18px; margin-left:90px; text-align:center ;background-color:rgb(141,216,248); border:none; color:rgb(81,128,190); padding-right:10x; position:absolute; top: 14px; left:267px'>ir</div>
          <div style='width: 50px; border-radius:5px; margin:18px; margin-left:90px; text-align:center ;background-color:rgb(141,216,248); border:none; color:rgb(81,128,190); padding-right:10x; position:absolute; top: 14px; left:517px'>ur</div>
        </div> 
            
         
        <div style='display:flex;margin-top:40px; line-height:50px; text-align:center' >
        <div style='width:30%; border: 2px solid rgb(202, 202, 202); border-radius:30px; padding-top:40px; margin:20px; padding-bottom:20px'>
              <div> # </div>
              <div> # </div>
          </div>
          <div style='width:30%; border: 2px solid rgb(202, 202, 202); border-radius:30px; padding-top:40px; margin:20px; padding-bottom:20px'>
              <div><u style='color:gray'>&ensp;&ensp;&ensp;&ensp;birthday&ensp;&ensp;&ensp;&ensp;</u></div>  
              <div> # </div> 
          </div>
          
          <div style='width:30%; border: 2px solid rgb(202, 202, 202); border-radius:30px; padding-top:40px; margin:20px; padding-bottom:20px'>
              <div> # </div>
              <div> # </div>  
          </div>
        </div>   
          `,
				answer: [
					'fern/germs',
					'fern/germs',
					'skirt',
					'purse/Thursday',
					'purse/Thursday',
				],
			},
		],
	},
}

export default json
