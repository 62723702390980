import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    unit: "Unit 10",
    id: "4WB-U10-P73-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page73/E1/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
				Complete the crossword.
			  `,
        color: "#234090",
      },
    ],
    titleImage: "",
    questionImage: [],
    inputSize: 10,
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 23,
      height: 29,
      // textTransform: 'uppercase',
      width: 40,
    },
    maxLength: 1,
    textAlign: "center",
    // hideBtnFooter: true,
    isHiddenCheck: true,
    selectStyle: {
      color: "#00ADFE",
      width: 30,
      textAlign: "center",
    },
    questions: [
      {
        title: `
					<div style=" position: relative; width:700px ">
            <img src='img/FriendsPlus/Page73/E1/1.jpg' style='width:25cm'>
             <div style=" position: absolute; margin-top: -312px; margin-left: 546px "><input id='0''></input></div>
             <div style=" position: absolute; margin-top: -260px; margin-left: 546px "><input id='1'></input></div>
             <div style=" position: absolute; margin-top: -208px; margin-left: 546px" ><input id='2'></input></div>
             <div style=" position: absolute; margin-top: -157px; margin-left: 546px" ><input id='3'></input></div>
             <div style=" position: absolute; margin-top: -312px; margin-left: 597px "><input id='4'></input></div>
             <div style=" position: absolute; margin-top: -260px; margin-left: 446px "><input id='5'></input></div>
             <div style=" position: absolute; margin-top: -208px; margin-left: 446px "><input id='6'></input></div>
             <div style=" position: absolute; margin-top: -157px; margin-left: 446px "><input id='7'></input></div>
             <div style=" position: absolute; margin-top: -105px; margin-left: 446px "><input id='8'></input></div>
             <div style=" position: absolute; margin-top: -53px; margin-left: 446px "><input id='9'></input></div>
             <div style=" position: absolute; margin-top: -208px; margin-left: 395px "><input id='10'></input></div>
             <div style=" position: absolute; margin-top: -208px; margin-left: 496px "><input id='11'></input></div>
             <div style=" position: absolute; margin-top: -53px; margin-left: 294px "><input id='12'></input></div>
             <div style=" position: absolute; margin-top: -53px; margin-left: 343px "><input id='13'></input></div>
             <div style=" position: absolute; margin-top: -53px; margin-left: 395px "><input id='14'></input></div>
					</div>
			  `,
        answer: [
          "c",
          "o",
          "r",
          "n",
          "a",
          "s",
          "t",
          "o",
          "r",
          "m",
          "s",
          "a",
          "f",
          "a",
          "r",
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 10",
    id: "4WB-U10-P73-E2",
    audio: "Audios/Track 37.mp3",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "2",
        title: `
				Write. Listen and chant. <headphone name='37' src='Audios/Track 37.mp3'></headphone>
			  `,
        color: "#234090",
      },
    ],
    hintBox: [
      {
        src: ["<s>car</s>", "shark", "park", "short", "storm", "dark"],
        borderColor: "#234090",
        // borderColor: '#C0F3FE',
        width: 600,
        textAlign: "center",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 24,
      height: 26,
      // textTransform: 'uppercase',
      width: 100,
    },
    checkDuplicated: true,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page73/E2/Key/E2answerKey.png",
    inputSize: 220,
    textAlign: "center",
    // titleImage: "img/FriendsPlus/Page48/E3/1.jpg",
    maxLength: 5,
    questionImage: [],
    questions: [
      {
        title: `
      <hintbox id="0"></hintbox>
      <div style=" display: flex ">
        <div>
        <img src='img/FriendsPlus/Page73/E2/1.jpg' style='width: 15cm'>
        </div>
        <div style=" width: max-content ">
         <div> <span>We're in the </span> <sup>1 </sup> <u style='color: gray'>&ensp;&ensp;car&ensp;&ensp;</u> <span>,</span></div>
         <div> <span>After playing in the </span> <sup>2 </sup> <input id="0"></input> <span>.</span></div>
         <div> <span>I have a </span> <sup>3 </sup> <input id="1"></input> <span>.</span></div>
         <div> <span>I have a toy </span> <sup>4 </sup> <input id="2"></input> <span>.</span></div>
         <div> <span>Here comes a</span> <sup>5 </sup> <input id="3"></input> <span>.</span></div>
         <div> <span>It's getting</span> <sup>6 </sup> <input id="4"></input> <span>.</span></div>
        </div>
      </div>
        `,
        answer: ["park", "short", "shark", "storm", "dark"],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 10",
    id: "4WB-U10-P73-E3",
    audio: "",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title: `
				Count and write.
			  `,
        color: "#234090",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 24,
      height: 26,
      // textTransform: 'uppercase',
    },
    checkDuplicated: true,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page73/E3/Key/E3answerKey.png",
    inputSize: 200,
    textAlign: "center",
    // titleImage: "img/FriendsPlus/Page48/E3/1.jpg",
    maxLength: 20,
    questionImage: [],
    questions: [
      {
        title: `
      <div style=" display: flex ">
        <div style=" margin-top: 30px; line-height: 45px ">
         <div> <span>1. There's one </span> <u style='color: gray'>&ensp;&ensp;storm&ensp;&ensp;</u> <span>,</span></div>
         <div> <span>2. There are two </span> <input id="0"></input> <span>.</span></div>
         <div> <span>3. There are three </span> <input id="1"></input> <span>.</span></div>
         <div> <span>4. There are four </span> <input id="2"></input> <span>.</span></div>
         <div> <span>5. There are five</span> <input id="3"></input> <span>.</span></div>
        </div>
        <div>
        <img src='img/FriendsPlus/Page73/E3/1.jpg' style='width:10cm'>
        </div>
      </div>
        `,
        answer: ["parks", "stars", "cars", "horns"],
      },
    ],
  },
};

export default json;
