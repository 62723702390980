import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_WriteSelect from "../../components/ExcerciseTypes/Circle_WriteSelect";

const json = {
  1: {
    // Exercise num
    unit: "Unit 7",
    id: "4WB-U7-P55-E1",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page55/E1/Key/E1answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 150,
    textAlign: "center",
    maxLength: 8,
    isHiddenCheck: true,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 23,
      height: 27,
      // textTransform: 'uppercase',
    },

    titleQuestion: [
      {
        num: "1",
        title: "Write the words in the correct boxes.",
        color: "#234090",
      },
    ],
    hintBox: [
      {
        src: [
          "<s style='color:gray'>make</s>",
          "<s style='color:gray'>cold</s>",
          "happy",
          "go",
          "ride",
          "hot",
          "eat",
          "sunny",
        ],
        width: 350,
        borderColor: "rgb(233,59,60)",
      },
    ],
    questions: [
      {
        title: `
        <div style='margin-top:30px; display:flex; position:relative; width:fit-content'>
        <hintbox id='0' ></hintbox>
        <div style='display:flex; position: absolute; top: 153px'>
            <div style='width:fit-content; border-radius:5px; margin:18px; margin-left:90px; text-align:center ;background-color:rgb(252,209,192); border:none; color:rgb(233,59,60); padding:6px; position:absolute; top: -182px; left:346px'>verbs</div>
            <div style='width:fit-content; border-radius:5px; margin:18px; margin-left:90px; text-align:center ;background-color:rgb(252,209,192); border:none; color:rgb(233,59,60); padding:6px; position:absolute; top: -182px; left:571px'>adjectives</div>
        </div> 

        <div style='display:flex; line-height:50px; text-align:center; width:500px' >
          <div style='width:50%; border: 1px solid rgb(202, 202, 202); border-radius:20px; padding-top:40px; margin:10px; padding-bottom:20px''>
              <div><u style='color:gray'>&ensp;&ensp;make&ensp;&ensp;</u> </div>
              <div> # </div>
              <div> # </div>
              <div> # </div>
          </div>
          <div style='width:50%; border: 1px solid rgb(202, 202, 202); border-radius:20px; padding-top:40px; margin:10px; padding-bottom:20px''>
					<div><u style='color:gray'>&ensp;&ensp;cold&ensp;&ensp;</u> </div>
              <div> # </div>
              <div> # </div>
              <div> # </div>
          </div>
        </div>
        </div>
        
            
          `,
        answer: [
          "go/ride/eat",
          "go/ride/eat",
          "go/ride/eat",
          "happy/hot/sunny",
          "happy/hot/sunny",
          "happy/hot/sunny",
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 7",
    id: "4WB-U7-P55-E2",
    audio: "",
    video: "",
    // exerciseKey: "img/FriendsPlus/Page25/E1/Key/E1answerKey.png",
    component: Circle_WriteSelect,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Circle today’s weather. Choose the important actions. Write (✓) or (✗).",
        color: "#234090",
      },
    ],
    selectStyle: {
      height: 35,
      width: 32,
      backgroundColor: "none",
      border: "none",
      textAlign: "center",
    },
    selectOptionRandom: true,
    selectOptionValues: ["✓", "✗"],
    question: {
      Write: {
        inputStyle: { width: 150, color: "black", textAlign: "center" },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px 5px",
          borderRadius: "50%",
          //   fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
        },
        selectWordStyle: {
          padding: "5px 5px",

          border: "2px solid black",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          "rainy &ensp; windy &ensp; hot &ensp; cold &ensp; snowy &ensp; sunny",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
		  <img  style='width: 23cm' src='img/FriendsPlus/Page55/E2/1.jpg' />
		  <div style='position: relative;'>
		  <div style="position: absolute; top: -212px; left: 59px; color:gray "><input id='0' type='Circle'/></div>
			<div style="position: absolute; top: -150px; left: 193px; "><select id=0></select></div>
			<div style="position: absolute; top: -150px; left: 369px; "><select id=1></select></div>
			<div style="position: absolute; top: -150px; left: 634px; "><select id=2></select></div>
			<div style="position: absolute; top: -106px; left: 220px; "><select id=3></select></div>
			<div style="position: absolute; top: -106px; left: 471px; "><select id=4></select></div>          
			<div style="position: absolute; top: -106px; left: 731px; "><select id=5></select></div>
		  
		  </div> 
		  
		  `,
    },
  },
  3: {
    // Exercise num
    unit: "Unit 7",
    id: "4WB-U7-P55-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page55/E3/Key/E3answerKey.png",
    hideBtnFooter: true,
    component: T6,
    // totalInput: 2,
    titleQuestion: [
      {
        num: "3",
        title:
          "Draw and write a weather report for today, and explain the things you can and can’t do.",
        color: "#234090",
      },
    ],
    selectStyle: {
      height: 35,
      width: 35,
      backgroundColor: "none",
      border: "none",
      textAlign: "center",
    },
    textareaStyle: {
      // background: 'gray',
      width: 300,
      fontSize: 21,
      height: 100,
    },
    questionImage: [],
    questions: [
      {
        title: `
        <img  style='width: 20cm' src='img/FriendsPlus/Page55/E3/1.jpg' />
        <div style='position: relative; font-size:21px'>
          <div style="position: absolute; top: -361px; left: 375px; ">Today, it’s <textarea id='0' row='3'></div>
          <div style="position: absolute; top: -205px; left: 375px; ">Don’t <textarea id='1' row='3'></div>          
        
        </div> 
        `,
        answer: ["", ""],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "4WB-U7-P55-E4",
    audio: "",
    video: "",
    hideBtnFooter: true,
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Explain the weather report for today to a friend. Say what he / she can and can’t do.",
        color: "#234090",
        // prefix: { icons: ["far fa-comment"] },
      },
    ],
    questionImage: [],
  },
};

export default json;
