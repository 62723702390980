import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import PaintColorType from "../../components/ExcerciseTypes/Design/PaintColorType";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 10",
    id: "4WB-U10-P72-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page72/E1/Key/E1answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "1",
        title: `
        Look at the picture and choose the best answer.
			  `,
        color: "#2284c8",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "3px 3px",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
        },
        selectWordStyle: {
          padding: "3px 3px",

          border: "2px solid black",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `A <br> B <br> C`, //1
          `A <br> B <br> C`, //1
          `A <br> B <br> C`, //1
        ],
        answers: ["0-8", "1-0", "2-4"],
        initialValue: [],
      },
      Layout: `
      <div>
	        <img src='img/FriendsPlus/Page72/E1/1.jpg' style='max-width:80%'>
        <div style="display:flex">
            <div>
            <b>1.</b>&ensp;
              Do Max and Jerry like fishing? <br>
            <div style="display: flex;margin-left:40px; line-height: 45px">
		          <div style='margin-left:20px'>
                <b>A </b>Yes, he does. <br>
                <b style='padding: 5px; border: 2px solid gray; border-radius: 50%'>B</b> Yes, they do. <br>
                <b>C </b>It's too cold.
              </div>
            </div>

            <b>2.</b>&ensp;
              Does Fred like fishing?
            <div style="display: flex;margin-left:40px; line-height: 45px">
		          <div><input id='0' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	        Yes, they do.<br>
                Yes, he does.<br>
                No, he doesn't.
		          </div>
            </div>
            </div>

            <div style="margin-left: 30px; line-height: 45px">
            <b>3.</b>&ensp;
              Do Fred and Max like playing volleyball?
            <div style="display: flex;margin-left:40px">
		          <div><input id='1' type='Circle'/></div>
		          <div style='margin-left:20px'>
		  	        No, they don't. <br>
                Jerry is happy.<br>
                Yes, they do
		          </div>
            </div>
            <b>4.</b>&ensp;
              Does Jerry like playing volleyball?
            <div style="display: flex;margin-left:40px">
            	<div><input id='2' type='Circle'/></div>
		          <div style='margin-left:20px'>
                No, they don't.<br>
                Yes, he does.<br>
                No, he doesn't.
		          </div>
            </div>
            </div>
      </div>
      </div>


        
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Unit 10",
    id: "4WB-U10-P72-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page72/E2/Key/E2answerKey.png",
    // stylesTextInput: { backgroundColor: 'transparent', border: 'none' },
    inputSize: 90,
    maxLength: 15,
    textAlign: "center",
    // titleImage: "img/FriendsPlus/Page72/E2/1.jpg",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 24,
      height: 28,
      // textTransform: 'uppercase',
      textAligh: "center",
      width: 150,
    },
    titleQuestion: [
      {
        num: "2",
        title: "Complete the questions and answers.",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=' display: flex' >
         <div style=' width: 500px; '>
           <div>
             <span>1. Do they like </span>
             <u style='color: gray'>&ensp;&ensp;reading&ensp;&ensp;</u>
             <span> comics?</span>
           </div>
           <div>
             <span>2. Do they like </span>
             <input id="0"></input>
             <span> badminton?</span>
           </div>
           <div>
             <span>3. Do they like </span>
             <input id="1"></input>
             <span> chess?</span>
           </div>
         </div>
         <div>
           <div>
             <span>Yes, </span>
             <input id="2"></input>
             <span> .</span>
           </div>
           <div>
             <span>No, </span>
             <input id="3"></input>
             <span> .</span>
           </div>
           <div>
             <span>Yes, </span>
             <input id="4"></input>
             <span> .</span>
           </div>
         </div>
        </div>
        `,
        answer: ["playing", "playing", "they do", "they don't", "they do"],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 10",
    id: "4WB-U10-P72-E3",
    audio: "Audios/Track 36.mp3",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page72/E3/Key/E3answerKey.png",
		isHiddenCheck:true,
    inputSize: 90,
    maxLength: 9,
    textAlign: "center",
    // titleImage: "img/FriendsPlus/Page72/E2/1.jpg",
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 24,
      height: 28,
      // textTransform: 'uppercase',
      textAligh: "center",
      width: 150,
    },
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and write. <headphone name='36' src='Audios/Track 36.mp3'></headphone>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" display: flex" >
         
         <img src='img/FriendsPlus/Page72/E3/1.jpg' style='width:400px'>
         
         <div style=' width: 500px; margin-top: 10px; margin-left: 25px '>
           <span>Dad </span>
           <sup>1 </sup>
           <u style='color: gray'>&ensp;&ensp;likes&ensp;&ensp;</u>
           <span> playing basketball,</span><br/>
           <span>He also likes playing </span>
           <sup>2 </sup>
           <input id="1"></input>
           <span>.</span><br/>
           <span>Mom likes </span>
           <sup>3 </sup>
           <input id="2"></input>
           <span>tennis,</span><br/>
           <span>But I </span>
           <sup>4 </sup>
           <input id="3"></input>
           <span>fishing best!</span><br/>
           <span>Mom likes </span>
           <sup>5 </sup>
           <input id="4"></input>
           <span>pictures,</span><br/>
           <span>And </span>
           <sup>6 </sup>
           <input id="5"></input>
           <span>like drawing, too.</span><br/>
           <span>Dad likes taking </span>
           <sup>7 </sup>
           <input id="6"></input>
           <span>,</span><br/>
           <span>So </span>
           <sup>8 </sup>
           <input id="7"></input>
           <span>have lots to do!</span>

         </div>
        </div>
        `,
        answer: ["chess", "playing", "like", "drawing", "I", "photos", "we"],
      },
    ],
  },
};

export default json;
