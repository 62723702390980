import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 5',
		id: '4WB-U5-P36-E1',
		audio: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page36/E1/Key/E1answerKey.png',
		// exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
		inputSize: 150,
		textAlign: 'center',
		maxLength: 20,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '1',
				title: 'Write.',
				color: '#234090',
			},
		],
		stylesTextInput: {
			background: 'none',
			// borderBottom: "none",
			fontSize: 23,
			width: 200,
		},
		questions: [
			{
				title: `
       <div style='display: flex; flex-direction: column'>

        <img style='width:20cm' src='img/FriendsPlus/Page36/E1/1.jpg' />
        <div style='display:flex'>
            <div style="margin-right:121px; width:35% ; margin-top:20px">#</div>
            <div style="margin-right:121px; width:35% ; margin-top:20px">#</div>
            <div style=" width:35% ; margin-top:20px">#</div>
        </div>

        <img style='width:20cm; margin-top:70px; margin-bottom:20px' src='img/FriendsPlus/Page36/E1/2.jpg' />
        <div style='display:flex'>
            <div style="margin-right:121px; width:35% ; margin-top:20px">#</div>
            <div style="margin-right:121px; width:35% ; margin-top:20px">#</div>
            <div style=" width:35% ; margin-top:20px">#</div>
        </div>
     
      </div>
            
          `,
				answer: [
					'surf',
					'go on a boat ',
					'read',
					'collect shells',
					'snorkle',
					'play with a ball',
				],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 5',
		id: '4WB-U5-P36-E2',
		audio: 'Audios/Track 18.mp3',
		video: '',
		component: MatchDots,
		exerciseKey: 'img/FriendsPlus/Page36/E2/Key/E2answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title:
					" Listen and draw a line.<headphone name='18' src='Audios/Track 18.mp3'></headphone>",
				color: '#2E479B',
			},
		],
		question: {
			DrawLines: {
				// multipleLine: true,
				boxMatch: [
					// {
					//   boxMatchStyle: {
					//     position: "absolute",
					//     top: "49px",
					//     left: "114px",
					//     width: 126,
					//     height: 86,
					//     backgroundColor: "none",
					//     border: "0px solid",
					//   },
					// }, //0
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '174px',
							left: '114px',
							width: 126,
							height: 86,
							backgroundColor: 'none',
							border: '0px solid',
						},
					}, //1
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '300px',
							left: '114px',
							width: 126,
							height: 86,
							backgroundColor: 'none',
							border: '0px solid',
						},
					}, //2
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '427px',
							left: '114px',
							width: 126,
							height: 86,
							backgroundColor: 'none',
							border: '0px solid',
						},
					}, //3
					//right
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '40px',
							left: '422px',
							width: 169,
							height: 41,
							backgroundColor: 'none',
							border: '0px solid',
						},
					}, //4
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '96px',
							left: '422px',
							width: 169,
							height: 41,
							backgroundColor: 'none',
							border: '0px solid',
						},
					}, //5
					// {
					//   boxMatchStyle: {
					//     position: "absolute",
					//     top: "160px",
					//     left: "422px",
					//     width: 169,
					//     height: 41,
					//     backgroundColor: "none",
					//     border: "0px solid",
					//   },
					// }, //6
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '214px',
							left: '422px',
							width: 169,
							height: 37,
							backgroundColor: 'none',
							border: '0px solid',
						},
					}, //7
				],
				answers: ['0-5', '1-3', '2-4'],
				initialValue: [],
			},
			Layout: `
      <img style='width: 16.5cm; margin-bottom:30px' src="img/FriendsPlus/Page36/E2/1.jpg" />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          
      `,
		},
	},
}

export default json
