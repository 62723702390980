import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import { red } from "@material-ui/core/colors";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "4WB-U1-P12-E1",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page12/E1/Key/E1answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 250,
    textAlign: "center",
    maxLength: 30,
    stylesTextInput: {
      background: "none",
      fontSize: 25,
      height: 28,
      width: 260,
      borderBottom: "2px solid gray",
    },
    titleQuestion: [
      {
        num: "1",
        title: "Look and write.",
        color: "#234090",
      },
    ],
    hintBox: [
      {
        src: [
          "police officers",
          "<s style='color:gray'>farmers</s>",
          "help everyone",
          "grow food",
        ],
        width: 700,
        borderColor: "rgb(133,208,151)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <hintbox id='0' style='border-radius:2px'></hintbox>
        <img src="img/FriendsPlus/Page12/E1/1.jpg" />
        <div style='display:flex;margin-top:20px; line-height:60px' >
          <div style='width:50%;'>
              <div>They’re <u style='color:gray ' >&ensp;farmers.</div>
              <div>They # </div>
          </div>
          <div style='width:72%; padding-left:149px'>
              <div>They’re # </div>
              <div>They # </div>
          </div>
        </div>
            
          `,
        answer: ["grow food", "police officers", "help everyone"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "4WB-U1-P12-E2",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page12/E2/Key/E2answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 180,
    textAlign: "center",
    maxLength: 180,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 22,
      height: 27,
    },
    titleQuestion: [
      {
        num: "2",
        title: " Look and read. Write. ",
        color: "#234090",
      },
    ],

    questions: [
      {
        title: `
       
        <img style='width:16cm' src="img/FriendsPlus/Page12/E2/1.jpg" />
        <div style='font-size:22px; margin-top:20px; line-height:40px; width:100%' >
          <div ><b>1.</b>&ensp; They have a police car. <u style='color:gray; padding-left:202px; text-align:center' >&ensp; police officers&ensp;</div>
          <div style='display:flex'><b>2.</b>&ensp; They help sick people.<div style='padding-left:206px'>#</div></div>
          <div style='display:flex'><b>3.</b>&ensp;  They grow apples and oranges.<div style='padding-left:117px'>#</div></div>
          <div style='display:flex'><b>4.</b>&ensp; They fight fires.<div style='padding-left:275px'>#</div></div>
          <div style='display:flex'><b>5.</b>&ensp; They fly planes.<div style='padding-left:272px'>#</div></div>
          <div style='display:flex'><b>6.</b>&ensp; They go to school.<div style='padding-left:247px'>#</div></div>
          
          
        </div>
            
          `,
        answer: ["doctors", "farmers", "firefighters", "pilots", "students"],
      },
    ],
  },
};

export default json;
