import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'
const json = {
	1: {
		// Exercise num
		unit: 'Unit 11',
		id: '4WB-U11-P80-E1',
		// audio: 'Audios/Track 39.mp3',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page80/E1/Key/E1answerKey.png',
		inputSize: 100,
		textAlign: 'center',
		maxLength: 20,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '1',
				title: 'Label the picture with the correct words.',
				color: '#234090',
			},
		],
		hintBox: [
			{
				src: [
					'beach',
					'<s>bus stop</s>',
					'library',
					'park',
					'school',
					'stores',
					'supermarket',
					'traffic lights',
				],
				width: 650,
				borderColor: '#234090',
				bgColor: 'none',
			},
		],
		stylesTextInput: {
			background: 'none',
			borderBottom: 'none',
			fontSize: 23,
			height: 30,
			width: 150,
		},
		questions: [
			{
				title: `
      <div style='margin-bottom: 20px'><hintbox id='0'></hintbox></div>
       <div style='position:relative'>
       <img style='width:22cm' src='img/FriendsPlus/Page80/E1/1.jpg' />

        <div style=" position: absolute; top: 37px; left: 130px; ">#</div>
        <div style=" position: absolute; top: 38px; left: 357px; ">#</div>
        <div style=" position: absolute; top: 145px; left: 502px; ">#</div>
        <div style=" position: absolute; top: 297px; left: 607px; ">#</div>

      </div>
            
          `,
				answer: ['stores', 'traffic lights', 'park', 'school'],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 11',
		id: '4WB-U11-P80-E2',
		// audio: 'Audios/Track 39.mp3',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page80/E2/Key/E2answerKey.png',
		inputSize: 100,
		textAlign: 'center',
		maxLength: 10,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '2',
				title: 'Read the invite in the Student Book. Complete Phuong’s notes.',
				color: '#234090',
			},
		],
		stylesTextInput: {
			background: 'none',
			borderBottom: 'none',
			fontSize: 23,
			height: 30,
			width: 150,
		},
		questions: [
			{
				title: `
       <div style='position:relative'>
       <img style='width:21cm' src='img/FriendsPlus/Page80/E2/1.jpg' />

        <div style=" position: absolute; top: 58px; left: 164px; ">#</div>
        <div style=" position: absolute; top: 57px; left: 488px; ">#</div>
        <div style=" position: absolute; top: 152px; left: 201px; ">#</div>
        <div style=" position: absolute; top: 198px; left: 69px; ">#</div>
        <div style=" position: absolute; top: 198px; left: 530px; ">#</div>
        <div style=" position: absolute; top: 290px; left: 162px; ">#</div>

      </div>
            
          `,
				answer: [`3 o'clock`, '5:45', 'left', 'straight', 'left', 'opposite'],
			},
		],
	},
}

export default json
