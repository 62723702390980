import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 7',
		id: '4WB-U7-P52-E1',
		audio: 'Audios/Track 26.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page52/E1/Key/E1answerKey.png',

		component: DesignUnderLine,
		totalInput: 4,
		titleQuestion: [
			{
				num: '1',
				title:
					"Listen and check (✓). <headphone name='26' src='Audios/Track 26.mp3'></headphone>",
				color: '#234090',
			},
		],
		// isAllowSubmit: false,
		questionImage: [
			// Row 1
			[{ url: 'img/FriendsPlus/Page52/E1/1.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page52/E1/2.jpg' },
				{ url: 'img/FriendsPlus/Page52/E1/3.jpg', input: '1', isCorrect: true },
				{ url: 'img/FriendsPlus/Page52/E1/4.jpg' },
				{ url: 'img/FriendsPlus/Page52/E1/5.jpg', input: '1' },
				{ url: 'img/FriendsPlus/Page52/E1/6.jpg' },
				{ url: 'img/FriendsPlus/Page52/E1/7.jpg', input: '1' },
			],
			// Row 1
			[{ url: 'img/FriendsPlus/Page52/E1/8.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page52/E1/9.jpg' },
				{ url: 'img/FriendsPlus/Page52/E1/10.jpg', input: '2' },
				{ url: 'img/FriendsPlus/Page52/E1/11.jpg' },
				{ url: 'img/FriendsPlus/Page52/E1/12.jpg', input: '2' },
				{ url: 'img/FriendsPlus/Page52/E1/13.jpg' },
				{
					url: 'img/FriendsPlus/Page52/E1/14.jpg',
					input: '2',
					isCorrect: true,
				},
			],
			// Row 1
			[{ url: 'img/FriendsPlus/Page52/E1/15.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page52/E1/16.jpg' },
				{ url: 'img/FriendsPlus/Page52/E1/17.jpg', input: '3' },
				{ url: 'img/FriendsPlus/Page52/E1/18.jpg' },
				{
					url: 'img/FriendsPlus/Page52/E1/19.jpg',
					input: '3',
					isCorrect: true,
				},
				{ url: 'img/FriendsPlus/Page52/E1/20.jpg' },
				{ url: 'img/FriendsPlus/Page52/E1/21.jpg', input: '3' },
			],
			// Row 1
			[{ url: 'img/FriendsPlus/Page52/E1/22.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page52/E1/23.jpg' },
				{
					url: 'img/FriendsPlus/Page52/E1/24.jpg',
					input: '4',
					isCorrect: true,
				},
				{ url: 'img/FriendsPlus/Page52/E1/25.jpg' },
				{ url: 'img/FriendsPlus/Page52/E1/26.jpg', input: '4' },
				{ url: 'img/FriendsPlus/Page52/E1/27.jpg' },
				{ url: 'img/FriendsPlus/Page52/E1/28.jpg', input: '4' },
			],
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 7',
		id: '4WB-U7-P52-E2',
		audio: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page52/E2/Key/E2answerKey.png',
		// exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
		inputSize: 250,
		textAlign: 'center',
		maxLength: 20,
		stylesTextInput: {
			background: 'none',
			// borderBottom: 'none',
			fontSize: 22,
			height: 26,
			// textTransform: 'uppercase',
		},
		titleQuestion: [
			{
				num: '2',
				title: 'Look and write.',
				color: '#234090',
			},
		],
		hintBox: [
			{
				src: [
					'play outside',
					'windy',
					'snowy',
					'sunny',
					'<s>fly our kite</s>',
					'make a snowman',
				],
				width: 600,
				borderColor: 'rgb(250,196,197)',
			},
		],
		questions: [
			{
				title: `
				<hintbox id='0' style='border-radius:2px'></hintbox>
       <div style='position:relative'>
			 
        <div style='display:flex; margin-top:20px; line-height:45px; width:100%' >
				<img style='width:8cm; height: 11cm' src="img/FriendsPlus/Page52/E2/1.jpg" />
          <div>
              <div > Let’s<u style='color:gray;'; text-align:center' > fly our kite!</div>
							<div>Why?</div>
              <div style='display:flex'>Because it’s <div># .</div></div>

              <div style='display:flex'>Let’s <div># .</div></div>
              <div>Why?</div>
							<div style='display:flex'>Because it’s <div># .</div></div>

              <div style='display:flex'>Let’s <div># .</div></div>
              <div>Why?</div>
							<div style='display:flex'>Because it’s <div># .</div></div>
          </div>
          
        </div>
     
      </div>
            
          `,
				answer: ['windy', 'make a snowman', 'snowy', 'play outside', 'sunny'],
			},
		],
	},
}

export default json
