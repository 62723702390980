// import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
// import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "4WB- Grammar Time-P90-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page90/Key/E1answerKey.png",
    inputSize: 150,
    maxLength: 10,
    checkUppercase: true,
    textAlign: "center",
    stylesTextInput: {
      // background: 'none',
      fontSize: 23,
      height: 25,
    },
    // titleImage: "img/FriendsPlus/Page88/E1/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 1</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
            <div style='display:flex; margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 0px 30px 0px 10px; width: fir-content'>
              <div style='margin-right: 30px; border-right: solid 2px white; padding-right:10px'>
                <b>We’re</b> doctors. <br>
                <b>They’re</b> farmers.<br>
                <b>We aren’t</b> teachers. <br>
                <b>They aren’t</b> students. 
              </div>  
              <div style=''>
                <b>We help</b> sick people. <br>
                <b>They grow</b> food.
              </div> 
            </div>
          <span style='color: black; font-size: 30px; margin-top:20px'><b> Write.</b></span>
           <div style='margin:; line-height: 60px'>
              <div><b>1. </b>We<u style='color:gray'>'re</u> police officers. We <u style='color:gray'>help</u> everyone</div>
              <div><b>2. </b>They # students. They # teachers.</div>
              <div><b>3. </b>We # office workers. # use computers.</div>
              <div><b>4. </b>They # firefighters. # fight fires.</div>
           </div>
      `,
        answer: [`aren't`, `'re/are`, `'re/are`, `We`, `'re/are`, `They`],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "4WB- Grammar Time-P90-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page90/Key/E2answerKey.png",
    inputSize: 300,
    maxLength: 40,
    checkUppercase: true,
    textAlign: "center",
    stylesTextInput: {
      // background: 'none',
      fontSize: 23,
      height: 25,
    },
    // titleImage: "img/FriendsPlus/Page88/E1/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 2</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
            <div style='display:flex; margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 5px 30px 5px 10px; width: max-content'>
              <div style='margin-right:30px'>
                She<b> works in</b> a hospital. <br>
                <b>Does</b> she <b>work in </b>a store?<br>
              </div>  
              <div style=''>
                He<b> works in</b> an airport. <br>
                Yes, she<b> does.</b> / No, she <b>doesn’t</b>. 
              </div> 
            </div>
          <span style='color: black; font-size: 30px; margin-top:20px'><b> Write.</b></span>
           <div style='margin:; line-height: 60px'>
              <div><b>1. </b>(store) He<u style='color:gray'> works in a store</u>.</div>
              <div><b>2. </b>(police station) She # .</div>
              <div><b>3. </b>(she) <u style='color:gray'> Does she </u>work in a police station? (✗)<u style='color:gray'> No, she doesn’t. </u></div>
              <div><b>4. </b>(he) # work in an airport? (✓) # .</div>
           </div>
      `,
        answer: [`works in a police station`, `Does he`, `Yes, he does`],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "4WB- Grammar Time-P90-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page90/Key/E3answerKey.png",
    inputSize: 570,
    maxLength: 100,
    checkUppercase: true,
    isHiddenCheck: true,
    textAlign: "center",
    stylesTextInput: {
      // background: 'none',
      fontSize: 23,
      height: 25,
    },
    // titleImage: "img/FriendsPlus/Page88/E1/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 3</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display:flex; margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 5px 30px 5px 10px; width: fit-content'>
            <div>
              <b>Would you like </b>a bubble tea?&ensp; <b>Yes, please. / No, thanks.</b><br>
              <b>Would </b>he <b>like </b>chicken <b>or </b>pizza?&ensp; He <b>would like </b>chicken.<br>
              <b>What does </b>she <b>like? </b>She <b>likes </b>noodles.
            </div>
          </div>
          <span style='color: black; font-size: 30px; margin-top:20px'><b> Write.</b></span>
          <div style='display: flex; line-height: 50px; max-width: 1500px'>
            <div style=''>
              <div style='margin-bottom:10px'><b>1. </b>Would you like noodles?</div>
              <div style='margin-bottom:10px'><b>2. </b>Would she like bubble tea or water?</div>
              <div style='margin-bottom:10px'><b>3. </b>What does he like?</div>
              <div style='margin-bottom:10px'><b>4. </b>Would you like mango juice?</div>
            </div>
            <div style=''>
              <div style='text-align: center; border: 1px solid rgb(1 174 240); border-radius: 10px; margin-bottom:5px; font-size: 20px; margin-right: 15px; margin-left: 15px'><s>noodles</s></div>
              <div style='text-align: center; border: 1px solid rgb(1 174 240); border-radius: 10px; margin-bottom:5px; font-size: 20px; margin-right: 15px; margin-left: 15px'>water</div>
              <div style='text-align: center; border: 1px solid rgb(1 174 240); border-radius: 10px; margin-bottom:5px; font-size: 20px; margin-right: 15px; margin-left: 15px'>pizza</div>
              <div style='text-align: center; border: 1px solid rgb(1 174 240); border-radius: 10px; margin-bottom:5px; font-size: 20px; margin-right: 15px; margin-left: 15px'>✓</div>

            </div>
            <div style=''>
              <div><u style='color: gray'>&ensp;No, thanks.&ensp;</u></div>
              <div style='margin-bottom:10px'># .</div>
              <div style='margin-bottom:10px'># .</div>
              <div style='margin-bottom:10px'># .</div>
            </div>
          </div>
      `,
        answer: [
          `She would like water please / She would like water`,
          `He likes pizza`,
          `Yes, please`,
        ],
      },
    ],
  },
};

export default json;
