import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import ScoreByColor from "../../components/ExcerciseTypes/Design/ScoreByColor";
import PaintColorType from "../../components/ExcerciseTypes/Design/PaintColorType";
const json = {
  1: {
    // Exercise num
    unit: "Stater",
    id: "4WB-S-P8-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page8/E1/Key/E1answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    component: T6,
    inputSize: 108,
    textAlign: "center",
    maxLength: 8,
    isHiddenCheck: true,
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 20,
      height: 24,
    },
    titleQuestion: [
      {
        num: "1",
        title: "Read the text in the Student Book. Write.",
        color: "#234090",
      },
    ],

    questions: [
      {
        title: `


        <div style=" position: relative; padding-top:20px ">
						<div> <img style='width:22cm' src='img/FriendsPlus/Page8/E1/1.jpg' /> </div>
            <div style=" position: absolute; top: 81px; left: 648px;height: 20px ">#</div>
						<div style=" position: absolute; top: 119px; left: 333px; "><input id='1'></input></div>
						<div style=" position: absolute; top: 119px; left: 593px; "><input id='2' ></input></div>
            <div style=" position: absolute; top: 156px; left: 522px; "><input id='3' ></input></div>

            
            <div style=" position: absolute; top: 300px; left: 438px;  "><input id='4'  width='116px'></input></div>
            <div style=" position: absolute; top: 300px; left: 665px; "><input id='5'  width='116px'></input></div>
            <div style=" position: absolute; top: 337px; left: 348px; "><input id='6' width='116px'></input></div>
            <div style=" position: absolute; top: 337px; left: 598px; "><input id='7' width='116px'></input></div>
            <div style=" position: absolute; top: 375px; left: 500px; "><input id='8' width='177px'></input></div>
					</div>
          `,
        // answer: ["sister","long","brown","pink,"brother","",],
        answer: [
          "sister",
          "brown",
          "eyes",
          "pink",
          "eleven",
          "brother",
          "short",
          "eyes",
          "green",
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Starter",
    id: "4WB-S-P8-E2",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page8/E2/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: '<div style="">Match.</div>',
        color: "#2E479B",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          //left
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "97px",
          //     left: "247px",
          //     width: 186,
          //     height: 45,
          //     // backgroundColor: "red",
          //     // border: '1px solid',
          //   },
          // }, //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "184px",
              width: 290,
              height: 45,
              //backgroundColor: "blue",
              // border: '1px solid',
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "245px",
              left: "139px",
              width: 191,
              height: 55,
              //backgroundColor: "yellow",
              // border: '1px solid',
            },
          }, //2
          //right
          {
            boxMatchStyle: {
              position: "absolute",
              top: "104px",
              left: "657px",
              width: 146,
              height: 45,
              //backgroundColor: "red",
              // border: '1px solid',
            },
          }, //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "169px",
              left: "722px",
              width: 278,
              height: 45,
              //backgroundColor: "blue",
              // border: '1px solid',
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "243px",
              left: "720px",
              width: 272,
              height: 58,
              //backgroundColor: "yellow",
              // border: '1px solid',
            },
          }, //5
          //mid
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "123px",
          //     left: "631px",
          //     width: 70,
          //     height: 43,
          //     backgroundColor: "pink",
          //     // border: '1px solid',
          //   },
          // }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "128px",
              left: "458px",
              width: 84,
              height: 50,
              //backgroundColor: "pink",
              // border: '1px solid',
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "197px",
              left: "456px",
              width: 105,
              height: 49,
              //backgroundColor: "pink",
              // border: '1px solid',
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "285px",
              left: "455px",
              width: 159,
              height: 81,
              //backgroundColor: "pink",
              // border: '1px solid',
            },
          }, //9
        ],
        answers: ["2-5", "0-6", "3-6", "4-6", "1-7"],
        initialValue: [],
      },
      Layout: `
      <img style='height:10cm; width: 23cm' src="img/FriendsPlus/Page8/E2/4.jpg" />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />

          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />

          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />  
      `,
    },
  },
  3: {
    // Exercise num
    unit: "Stater",
    id: "4WB-S-P8-E3",
    audio: "",
    component: T6,
    hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page7/Key/E1answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 290,
    textAlign: "center",
    maxLength: 30,

    titleQuestion: [
      {
        num: "3",
        title: "Draw and write about you.",
        color: "#234090",
      },
    ],

    questions: [
      {
        title: `
        <div style='display:flex'>
          <img style=' height:6cm; padding-right:10px' src="img/FriendsPlus/Page8/E3/1.jpg" />&ensp;
          <div style='line-height:45px'>
            <div>My name’s <input id='1' width='365px'></input> .</div>
            <div>I’m <input id='2' width='450px'></input> .</div>
            <div>I have <input id='3' width='385px'></input> and</div>
            <div> <input id='4' width='500px'></input></div>
            <div>My favorite color is # .</div>
          </div>
        </div>
        
          
          `,
        answer: ["", "", "", "", ""],
      },
    ],
  },
};

export default json;
