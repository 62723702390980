// import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
// import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
// import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    // Exercise num
    unit: "Unit 12",
    id: "4WB-U12-P85-E1",
    audio: "Audios/Track 43.mp3",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page85/E1/Key/E1answerKey.png",
    inputSize: 205,
    textAlign: "center",
    maxLength: 5,
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 22,
      height: 27,
      width: 150,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Order the letters. Listen and check. <headphone name='43' src='Audios/Track 43.mp3'></headphone>",
        color: "#234090",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='position: relative; width: max-content' >
            <img style='width:22cm' src="img/FriendsPlus/Page85/E1/1.jpg" />    
            
            <div style=" position: absolute; top: 140px; left: 229px;">#</div>
            <div style=" position: absolute; top: 140px; left: 436px;">#</div>
            <div style=" position: absolute; top: 140px; left: 647px;">#</div>
            </div>
            
          `,
        answer: ["sleep", "green", "dream"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 12",
    id: "4WB-U12-P85-E2",
    // audio: 'Audios/Track 27.mp3',
    component: T6,
    exerciseKey: "img/FriendsPlus/Page85/E2/Key/E2answerKey.png",
    inputSize: 205,
    textAlign: "center",
    maxLength: 8,
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 27,
      width: 100,
    },
    titleQuestion: [
      {
        num: "2",
        title: "Write.",
        color: "#234090",
      },
    ],
    hintBox: [
      {
        src: ["eating", "please", "see", "<s>tree</s>"],
        width: 600,
        borderColor: "#234090",
        bgColor: "none",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='margin-bottom: 10px'><hintbox id='0'></hintbox></div>
        <img style='width:20cm' src="img/FriendsPlus/Page85/E2/1.jpg" />    
        <div style='display: flex; max-width:900px' > 
          <div style='width: 20%; margin-right:20px'>My mom likes sitting under a <u style='color:gray'>tree</u>.</div>
          <div style='width: 20%; margin-right:20px'>Can you give me a melon, # ?</div>
					<div style='width: 20%; margin-right:20px'>He is # ice cream.</div>
					<div style='width: 20%;'>What can you # in this photo?</div>
        
        </div>  
          `,
        answer: ["please", "eating", "see"],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 9",
    id: "4WB-U9-P66-E2",
    audio: "Audios/Track 44.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page85/E3/Key/E3answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "3",
        title:
          "Circle. Listen and chant. <headphone name='44' src='Audios/Track 44.mp3'></headphone>",
        color: "#234090",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 150, color: "black", textAlign: "center" },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
          fontWeight: "bold",
          color: "black",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "clean / green", //0
          "eat / dream", //1
          "cream / dream", //2
          "queen / ice cream", //3
        ],
        answers: ["0-4", "1-0", "2-0", "3-0"],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex;line-height:50px'>
              <div>
                  <div>
                  I go to <sup>1 </sup><b><span style='padding:10px; border:2px solid gray; border-radius:50% '>sleep</span> / dream </b>.<br>
                  </div>
                  <div>Dream, dream, dream.</div>
                  <div>
                  I have a <sup>2 </sup> <input id='0' type='Circle'/> spoon to <sup>3 </sup><input id='1' type='Circle'/><br>
                    
                  </div>
                  <div>
                  Ice cream,<sup>4 </sup> <input id='2' type='Circle'/>, cream.
                   
                  </div>
                  <div>
                  I am the <sup>5 </sup> <input id='3' type='Circle'/>  in my
                   
                  </div>
                  <div>Dream, dream, dream.</div>
              </div>
              <img style="width:9cm" src='img/FriendsPlus/Page85/E3/1.jpg' />
      </div>
        
      `,
    },
  },
};

export default json;
