import TextEditer from "../../components/ExcerciseTypes/TextEditer";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "4WB-U1-P13-E1",
    audio: "Audios/Track 03.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page13/E1/Key/E1answerKey.png",
    isHiddenCheck: true,
    component: DesignUnderLine,
    totalInput: 3,
    titleQuestion: [
      {
        num: "1",
        title:
          "Check (✓) the correct stress. Listen and check. <headphone name='03' src='Audios/Track 03.mp3'></headphone>",
        color: "#234090",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page13/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page13/E1/2.jpg" },
        { url: "img/FriendsPlus/Page13/E1/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page13/E1/4.jpg" },
        { url: "img/FriendsPlus/Page13/E1/5.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page13/E1/6.jpg" },
        { url: "img/FriendsPlus/Page13/E1/7.jpg", input: 3 },
      ],
      [
        { url: "img/FriendsPlus/Page13/E1/8.jpg" },
        { url: "img/FriendsPlus/Page13/E1/9.jpg", input: 1 },
        { url: "img/FriendsPlus/Page13/E1/10.jpg" },
        { url: "img/FriendsPlus/Page13/E1/11.jpg", input: 2 },
        { url: "img/FriendsPlus/Page13/E1/12.jpg" },
        { url: "img/FriendsPlus/Page13/E1/13.jpg", input: 3, isCorrect: true },
      ],
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 1",
    id: "4WB-U1-P13-E2",
    audio: "Audios/Track 04.mp3",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page13/E2/Key/E2answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 105,
    textAlign: "center",
    maxLength: 8,
    stylesTextInput: {
      background: "none",
      borderBottom: 0,
      fontSize: 23,
      height: 30,
      width: 80,
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "  Write. Listen and chant. <headphone name='04' src='Audios/Track 04.mp3'></headphone>",
        color: "#234090",
      },
    ],

    questions: [
      {
        title: `
        <div style='position: relative;margin-top:20px; width:100%; text-align:right' >
            <img style='width:15cm' src="img/FriendsPlus/Page13/E2/1.jpg" />    
            <div style="width:100px; position: absolute; top: 63px; left: 370px"><input id='1' ></input></div>
            <div style="width:100px; position: absolute; top: 109px; left: 236px"><input id='2' ></input></div>
            <div style="width:100px; position: absolute; top: 150px; left: 61px"><input id='3' ></input></div>
            <div style="width:100px; position: absolute; top: 150px; left: 347px"><input id='4' ></input></div>
            <div style="width:100px; position: absolute; top: 191px; left: 223px"><input id='4' ></input></div>
        </div>
            
          `,
        answer: ["work", "ev", "pi", "fire", "ma"],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "4WB-U1-P13-E3",
    audio: "Audios/Track 05.mp3",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page13/E3/Key/E3answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 40,
    textAlign: "center",
    maxLength: 8,

    titleQuestion: [
      {
        num: "3",
        title:
          " Write the missing letters with schwa. Listen and check. <headphone name='05' src='Audios/Track 05.mp3'></headphone>",
        color: "#234090",
      },
    ],

    questions: [
      {
        title: `
       <div style='width:735px; display:flex; line-height:70px'>
        <div style='width:112%; padding-right:89px'>
          <div><b>1.</b>farm <u style='color:gray'>&ensp;e&ensp;</u> <u style='color:gray'>&ensp;r&ensp;</u></div>
          <div><b>2.</b>office work # #</div>
          <div><b>3.</b>doct # #</div>
        </div>

        <div style='width:87%'>
          <div><b>4.</b>firefight # #</div>
          <div><b>5.</b>teach # #</div>
          <div><b>6.</b>pil # t</div>
        </div>
       </div>
            
          `,
        answer: ["e", "r", "o", "r", "e", "r", "e", "r", "o"],
      },
    ],
  },
};
export default json;
