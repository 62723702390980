import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 10",
    id: "4WB-U10-P71-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page71/E1/Key/E1answerKey.png",

    component: T6,
    titleQuestion: [
      {
        num: "1",
        title: "Look and read. Write (✓) or (✗).",
        color: "#234090",
      },
    ],
    selectStyle: {
      height: 35,
      width: 38,
      textAlign: "center",
    },
    selectOptionRandom: true,
    selectOptionValues: ["✓", "X"],
    questionImage: [],
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page71/E1/1.jpg' width: 95% />
        <div style='position: relative;'>
          <div style=" position: absolute; top: -296px; left: 986px; "><select id=0></select></div>
          <div style=" position: absolute; top: -40px; left: 463px; "><select id=1></select></div>
          <div style=" position: absolute; top: -40px; left: 986px; "><select id=2></select></div>
        </div> 
        `,
        answer: ["X", "✓", "✓"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 10",
    id: "4WB-U10-P71-E2",
    audio: "",
    video: "",
    component: T6,
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page71/E2/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "Match the pictures with the sentences. There are two extra sentences.",
        color: "#2E479B",
      },
    ],
    question: {
      DrawLines: {
        // multipleLine: true,
        boxMatch: [
          //left
          {
            boxMatchStyle: {
              position: "absolute",
              top: "406px",
              left: "110px",
              width: 201,
              height: 149,
              //backgroundColor: 'red',
              // border: "1px solid",
            },
          }, //0
          //right
          {
            boxMatchStyle: {
              position: "absolute",
              top: "164px",
              left: "646px",
              width: 201,
              height: 149,
              //backgroundColor: 'yellow',
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "407px",
              left: "646px",
              width: 201,
              height: 149,
              //backgroundColor: 'pink',
              // border: "1px solid",
            },
          }, //2
          //mid
          {
            boxMatchStyle: {
              position: "absolute",
              top: "72px",
              left: "367px",
              width: 272,
              height: 62,
              //backgroundColor: 'blue',
              // border: "1px solid",
            },
          }, //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "157px",
              left: "367px",
              width: 247,
              height: 62,
              //backgroundColor: 'red',
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "326px",
              left: "363px",
              width: 254,
              height: 62,
              //backgroundColor: 'yellow',
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "409px",
              left: "380px",
              width: 288,
              height: 62,
              //backgroundColor: 'black',
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "493px",
              left: "344px",
              width: 217,
              height: 62,
              //backgroundColor: 'green',
              // border: "1px solid",
            },
          }, //7
        ],
        answers: ["0-3", "1-6", "2-7"],
        initialValue: [],
      },
      Layout: `
      <img style='padding-top:30px; margin-bottom: 40px; width: 20cm' src="img/FriendsPlus/Page71/E2/1.jpg" />
          <input id='0' type= 'boxMatch' />
          
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />

          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />

      `,
    },
  },
};

export default json;
