import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 5',
		id: '4WB-U5-P38-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page38/E1/Key/E1answerKey.png',

		component: DesignUnderLine,
		totalInput: 5,
		titleQuestion: [
			{
				num: '1',
				title: 'Look at the picture. Read and check (✓).',
				color: '#234090',
			},
		],
		// isAllowSubmit: false,
		questionImage: [
			// Q1
			[{ url: 'img/FriendsPlus/Page38/E1/1.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page38/E1/2.jpg' },
				{ url: 'img/FriendsPlus/Page38/E1/3.jpg', input: 1, isCorrect: true },
				{ url: 'img/FriendsPlus/Page38/E1/4.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page38/E1/5.jpg' },
				{ url: 'img/FriendsPlus/Page38/E1/6.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page38/E1/7.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page38/E1/8.jpg' },
				{ url: 'img/FriendsPlus/Page38/E1/9.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page38/E1/10.jpg' },
			],
			// Q2
			[{ url: 'img/FriendsPlus/Page38/E1/11.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page38/E1/12.jpg' },
				{ url: 'img/FriendsPlus/Page38/E1/13.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page38/E1/14.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page38/E1/15.jpg' },
				{ url: 'img/FriendsPlus/Page38/E1/16.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page38/E1/17.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page38/E1/18.jpg' },
				{ url: 'img/FriendsPlus/Page38/E1/19.jpg', input: 2, isCorrect: true },
				{ url: 'img/FriendsPlus/Page38/E1/20.jpg' },
			],
			// Q3
			[{ url: 'img/FriendsPlus/Page38/E1/21.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page38/E1/22.jpg' },
				{ url: 'img/FriendsPlus/Page38/E1/23.jpg', input: 3 },
				{ url: 'img/FriendsPlus/Page38/E1/24.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page38/E1/25.jpg' },
				{ url: 'img/FriendsPlus/Page38/E1/26.jpg', input: 3, isCorrect: true },
				{ url: 'img/FriendsPlus/Page38/E1/27.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page38/E1/28.jpg' },
				{ url: 'img/FriendsPlus/Page38/E1/29.jpg', input: 3 },
				{ url: 'img/FriendsPlus/Page38/E1/30.jpg' },
			],
			// Q4
			[{ url: 'img/FriendsPlus/Page38/E1/31.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page38/E1/32.jpg' },
				{ url: 'img/FriendsPlus/Page38/E1/33.jpg', input: 4 },
				{ url: 'img/FriendsPlus/Page38/E1/34.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page38/E1/35.jpg' },
				{ url: 'img/FriendsPlus/Page38/E1/36.jpg', input: 4 },
				{ url: 'img/FriendsPlus/Page38/E1/37.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page38/E1/38.jpg' },
				{ url: 'img/FriendsPlus/Page38/E1/39.jpg', input: 4, isCorrect: true },
				{ url: 'img/FriendsPlus/Page38/E1/40.jpg' },
			],
			// Q5
			[{ url: 'img/FriendsPlus/Page38/E1/41.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page38/E1/42.jpg' },
				{ url: 'img/FriendsPlus/Page38/E1/43.jpg', input: 5 },
				{ url: 'img/FriendsPlus/Page38/E1/44.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page38/E1/45.jpg' },
				{ url: 'img/FriendsPlus/Page38/E1/46.jpg', input: 5 },
				{ url: 'img/FriendsPlus/Page38/E1/47.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page38/E1/48.jpg' },
				{ url: 'img/FriendsPlus/Page38/E1/49.jpg', input: 5, isCorrect: true },
				{ url: 'img/FriendsPlus/Page38/E1/50.jpg' },
			],
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 5',
		id: '4WB-U5-P38-E2',
		audio: 'Audios/Track 20.mp3',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page38/E2/Key/E2answerKey.png',
		// exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
		inputSize: 50,
		textAlign: 'center',
		maxLength: 20,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '2',
				title:
					"Listen and write.<headphone name='20' src='Audios/Track 20.mp3'></headphone>",
				color: '#234090',
			},
		],
		stylesTextInput: {
			background: 'none',
			borderBottom: 'none',
			fontSize: 23,
			height: 30,
			width: 180,
		},
		questions: [
			{
				title: `
       <div style='position:relative'>
       <img style='width: 25cm' src='img/FriendsPlus/Page38/E2/1.jpg' />

        <div style=" position: absolute; top: 51px; left: 91px; ">#</div>
        <div style=" position: absolute; top: 96px; left: 91px; ">#</div>
        <div style=" position: absolute; top: 142px; left: 91px; ">#</div>
        <div style=" position: absolute; top: 187px; left: 91px; ">#</div>

        <div style=" position: absolute; top: 243px; left: 151px; ">#</div>
        <div style=" position: absolute; top: 290px; left: 142px; ">#</div>
        <div style=" position: absolute; top: 337px; left: 259px; ">#</div>
        <div style=" position: absolute; top: 382px; left: 105px; ">#</div>

        
     
      </div>
            
          `,
				answer: [
					'playing',
					'sitting',
					'playing',
					'having',
					'collecting',
					'swimming',
					'going',
					'surfing',
				],
			},
		],
	},
}

export default json
