import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 8',
		id: '4WB-U8-P60-E1',
		audio: '',
		exerciseKey: 'img/FriendsPlus/Page60/E1/Key/E1answerKey.png',
		video: '',
		recorder: true,
		textareaStyle:{width:700},
		component: T6,
		// component: DesignUnderLine1,
		titleImage: '',
		hideBtnFooter: true,
		questionImage: [],
		titleQuestion: [
			{
				num: '1',
				title: `
				Read.
			  `,
				color: '#23408f',
			},
		],
		questions: [
			{
				title: `
					<div style=" position: relative ">
            <img src='img/FriendsPlus/Page60/E1/1.jpg' width='80%'>
			<div style='display:flex; margin-top:20px'>
			<b><u><i>ANSWER:</i></u></b> 
			<textarea id='0' rows='3'></textarea>
			</div>
					</div>
			  `,
				answer: [],
			},
		],
	},
	2: {	
		// Exercise num
		unit: 'Unit 8',
		id: '4WB-U8-P60-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page60/E2/Key/E2answerKey.png',
		component: DesignUnderLine,
		isHiddenCheck:true,
		totalInput: 3,
		titleQuestion: [
			{
				num: '2',
				title: 'Read again. Fill in the correct circle.',
				color: '#234090',
			},
		],
		// isAllowSubmit: false,
		questionImage: [
			[{ url: 'img/FriendsPlus/Page60/E2/37.jpg' }],
			// Row 1
			[{ url: 'img/FriendsPlus/Page60/E2/1.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page60/E2/2.jpg' },
				{ url: 'img/FriendsPlus/Page60/E2/3.jpg' },
				{ url: 'img/FriendsPlus/Page60/E2/4.jpg' },
				{ url: 'img/FriendsPlus/Page60/E2/5.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page60/E2/6.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page60/E2/7.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page60/E2/8.jpg' },
				{ url: 'img/FriendsPlus/Page60/E2/9.jpg' },
				{ url: 'img/FriendsPlus/Page60/E2/10.jpg'},
				{ url: 'img/FriendsPlus/Page60/E2/11.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page60/E2/12.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page60/E2/13.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page60/E2/14.jpg' },
				{ url: 'img/FriendsPlus/Page60/E2/15.jpg' },
				{ url: 'img/FriendsPlus/Page60/E2/16.jpg' },
				{ url: 'img/FriendsPlus/Page60/E2/17.jpg', input: 1, isCorrect: true },
				{ url: 'img/FriendsPlus/Page60/E2/18.jpg' },
			],
			// Row 2
			[{ url: 'img/FriendsPlus/Page60/E2/19.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page60/E2/20.jpg' },
				{ url: 'img/FriendsPlus/Page60/E2/21.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page60/E2/22.jpg' },
				{ url: 'img/FriendsPlus/Page60/E2/23.jpg', input: 3 },
				{ url: 'img/FriendsPlus/Page60/E2/24.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page60/E2/25.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page60/E2/26.jpg' },
				{ url: 'img/FriendsPlus/Page60/E2/27.jpg', input: 2, isCorrect: true  },
				{ url: 'img/FriendsPlus/Page60/E2/28.jpg' },
				{ url: 'img/FriendsPlus/Page60/E2/29.jpg', input: 3, isCorrect: true },
				{ url: 'img/FriendsPlus/Page60/E2/30.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page60/E2/31.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page60/E2/32.jpg' },
				{ url: 'img/FriendsPlus/Page60/E2/33.jpg', input: 2},
				{ url: 'img/FriendsPlus/Page60/E2/34.jpg' },
				{ url: 'img/FriendsPlus/Page60/E2/35.jpg', input: 3 },
				{ url: 'img/FriendsPlus/Page60/E2/36.jpg' },
			],
		],
	},
}

export default json
