import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "4WB-U2-P18-E1",
    audio: "",
    // hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page18/Key/E1answerKey.png",
    component: T6,
    inputSize: 550,
    textAlign: "center",
    maxLength: 60,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 23,
      height: 29,
      // textTransform: "uppercase",
      borderStyle: "2px dotted",
      textAlign: "left",
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title: "Find and write the words.",
        color: "#234090",
      },
    ],
    questions: [
      {
        title: `	
       
            <div> <img style='width:23cm' src='img/FriendsPlus/Page18/E1/1.jpg' /> </div>
						<div>
				<i style='font-size:18px; color: rgb(245,54,92)'>*Write the selected words in the sorted order of the list above and between words seperated by commas (,).</i></div>
        <div style='display: flex; margin-top: 30px'>
        <b>Answer:&ensp;</b><i style='color: gray'>farm,</i>#
        
        </div>
          `,
        answer: ["office, bank / bank, office"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 2",
    id: "4WB-U2-P18-E2",
    audio: "Audios/Track 07.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page18/E2/Key/E2answerKey.png",

    component: DesignUnderLine,
    totalInput: 3,
    titleQuestion: [
      {
        num: "2",
        title:
          "<div style=''>Listen and check (✓). <headphone name='07' src='Audios/Track 07.mp3'></headphone></div>",
        color: "#234090",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row 1
      [
        { url: "img/FriendsPlus/Page18/E2/1.jpg" },
        { url: "img/FriendsPlus/Page18/E2/2.jpg" },
        { url: "img/FriendsPlus/Page18/E2/3.jpg" },
        { url: "img/FriendsPlus/Page18/E2/4.jpg" },
        { url: "img/FriendsPlus/Page18/E2/5.jpg" },
        { url: "img/FriendsPlus/Page18/E2/6.jpg" },
      ],

      [
        { url: "img/FriendsPlus/Page18/E2/7.jpg" },
        { url: "img/FriendsPlus/Page18/E2/8.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page18/E2/9.jpg" },
        { url: "img/FriendsPlus/Page18/E2/10.jpg", input: 1 },
        { url: "img/FriendsPlus/Page18/E2/11.jpg" },
        { url: "img/FriendsPlus/Page18/E2/12.jpg", input: 1 },
      ],
      // Row 2
      [
        { url: "img/FriendsPlus/Page18/E2/13.jpg" },
        { url: "img/FriendsPlus/Page18/E2/14.jpg" },
        { url: "img/FriendsPlus/Page18/E2/15.jpg" },
        { url: "img/FriendsPlus/Page18/E2/16.jpg" },
        { url: "img/FriendsPlus/Page18/E2/17.jpg" },
        { url: "img/FriendsPlus/Page18/E2/18.jpg" },
      ],

      [
        { url: "img/FriendsPlus/Page18/E2/19.jpg" },
        { url: "img/FriendsPlus/Page18/E2/20.jpg", input: 2 },
        { url: "img/FriendsPlus/Page18/E2/21.jpg" },
        { url: "img/FriendsPlus/Page18/E2/22.jpg", input: 2 },
        { url: "img/FriendsPlus/Page18/E2/23.jpg" },
        { url: "img/FriendsPlus/Page18/E2/24.jpg", input: 2, isCorrect: true },
      ],
      // Row 3
      [
        { url: "img/FriendsPlus/Page18/E2/25.jpg" },
        { url: "img/FriendsPlus/Page18/E2/26.jpg" },
        { url: "img/FriendsPlus/Page18/E2/27.jpg" },
        { url: "img/FriendsPlus/Page18/E2/28.jpg" },
        { url: "img/FriendsPlus/Page18/E2/29.jpg" },
        { url: "img/FriendsPlus/Page18/E2/30.jpg" },
      ],

      [
        { url: "img/FriendsPlus/Page18/E2/31.jpg" },
        { url: "img/FriendsPlus/Page18/E2/32.jpg", input: 3 },
        { url: "img/FriendsPlus/Page18/E2/33.jpg" },
        { url: "img/FriendsPlus/Page18/E2/34.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page18/E2/35.jpg" },
        { url: "img/FriendsPlus/Page18/E2/36.jpg", input: 3 },
      ],
    ],
  },
};

export default json;
