import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DrawColor from "../../components/ExcerciseTypes/DrawColor";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    // Exercise num
    unit: "Unit 11",
    id: "4WB-U11-P81-E1",
    // audio: 'Audios/Track 38.mp3',
    video: "",
    exerciseKey: "img/FriendsPlus/Page81/E1/Key/E1answerKey.png",
    isHiddenCheck: true,
    component: DesignUnderLine,
    totalInput: 3,
    titleQuestion: [
      {
        num: 1,
        title: "Check (✓) the sentences with imperative verbs.",
        color: "#234090",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row 1
      [{ url: "img/FriendsPlus/Page81/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page81/E1/2.jpg" },
        { url: "img/FriendsPlus/Page81/E1/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page81/E1/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page81/E1/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page81/E1/6.jpg" },
        { url: "img/FriendsPlus/Page81/E1/7.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page81/E1/8.jpg" },
        { url: "img/FriendsPlus/Page81/E1/9.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page81/E1/10.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page81/E1/11.jpg" },
        { url: "img/FriendsPlus/Page81/E1/12.jpg", input: 4 },
        { url: "img/FriendsPlus/Page81/E1/13.jpg" },
        { url: "img/FriendsPlus/Page81/E1/14.jpg", input: 5, isCorrect: true },
        { url: "img/FriendsPlus/Page81/E1/15.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page81/E1/16.jpg" }],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 11",
    id: "4WB-U11-P81-E2",
    // audio: 'Audios/Track 39.mp3',
    component: T6,
    // exerciseKey: 'img/FriendsPlus/Page81/E2/Key/E2answerKey.png',
    inputSize: 100,
    textAlign: "left",
    hideBtnFooter: true,
    maxLength: 130,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title: "Plan your party. Make notes.",
        color: "#234090",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 22,
      height: 30,
    },
    questions: [
      {
        title: `
       <div style='position:relative'>
       <img style='width:24cm' src='img/FriendsPlus/Page81/E2/1.jpg' />

        <div style=" position: absolute; top: 129px; left: 182px; "><input id='0' width='650px'></div>
        <div style=" position: absolute; top: 167px; left: 153px; "><input id='1' width='650px'></div>
        <div style=" position: absolute; top: 206px; left: 315px; "><input id='2' width='500px'></div>
        <div style=" position: absolute; top: 244px; left: 314px; "><input id='3' width='500px'></div>

      </div>
            
          `,
        answer: ["", "", "", ""],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 11",
    id: "4WB-U11-P81-E3",
    // audio: 'Audios/Track 39.mp3',
    component: T6,
    // exerciseKey: 'img/FriendsPlus/Page81/E2/Key/E2answerKey.png',
    inputSize: 100,
    textAlign: "center",
    hideBtnFooter: true,
    maxLength: 130,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "3",
        title: "Draw and write a party invite to a friend. Give directions.",
        color: "#234090",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 20,
      height: 25,
      width: 150,
    },
    questions: [
      {
        title: `
       <div style='position:relative'>
       <img style='width:22cm' src='img/FriendsPlus/Page81/E3/1.jpg' />

        <div style=" position: absolute; top: 138px; left: 343px; ">#</div>
        <div style=" position: absolute; top: 198px; left: 349px; ">#</div>
        <div style=" position: absolute; top: 234px; left: 349px; ">#</div>
        <div style=" position: absolute; top: 264px; left: 351px; ">#</div>
        <div style=" position: absolute; top: 294px; left: 403px; ">#</div>
        <div style=" position: absolute; top: 294px; left: 580px; ">#</div>

        <div style=" position: absolute; top: 327px; left: 297px; "><input id='2' width='470px'></div>
        <div style=" position: absolute; top: 361px; left: 297px; "><input id='3' width='470px'></div>

        <div style=" position: absolute; top: 422px; left: 347px; ">#</div>
      </div>
            
          `,
        answer: ["", "", "", "", "", "", "", "", ""],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 11",
    id: "4WB-U11-P81-E4",
    // audio: 'Audios/Track 39.mp3',
    component: T6,
    // exerciseKey: 'img/FriendsPlus/Page81/E2/Key/E2answerKey.png',
    inputSize: 100,
    textAlign: "left",
    hideBtnFooter: true,
    maxLength: 130,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Give your party invite to a friend. Write a reply to your friend’s invite.",
        color: "#234090",
      },
    ],
    textareaStyle: {
      // background: 'none',
      width: 800,
      fontSize: 23,
      height: 270,
    },
    questions: [
      {
        title: `
      <div style=''>
      <textarea id='0' row='3'>
      </div>     
          `,
        answer: [""],
      },
    ],
  },
};

export default json;
