import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 5",
    id: "4WB-U5-P41-E1",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page41/E1/Key/E1answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 50,
    textAlign: "center",
    maxLength: 10,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title: "Complete the phrases.",
        color: "#234090",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 24,
      height: 30,
      width: 140,
    },
    hintBox: [
      {
        src: ["see", "<s>Dear</s>", "in", "There"],
        width: 450,
        borderColor: "rgb(241,149,112)",
        bgColor: "none",
      },
    ],
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
       <div style='display:flex; max-width:800px; margin-top:30px; line-height:50px'>
        <div style='margin-right:50px'>
          <div><b>1. </b><u style='color:gray; width:100px'>&ensp;Dear&ensp; </u> &ensp;Duy.</div>
          <div><b>2. </b># you soon.</div>
        </div>

        <div style='margin-left:20px'>
        <div><b>3. </b>I’m # Phan Thiet.</div>
        <div><b>4. </b># are seafood restaurants.</div>
        </div>
     
      </div>
            
          `,
        answer: ["See", "in", "There"],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "4WB-U5-P41-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page41/E2/Key/E2answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "2",
        title: `<div style='font-size:24px'>Circle the correct word.</div>
  		  `,
        color: "#234090",
        // prefix: { icon: [''], text: '' },
      },
    ],

    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "3.5px 3.5px",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
        },
        selectWordStyle: {
          padding: "3.5px 3.5px",

          border: "2px solid black",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `beach / zoo`, //0
          `taking / looking`, //1
          `playing / talking`, //2
          `writing / reading`, //3
        ],
        answers: ["0-0", "1-0", "2-4", "3-4"],
        initialValue: [],
      },
      Layout: `
       <div>
	        <img style='width:26cm; height: 16cm' src='img/FriendsPlus/Page41/E2/1.jpg'>
          <div style='position:relative; height:fit-content'>
            <div style="line-height: 40px; width:397px; position: absolute; top: -414px; left: 87px; font-size:22px; width:377px">
            We’re at the <sup>1 </sup><input id='0' type='Circle'/>. It’s sunny
            and hot. My grandpa is <sup>2 </sup><input id='1' type='Circle'/> photos of my sister. 
            My grandma is <sup>3 </sup><input id='2' type='Circle'/> to my
            mom, and I’m <sup>4 </sup><input id='3' type='Circle'/>
            a great book now!<br>
            From Xuan
            
          </div>

          </div>
      </div>
      `,
    },
  },
  3: {
    // Exercise num
    unit: "Unit 5",
    id: "4WB-U5-P41-E3",
    audio: "",
    hideBtnFooter: true,
    // exerciseKey: 'img/FriendsPlus/Page41/E3Key/E3answerKey.png',
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    component: T6,
    inputSize: 270,
    textAlign: "left",
    maxLength: 100,
    stylesTextInput: {
      background: "none",
      borderBottom: "",
      fontSize: 23,
      height: 25,
    },
    titleQuestion: [
      {
        num: "3",
        title: "Choose a place for a trip. Draw and write a postcard.",
        color: "#234090",
      },
    ],

    questions: [
      {
        title: `
        <div style='position: relative'>
        <img style='width:25cm; height: 10cm' src='img/FriendsPlus/Page41/E3/1.jpg'>
        
        <div style="width:500px; position: absolute; top: 53px; left: 40px; font-size:23px">
          <div>Hi <input id='0' width='300px'></input> .</div>
          <div>We’re at <input id='1' width='300px'></input> .</div>
          <div>It’s <input id='2' width='300px'></input> .</div>
          <div>Every day I <input id='3' width='290px'></input> .</div>
          <div>My <input id='4' width='300px'></input> .</div>
          <div>I’m <input id='5' width='300px'></input> .</div>
          <div>From <input id='6' width='300px'></input> .</div>
        </div>

        
    </div>

          `,
        answer: ["", "", "", "", "", "", ""],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "4WB-U5-P41-E4",
    audio: "",
    video: "",
    hideBtnFooter: true,
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: "Tell your friend about your trip. What are you doing there?",
        color: "#234090",
        // prefix: { icons: ["far fa-comment"] },
      },
    ],
    questionImage: [],
  },
};

export default json;
