import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 3",
    id: "4WB-U3-P25-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page25/E1/Key/E1answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "1",
        title:
          "Say the words. Circle the word that begins with a different sound.",
        color: "#234090",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 150, color: "black", textAlign: "center" },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "3px 3px",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
        },
        selectWordStyle: {
          padding: "3px 3px",

          border: "2px solid black",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          "queen &ensp;&ensp; rocks &ensp;&ensp; rabbit", //2
          "question &ensp;&ensp; queen &ensp;&ensp; sofa", //3
          "rocks &ensp;&ensp; sofa &ensp;&ensp; socks",
        ],
        answers: ['0-0', '2-0', '1-8'],
        initialValue: [],
      },
      Layout: `

      <div style='font-size:25px;padding-top:30px; display:flex;line-height:50px; width:1000px; position:relative'>
              <div style='dispplay:flex; width:50%'>
                  <div style='display:flex'>
                    <b>1.</b>
                    <span style='padding-left: 50px'>sofa</span>
                    <span style='margin:0 50px 0 50px; padding:0 5px 0 5px;border: solid 2px gray; border-radius:50% '>rabbit</span>
                    <span>socks</span>
                    <br>
                  </div>
                  <div style='display:flex'>
                    <b>2.</b>&ensp;<br>
                    <div style='text-align:center;width:70%'><input id='0' type='Circle'/></div>
                  </div>
              </div>

              <div style='dispplay:flex; width:50%'>
                  <div style='display:flex'>
                    <b>3.</b>&ensp;<br>
                    <div style='text-align:center;width:70%'><input id='1' type='Circle'/></div>
                  </div>
                  <div style='display:flex'>
                    <b>4.</b>&ensp;<br>
                    <div style='text-align:center;width:70%'><input id='2' type='Circle'/></div>
                  </div>
              </div>

      </div>
      
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Unit 3",
    id: "4WB-U3-P25-E2",
    audio: "Audios/Track 13.mp3",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page25/E2/Key/E2answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 105,
    textAlign: "center",
    maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 23,
      height: 40,
      width: 40,
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Write. Listen and check. <headphone name='13' src='Audios/Track 13.mp3'></headphone>",
        color: "#234090",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `

        <div style='position: relative; width:100%; text-align:right' >
            <img style='width:20cm' src="img/FriendsPlus/Page25/E2/1.jpg" />    
            
            <div style=" position: absolute; top: 200px; left: 354px;">#</div>
            <div style=" position: absolute; top: 200px; left: 655px;">#</div>

            <div style=" position: absolute; top: 414px; left: 74px;">#</div>
            <div style=" position: absolute; top: 414px; left: 366px;">#</div>
            <div style=" position: absolute; top: 414px; left: 660px;">#</div>
            </div>
            
          `,
        answer: ["q", "r", "r", "q", "s"],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 3",
    id: "4WB-U3-P25-E3",
    audio: "Audios/Track 14.mp3",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page25/E3/Key/E3answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 105,
    textAlign: "center",
    maxLength: 8,
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 27,
      height: 40,
      width: 123,
    },
    titleQuestion: [
      {
        num: "3",
        title:
          "Write. Listen and chant. <headphone name='14' src='Audios/Track 14.mp3'></headphone>",
        color: "#234090",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `

        <div style='position: relative;margin-top:20px; width:100%; text-align:right' >
            <img style='width:25cm' src="img/FriendsPlus/Page25/E3/1.jpg" />    
            
            <div style=" position: absolute; top: 73px; left: 309px;">#</div>
            <div style=" position: absolute; top: 130px; left: 254px;">#</div>

            <div style=" position: absolute; top: 190px; left: 122px;">#</div>
            <div style=" position: absolute; top: 248px; left: 66px;">#</div>
            <div style=" position: absolute; top: 366px; left: 64px;">#</div>
            </div>
            
          `,
        answer: ["question", "socks", "rabbit", "sofa", "rocks"],
      },
    ],
  },
};

export default json;
