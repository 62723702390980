import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import { red } from '@material-ui/core/colors'
const json = {
	1: {
		unit: 'Unit 6',
		id: '4WB-U6-P47-E1',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page47/E1/Key/E1answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title: `
				Read the notes about a school trip. Put them in the correct order. 
			  `,
				color: '#234090',
			},
		],
		titleImage: '',
		questionImage: [],
		inputSize: 50,
		stylesTextInput: {
			background: 'none',
			borderBottom: 'none',
			fontSize: 30,
			height: 44,
			// textTransform: 'uppercase',
			width: 40,
		},
		maxLength: 1,
		textAlign: 'center',
		isHiddenCheck: true,
		questions: [
			{
				title: `
          <div style=" position: relative; display: flex ">
          <img src='img/FriendsPlus/Page47/E1/1.jpg' style='width:25cm'>
            <div style=" position: absolute; top: 0px; margin-left: 386px "><input id='3'></input></div>
            <div style=" position: absolute; top: 60px; margin-left: 386px "><input id='3'></input></div>
            <div style=" position: absolute; top: 0px; margin-left: 890px "><input id='3'></input></div>
            <div style=" position: absolute; top: 60px; margin-left: 890px "><input id='3'></input></div>
            </div>
          </div>
			  `,
				answer: ['3', '2', '4', '1'],
			},
		],
	},
	2: {
		unit: 'Unit 6',
		id: '4WB-U6-P47-E2',
		audio: '',
		video: '',
		component: T6,
		// exerciseKey: 'img/FriendsPlus/Page47/E2/Key/E2answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: `
				Plan a poster for a school trip. Make notes.
			  `,
				color: '#234090',
			},
		],
		titleImage: '',
		questionImage: [],
		inputSize: 50,
		maxLength: 40,
		stylesTextInput: {
			background: 'none',
			borderBottom: 'none',
			fontSize: 23,
			height: 29,
			// textTransform: 'uppercase',
			width: 490,
		},
		textAlign: 'center',
		hideBtnFooter: true,
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<div style=" position: relative; width:700px ">
          <img style="width:21cm" src='img/FriendsPlus/Page47/E2/1.jpg' />
             <div style=" position: absolute; top: 113px; left: 235px "><input id='0'></input></div>
             <div style=" position: absolute; top: 159px; left: 236px "><input id='1'></input></div>
             <div style=" position: absolute; top: 204px; left: 228px "><input id='2'></input></div>
             <div style=" position: absolute; top: 250px; left: 242px "><input id='3'></input></div>
					</div>
				
			  
			  `,
				answer: ['', '', '', ''],
			},
		],
	},
	3: {
		unit: 'Unit 6',
		id: '4WB-U6-P47-E3',
		audio: '',
		video: '',
		component: T6,
		// exerciseKey: 'img/FriendsPlus/Page47/E3/Key/E3answerKey.png',
		titleQuestion: [
			{
				num: '3',
				title: `
				Draw and make a poster for your school trip.
			  `,
				color: '#234090',
			},
		],
		titleImage: '',
		questionImage: [],
		inputSize: 50,
		maxLength: 40,
		stylesTextInput: {
			background: 'none',
			// borderBottom: "none",
			fontSize: 23,
			height: 29,
			// textTransform: 'uppercase',
			width: 420,
		},
		maxLength: 140,
		textAlign: 'left',
		textareaStyle: { width: '900px' },
		hideBtnFooter: true,
		isHiddenCheck: true,
		selectStyle: {
			color: '#00ADFE',
			width: 60,
			textAlign: 'center',
			// fontSize: 17,
		},
		// selectOptionRandom: true,
		// selectOptionValues: ['1', '2', '3', '4', '5'],
		questions: [
			{
				title: `
					<div style=" position: relative; width:700px ">
            <img src='img/FriendsPlus/Page47/E3/1.jpg' style='width:25cm'>
             <div style=" position: absolute; margin-top:-503px; margin-left: 356px "><input id='0' width='346px'></input></div>
             <div style=" position: absolute; margin-top:-455px; margin-left: 396px" ><input id='1' width='521px'></input></div>
             <div style=" position: absolute; margin-top:-359px; margin-left: 312px "><input id='2' width='608px'></input></div>
             <div style=" position: absolute; margin-top:-215px; margin-left: 340px "><input id='3' width='581px'></input></div>
             <div style=" position: absolute; margin-top:-118px; margin-left: 340px "><input id='4' width='581px'></input></div>
					</div>
				
			  
			  `,
				answer: ['', '', '', '', ''],
			},
		],
	},
	4: {
		unit: 'Unit 6',
		id: '4WB-U6-P47-E4',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page47/E4/Key/E4answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title: `
				Look at a friend's poster. What do you like about it?
			  `,
				color: '#234090',
			},
		],
		titleImage: '',
		questionImage: [],
		inputSize: 50,
		maxLength: 300,
		textAlign: 'center',
		textareaStyle: { width: '900px', height: 200 },
		hideBtnFooter: true,
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<div style=" position: relative ">
             <textarea id='0' row='6'></textarea>
					</div>
			  `,
				answer: [''],
			},
		],
	},
}

export default json
