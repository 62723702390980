import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import MatchDotsV2 from "../../components/ExcerciseTypes/LineTo/MatchDotsV2";
// import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'

const json = {
  1: {
    // Exercise num
    unit: "Unit 9",
    id: "4WB-U9-P67-E1",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page67/E1/Key/E1answerKey.png",
    // inputSize: 100,
    textAlign: "left",
    maxLength: 50,
    isHiddenCheck: true,
    checkUppercase: true,
    titleQuestion: [
      {
        num: "1",
        title: " Order the words.",
        color: "#234090",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 23,
      height: 30,
      width: 650,
    },
    questions: [
      {
        title: `
       <div style='position:relative'>
       <img style='width:22cm' src='img/FriendsPlus/Page67/E1/1.jpg' />

        <div style=" position: absolute; top: 175px; left: 36px">#</input></div>
        <div style=" position: absolute; top: 278px; left: 36px">#</input></div>
     
      </div>
            
          `,
        answer: [
          `I have breakfast with my mom.`,
          `Then I go to school at eight o'clock.`,
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 9",
    id: "4WB-U9-P67-E2",
    audio: "",
    component: MatchDotsV2,
    hideBtnFooter: true,
    // exerciseKey: 'img/FriendsPlus/Page67/E2/Key/E2answerKey.png',
    inputSize: 100,
    textAlign: "left",
    maxLength: 30,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "",
        title: "<div style='margin-left:80px'><span style='color:#234090;font-size:30px;font-weight:700'>2</span>&ensp;Draw the times you do these things.</div>",
        color: "#234090",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 23,
      height: 30,
      width: 650,
    },
    question: {
			DrawLines: {
				fontWeight: '120px',
				multipleLine: true,
				boxMatch: [
					//get up Clock 
					{
						boxMatchStyle: {
								position: 'absolute',
								top: '223px',
								left: '204px',
								width:12,
								height: 12,
								backgroundColor: 'black',
								borderRadius: '50px',
							},
						}, //tt
         			
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '175px',
							left: '206px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //0/12
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '183px',
							left: '229px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //1
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '196px',
							left: '247px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //2
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '223px',
							left: '254px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //3
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '249px',
							left: '248px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //4
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '268px',
							left: '232px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //5
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '277px',
							left: '206px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //6
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '269px',
							left: '178px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //7
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '254px',
							left: '162px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //8
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '225px',
							left: '155px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //9
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '205px',
							left: '159px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //10
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '184px',
							left: '176px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //11

					//have dinner CLOCK 
          {
						boxMatchStyle: {
							position: 'absolute',
							top: '444px',
							left: '344px',
							width:15,
							height: 15,
							backgroundColor: 'black',
							borderRadius: '50px',
						},
					}, //tt
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '396px',
							left: '343px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //0/12
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '403px',
							left: '367px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //13
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '417px',
							left: '386px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //14
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '444px',
							left: '394px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //15
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '470px',
							left: '388px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //16
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '489px',
							left: '370px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //17
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '497px',
							left: '345px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //18
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '489px',
							left: '318px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //19
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '475px',
							left: '301px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //20
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '445px',
							left: '295px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //21
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '426px',
							left: '297px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //22
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '404px',
							left: '315px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					},//23

					//	Have breakfast CLOCK 
          {
						boxMatchStyle: {
							position: 'absolute',
							top: '220px',
							left: '554px',
							width:15,
							height: 15,
							backgroundColor: 'black',
							borderRadius: '50px',
						},
					}, //tt
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '171px',
							left: '553px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //0/24
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '179px',
							left: '578px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //25
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '192px',
							left: '596px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //26
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '219px',
							left: '603px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //27
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '244px',
							left: '598px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //28
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '264px',
							left: '582px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //29
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '273px',
							left: '554px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //30
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '265px',
							left: '529px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //31
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '250px',
							left: '511px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //32
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '220px',
							left: '505px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //33
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '202px',
							left: '507px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //34
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '180px',
							left: '525px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //35

					//	Go To Bed CLOCK
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '441px',
							left: '699px',
							width: 15,
							height: 15,
							backgroundColor: 'black',
							borderRadius: '50px',
						},
					}, //tt
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '393px',
							left: '697px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //36 
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '400px',
							left: '721px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //37
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '414px',
							left: '740px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //38
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '440px',
							left: '747px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //39
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '467px',
							left: '741px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //40
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '486px',
							left: '727px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //41
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '494px',
							left: '699px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //42
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '487px',
							left: '672px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //43
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '471px',
							left: '655px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //44
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '443px',
							left: '649px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //45
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '424px',
							left: '652px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //46
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '402px',
							left: '669px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					},//47

					//	GO TO SCHOOL CLOCK 
          {
						boxMatchStyle: {
							position: 'absolute',
							top: '215px',
							left: '863px',
							width:15,
							height: 15,
							backgroundColor: 'black',
							borderRadius: '50px',
						},
					}, //tt
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '167px',
							left: '862px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //36 
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '175px',
							left: '887px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //37
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '189px',
							left: '904px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //38
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '215px',
							left: '912px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //39
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '240px',
							left: '907px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //40
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '260px',
							left: '892px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //41
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '269px',
							left: '863px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //42
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '260px',
							left: '838px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //43
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '245px',
							left: '821px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //44
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '216px',
							left: '814px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //45
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '198px',
							left: '818px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					}, //46
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '176px',
							left: '833px',
							width:8,
							height: 8,
							backgroundColor: 'rgb(67,79,161)',
							borderRadius: '50px',
						},
					},//59


					// kim giờ GET UP
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '192px',
							left: '204px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //0/12
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '196px',
							left: '220px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //1
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '205px',
							left: '230px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //2
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '222px',
							left: '235px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //3
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '239px',
							left: '232px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //4
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '251px',
							left: '223px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //5
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '255px',
							left: '206px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //6
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '252px',
							left: '190px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //7
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '241px',
							left: '178px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //8
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '225px',
							left: '174px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //9
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '210px',
							left: '177px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //10
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '197px',
							left: '188px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //11


					// kim giờ HAVE DINNER
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '415px',
							left: '342px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //0/12
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '417px',
							left: '357px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //13
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '427px',
							left: '368px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //14
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '443px',
							left: '372px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //15
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '459px',
							left: '368px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //16
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '470px',
							left: '360px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //17
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '475px',
							left: '345px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //18
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '471px',
							left: '331px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //19
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '462px',
							left: '319px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //20
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '447px',
							left: '315px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //21
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '432px',
							left: '319px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //22
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '420px',
							left: '328px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					},//23
					
					// Have breakfast CLOCK 
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '190px',
							left: '553px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //0/24
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '195px',
							left: '568px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //25
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '204px',
							left: '579px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //26
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '218px',
							left: '583px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //27
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '235px',
							left: '580px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //28
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '248px',
							left: '572px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //29
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '253px',
							left: '555px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //30
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '248px',
							left: '540px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //31
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '238px',
							left: '529px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //32
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '221px',
							left: '524px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //33
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '206px',
							left: '529px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //34
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '194px',
							left: '539px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //35
					
					// kim phút GO TO BED
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '413px',
							left: '697px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //36 
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '418px',
							left: '683px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //37
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '429px',
							left: '673px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //38
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '445px',
							left: '672px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //39
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '459px',
							left: '676px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //40
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '468px',
							left: '686px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //41
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '473px',
							left: '700px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //42
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '469px',
							left: '714px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //43
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '460px',
							left: '722px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //44
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '446px',
							left: '725px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //45
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '430px',
							left: '722px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //46
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '418px',
							left: '713px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					},//47


					// kim phút go to school
					
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '188px',
							left: '862px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //36 
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '192px',
							left: '848px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //37
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '202px',
							left: '838px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //38
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '217px',
							left: '834px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //39
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '232px',
							left: '839px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //40
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '242px',
							left: '849px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //41
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '248px',
							left: '863px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //42
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '242px',
							left: '879px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //43
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '232px',
							left: '889px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //44
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '216px',
							left: '893px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //45
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '202px',
							left: '888px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					}, //46
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '193px',
							left: '877px',
							width:8,
							height: 8,
							backgroundColor: 'gray',
							borderRadius: '50px',
						},
					},//59

				],
				answers: [
				],
				initialValue: [],
			},
			Layout: `
      <div><i style='font-size:18px; color: red'>*Please select the hour first and the minute after.</i></div>
      <img style='height: 15cm' src="img/FriendsPlus/Page67/E2/1.jpg" />
					<input id='0' type='boxMatch' />
					<input id='1' type='boxMatch' />
					<input id='2' type='boxMatch' />
					<input id='3' type='boxMatch' />
					<input id='4' type='boxMatch' />
					<input id='5' type='boxMatch' />
					<input id='6' type='boxMatch' />
					<input id='7' type='boxMatch' />
					<input id='8' type='boxMatch' />
					<input id='9' type='boxMatch' />
					<input id='10' type='boxMatch' />
					<input id='11' type='boxMatch' />
          
					<input id='12' type='boxMatch' />
					<input id='13' type='boxMatch' />
					<input id='14' type='boxMatch' />
					<input id='15' type='boxMatch' />
					<input id='16' type='boxMatch' />
					<input id='17' type='boxMatch' />
					<input id='18' type='boxMatch' />
					<input id='19' type='boxMatch' />
					<input id='20' type='boxMatch' />
					<input id='21' type='boxMatch' />
					<input id='22' type='boxMatch' />
					<input id='23' type='boxMatch' />

					<input id='24' type='boxMatch' />
					<input id='25' type='boxMatch' />
					<input id='26' type='boxMatch' />
					<input id='27' type='boxMatch' />
					<input id='28' type='boxMatch' />
					<input id='29' type='boxMatch' />
					<input id='30' type='boxMatch' />
					<input id='31' type='boxMatch' />
					<input id='32' type='boxMatch' />
					<input id='33' type='boxMatch' />
					<input id='34' type='boxMatch' />
					<input id='35' type='boxMatch' />

					<input id='36' type='boxMatch' />
					<input id='37' type='boxMatch' />
					<input id='38' type='boxMatch' />
					<input id='39' type='boxMatch' />
					<input id='40' type='boxMatch' />
					<input id='41' type='boxMatch' />
					<input id='42' type='boxMatch' />
					<input id='43' type='boxMatch' />
					<input id='44' type='boxMatch' />
					<input id='45' type='boxMatch' />
					<input id='46' type='boxMatch' />
					<input id='47' type='boxMatch' />

					<input id='47' type='boxMatch' />
					<input id='48' type='boxMatch' />
					<input id='49' type='boxMatch' />
					<input id='50' type='boxMatch' />
					<input id='51' type='boxMatch' />
					<input id='52' type='boxMatch' />
					<input id='53' type='boxMatch' />
					<input id='54' type='boxMatch' />
					<input id='55' type='boxMatch' />
					<input id='56' type='boxMatch' />
					<input id='57' type='boxMatch' />
					<input id='58' type='boxMatch' />

					<input id='59' type='boxMatch' />
					<input id='60' type='boxMatch' />
					<input id='61' type='boxMatch' />
					<input id='62' type='boxMatch' />
					<input id='63' type='boxMatch' />
					<input id='64' type='boxMatch' />

					<input id='65' type='boxMatch' />
					<input id='66' type='boxMatch' />
					<input id='67' type='boxMatch' />
					<input id='68' type='boxMatch' />
					<input id='69' type='boxMatch' />
					<input id='70' type='boxMatch' />
					<input id='71' type='boxMatch' />
					<input id='72' type='boxMatch' />
					<input id='73' type='boxMatch' />
					<input id='74' type='boxMatch' />
					<input id='75' type='boxMatch' />
					<input id='76' type='boxMatch' />

					<input id='77' type='boxMatch' />
					<input id='78' type='boxMatch' />
					<input id='79' type='boxMatch' />
					<input id='80' type='boxMatch' />
					<input id='81' type='boxMatch' />
					<input id='82' type='boxMatch' />
					<input id='83' type='boxMatch' />
					<input id='84' type='boxMatch' />
					<input id='85' type='boxMatch' />
					<input id='86' type='boxMatch' />
					<input id='87' type='boxMatch' />
					<input id='88' type='boxMatch' />

					<input id='89' type='boxMatch' />
					<input id='90' type='boxMatch' />
					<input id='91' type='boxMatch' />
					<input id='92' type='boxMatch' />
					<input id='93' type='boxMatch' />
					<input id='94' type='boxMatch' />
					<input id='95' type='boxMatch' />
					<input id='96' type='boxMatch' />
					<input id='97' type='boxMatch' />
					<input id='98' type='boxMatch' />
					<input id='99' type='boxMatch' />
					<input id='100' type='boxMatch' />

					<input id='101' type='boxMatch' />
					<input id='102' type='boxMatch' />
					<input id='103' type='boxMatch' />
					<input id='104' type='boxMatch' />
					<input id='105' type='boxMatch' />
					<input id='106' type='boxMatch' />
					<input id='107' type='boxMatch' />
					<input id='108' type='boxMatch' />
					<input id='109' type='boxMatch' />
					<input id='110' type='boxMatch' />
					<input id='111' type='boxMatch' />
					<input id='112' type='boxMatch' />

					<input id='113' type='boxMatch' />
					<input id='114' type='boxMatch' />
					<input id='115' type='boxMatch' />
					<input id='116' type='boxMatch' />
					<input id='117' type='boxMatch' />
					<input id='118' type='boxMatch' />
					<input id='119' type='boxMatch' />
					<input id='120' type='boxMatch' />
					<input id='121' type='boxMatch' />
					<input id='122' type='boxMatch' />
					<input id='123' type='boxMatch' />
					<input id='124' type='boxMatch' />
					
      `,
    },
  },
  3: {
    // Exercise num
    unit: "Unit 9",
    id: "4WB-U9-P67-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page67/E3/Key/E3answerKey.png",
    hideBtnFooter: true,
    component: T6,
    // totalInput: 2,
    titleQuestion: [
      {
        num: "3",
        title: "Draw and write about your day.",
        color: "#234090",
      },
    ],
    selectStyle: {
      height: 35,
      width: 35,
      background: "none",
      border: "none",
      textAlign: "center",
    },
    textareaStyle: {
      //   borderBottom:"2px solid",
      //   background: 'red',

      width: 300,
      fontSize: 21,
      height: 100,
    },
    questionImage: [],
    questions: [
      {
        title: `
        <img  style='width: 23cm' src='img/FriendsPlus/Page67/E3/1.jpg' />
        <div style='position: relative; font-size:21px'>
          <div style="position: absolute; top: -315px; left: 506px; ">I have breakfast at <textarea id='0' row='3'></div>
          <div style="position: absolute; top: -168px; left: 485px;display:flex ">I &ensp;<textarea id='1' rows='3'></textarea></div>          
        
        </div> 
        `,
        answer: ["", ""],
      },
    ],
  },
  4: {
    unit: "Unit 9",
    id: "4WB-U9-P67-E4",
    audio: "",
    video: "",
    hideBtnFooter: true,
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: "Tell your friend about your day.",
        color: "#234090",
        // prefix: { icons: ["far fa-comment"] },
      },
    ],
    questionImage: [],
  },
};

export default json;
