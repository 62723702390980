import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 4',
		id: '4WB-U4-P31-E1',
		audio: '',
		component: T6,
		hideBtnFooter: true,
		exerciseKey: 'img/FriendsPlus/Page31/E1/Key/E1answerKey.png',
		// exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
		inputSize: 250,
		textAlign: 'center',
		maxLength: 40,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '1',
				title: 'Write.',
				color: '#234090',
			},
		],
		stylesTextInput: {
			background: 'none',
			borderBottom: 'solid 2px gray',
			fontSize: 25,
			height: 30,
			width: 400,
		},
		questions: [
			{
				title: `
       <div style='position:relative; font-size: 25px; margin-top: 30px'>

        <div style='margin-bottom: 20px'><b>1.&ensp;</b>What do you have on Mondays?&ensp;<input id='0' width='393px'></input></div>
        <div style='margin-bottom: 20px'><b>2.&ensp;</b>What do you have on Wednesdays?&ensp;<input id='1' width='350px'></input></div>
        <div style='margin-bottom: 20px'><b>3.&ensp;</b>When do you have English?&ensp;<input id='2' width='350px'></input></div>
        <div style='margin-bottom: 20px'><b>4.&ensp;</b>What do you have on Fridays?&ensp;<input id='3' width='414px'></input></div>
        <div style='margin-bottom: 20px'><b>5.&ensp;</b>When do you have Vietnamese?&ensp;<input id='4' width='393px'></input></div>
     
      </div>
            
          `,
				answer: ['', '', '', '', ''],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 4',
		id: '4WB-U4-P31-E2',
		audio: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page31/E2/Key/E2answerKey.png',
		// exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
		inputSize: 150,
		textAlign: 'center',
		maxLength: 10,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '2',
				title: 'Write.',
				color: '#234090',
			},
		],
		stylesTextInput: {
			background: 'none',
			borderBottom: 'none',
			fontSize: 17,
			width: 150,
		},
		questions: [
			{
				title: `
       <div style='position:relative'>
       <img style='width:15cm' src='img/FriendsPlus/Page31/E2/1.jpg' />

        <div style=" position: absolute; top:463px; left: 24px; ">#</div>
        <div style=" position: absolute; top: 43px; left: 351px; ">#</div>
        <div style=" position: absolute; top: 451px; left: 310px; ">#</div>
     
      </div>
            
          `,
				answer: ["you can't", 'Can we', 'You can'],
			},
		],
	},
}

export default json
