// import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
// import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
// import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  // 1: {
  //   // Exercise num
  //   unit: "Unit 9",
  //   id: "4WB-U9-P63-E1",
  //   audio: "",
  //   video: "",
  //   exerciseKey: "img/FriendsPlus/Page63/E1/Key/E1answerKey.png",
  //   component: DesignUnderLine,
  //   totalInput: 3,
  //   titleQuestion: [
  //     {
  //       num: "1",
  //       title: "Write.",
  //       color: "#234090",
  //     },
  //   ],
  //   // isAllowSubmit: false,
  //   questionImage: [
  //     // Q1
  //     [{ url: "img/FriendsPlus/Page63/E1/1.jpg" }],
  //     [
  //       { url: "img/FriendsPlus/Page63/E1/2.jpg" },
  //       { url: "img/FriendsPlus/Page63/E1/3.jpg", input: 1, isCorrect: true },
  //       { url: "img/FriendsPlus/Page63/E1/4.jpg" },
  //       { url: "img/FriendsPlus/Page63/E1/5.jpg", input: 1 },
  //       { url: "img/FriendsPlus/Page63/E1/6.jpg" },
  //       { url: "img/FriendsPlus/Page63/E1/7.jpg", input: 1 },
  //       { url: "img/FriendsPlus/Page63/E1/8.jpg" },
  //     ],
  //     // Q2
  //     [{ url: "img/FriendsPlus/Page63/E1/9.jpg" }],
  //     [
  //       { url: "img/FriendsPlus/Page63/E1/10.jpg" },
  //       { url: "img/FriendsPlus/Page63/E1/11.jpg", input: 2 },
  //       { url: "img/FriendsPlus/Page63/E1/12.jpg" },
  //       { url: "img/FriendsPlus/Page63/E1/13.jpg", input: 2 },
  //       { url: "img/FriendsPlus/Page63/E1/14.jpg" },
  //       { url: "img/FriendsPlus/Page63/E1/15.jpg", input: 2, isCorrect: true },
  //       { url: "img/FriendsPlus/Page63/E1/16.jpg" },
  //     ],
  //     // Q3
  //     [{ url: "img/FriendsPlus/Page63/E1/17.jpg" }],
  //     [
  //       { url: "img/FriendsPlus/Page63/E1/18.jpg" },
  //       { url: "img/FriendsPlus/Page63/E1/19.jpg", input: 3 },
  //       { url: "img/FriendsPlus/Page63/E1/20.jpg" },
  //       { url: "img/FriendsPlus/Page63/E1/21.jpg", input: 3, isCorrect: true },
  //       { url: "img/FriendsPlus/Page63/E1/22.jpg" },
  //       { url: "img/FriendsPlus/Page63/E1/23.jpg", input: 3 },
  //       { url: "img/FriendsPlus/Page63/E1/24.jpg" },
  //     ],
  //   ],
  // },
  1: {
    unit: "Unit 9",
    id: "4WB-U9-P63-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page63/E1/Key/E1answerKey.png",
    component: Circle_Write,
    checkUppercase: true,
    titleQuestion: [
      {
        num: "1",
        title: "Write.",
        color: "#234090",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 30,
        inputStyle: {
          width: 280,
          fontSize: 23,
          textAlign: "center",
          height: 27,
        },
        answers: [``],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px 5px",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
        },
        selectWordStyle: {
          padding: "5px 5px",

          border: "2px solid black",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a._get_up &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; b._gets_up &ensp;&ensp;&ensp;&ensp;&ensp;&ensp; c._get`, //0
          `a._do_have &ensp;&ensp;&ensp;&ensp;&ensp; b._has &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; c._have`, //0
          `a._in &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; b._in_the &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; c._on`, //0
        ],
        answers: ["0-0", "1-8", "2-4"],
        initialValue: [],
      },
      Layout: `
      <div style=' margin-top: 20px; line-height: 50px; position: relative; padding-right: 30px; width: max-content; height: 700px'>
      <img style="width:22cm; " src='img/FriendsPlus/Page63/E1/1.jpg' />
        <div style='margin-left: 50px'>
        <span style='margin-right: 105px'>a. you do</span>
        <span style='margin-right: 100px; padding: 5px; border: 2px solid gray; border-radius: 50%'>b. do you</span>
        <span style='margin-right: 50px'>c. does you</span>
        </div>
				<div style='position: absolute; top: 398px; left: 42px'><input id='0' type='Circle'/></div>
        <div style='position: absolute; top: 464px; left: 42px'><input id='1' type='Circle'/></div>
				<div style='position: absolute; top: 534px; left: 42px'><input id='2' type='Circle'/></div>

        
	    </div>
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Unit 9",
    id: "4WB-U9-P63-E2",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page63/E2/Key/E2answerKey.png",
    // inputSize: 40,
    textAlign: "center",
    maxLength: 50,
    checkUppercase: true,
    stylesTextInput: {
      background: "none",
      fontSize: 22,
      height: 30,
      width: 380,
      borderBottom: "none",
    },
    titleQuestion: [
      {
        num: "2",
        title: "Look and write.",
        color: "#234090",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='position:relative'>
        <img style='width:23cm' src="img/FriendsPlus/Page63/E2/1.jpg"/>
        <div style="position: absolute; top:346px; left: 28px; "><input id='0' ></input></div>
        <div style="position: absolute; top:142px; left: 468px; "><input id='1' ></input></div>
        <div style="position: absolute; top:346px; left: 468px; "><input id='2' ></input></div>
        </div>
            
          `,
        answer: [
          `It's three o'clock in the afternoon.`,
          `It's ten o'clock in the morning.`,
          `It's six o'clock in the evening.`,
        ],
      },
    ],
  },
};

export default json;
