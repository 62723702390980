import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import Circle_Write2 from "../../components/ExcerciseTypes/Circle_Write2";
const json = {
	1: {
		// Exercise num
		unit: 'Unit 4',
		id: '4WB-U4-P34-E1',
		audio: '',
		hideBtnFooter: true,
		// exerciseKey: 'img/FriendsPlus/Page7/Key/E1answerKey.png',
		component: T6,
		inputSize: 250,
		textAlign: 'center',
		maxLength: 18,

		titleQuestion: [
			{
				num: '1',
				title: 'Read.',
				color: '#234090',
			},
		],
		questions: [
			{
				title: `	
       
            <div> <img style='width:22cm' src='img/FriendsPlus/Page34/E1/1.jpg' /> </div>
          `,
				answer: [''],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 4',
		id: '4WB-U4-P34-E2',
		audio: '',
		exerciseKey: 'img/FriendsPlus/Page34/E2/Key/E2answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title:
					'Read again. Circle two things students can do. Underline two things they can’t do.',
				color: '#234090',
			},
		],
		component: Circle_Write2,
		question: {
		  Write: {
			isHiddenCheck: true,
			maxLength: 11,
			inputStyle: {
			  width: 149,
			  fontSize: 23,
			  borderBottom: "none",
			  textAlign: "center",
			},
			answers: [""],
			initialValue: [],
		  },
		  
	
		  Circle: {
			initialWordStyle: {
			//   padding: 5,
			color:"black",
			  // borderRadius: "50%",
			//   borderColor: "transparent"
			},
			selectWordStyle: {
				// padding:5,
			   color:"black",
			   border: "2px solid gray",
				borderRadius: "50%" },
			limitSelect: 2,
			listWords: [
			  `This_is_my schoolyard. It is for 
			  students in grades 4 and 5.<br> 
			  Grade 1, 2, and 3 students play in 
			  a different schoolyard.<br>
			  <div>
			  We can play_ball_games and we 
			  can talk_with_our_friends. We can’t 
			  ride_our_bikes or throw_trash_on_the_ground. </div>
			  `,
			  `This_is_my schoolyard. It is for 
			  students in grades 4 and 5.<br> 
			  Grade 1, 2, and 3 students play in 
			  a different schoolyard.<br>
			  <div>
			  We can play_ball_games and we 
			  can talk_with_our_friends. We can’t 
			  ride_our_bikes or throw_trash_on_the_ground. </div>
			  `
			],
			answers: ['0-106', '0-126', '1-144', '1-148'],
			initialValue: [],
		  },
		  Layout: `
			<div style='position: relative;background:none'>
		  	  <div style='font-weight:bold;font-style:italic;margin-left:20px'>
				Circle two things students can do
				<div style='position: absolute; top:0px; left:707px'>
					Underline two things they can’t do
				</div>
			  </div>
			  <img style='height: 12cm' src='img/FriendsPlus/Page34/E1/a.jpg' />
			  <img style='height: 10cm' src='img/FriendsPlus/Page34/E1/b.jpg' />
			  <img style='height: 12cm' src='img/FriendsPlus/Page34/E1/a.jpg' />

			  <div style='position: absolute; top:119px; left:59px;font-size:21px;width:85mm	'>
				<input id='0' type='Circle'>
			  </div>
			  <div style='position: absolute; top:119px; left:747px;font-size:21px;width:85mm	'>
				<input id='1' type='Underline'>
			  </div>
			 
			  
			  
			 
			</div> 
		  `,
		},
	  },
	3: {
		// Exercise num
		unit: 'Unit 4',
		id: '4WB-U4-P34-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page34/E3/Key/E3answerKey.png',

		component: DesignUnderLine,
		totalInput: 3,
		titleQuestion: [
			{
				num: '3',
				title: 'Read again. Fill in the correct circle.',
				color: '#234090',
			},
		],
		// isAllowSubmit: false,
		questionImage: [
			[{ url: 'img/FriendsPlus/Page34/E3/1.jpg' }],
			[{ url: 'img/FriendsPlus/Page34/E3/2.jpg' }],
			// Row 1
			[
				{ url: 'img/FriendsPlus/Page34/E3/3.jpg' },
				{ url: 'img/FriendsPlus/Page34/E3/4.jpg', input: 1, isCorrect: true },
				{ url: 'img/FriendsPlus/Page34/E3/5.jpg' },
				{ url: 'img/FriendsPlus/Page34/E3/6.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page34/E3/7.jpg' },
				{ url: 'img/FriendsPlus/Page34/E3/8.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page34/E3/9.jpg' },
			],
			// Row 2
			[{ url: 'img/FriendsPlus/Page34/E3/10.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page34/E3/11.jpg' },
				{ url: 'img/FriendsPlus/Page34/E3/12.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page34/E3/13.jpg' },
				{ url: 'img/FriendsPlus/Page34/E3/14.jpg', input: 2, isCorrect: true },
				{ url: 'img/FriendsPlus/Page34/E3/15.jpg' },
				{ url: 'img/FriendsPlus/Page34/E3/16.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page34/E3/17.jpg' },
			],
			// Row 3
			[{ url: 'img/FriendsPlus/Page34/E3/18.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page34/E3/19.jpg' },
				{ url: 'img/FriendsPlus/Page34/E3/20.jpg', input: 3 },
				{ url: 'img/FriendsPlus/Page34/E3/21.jpg' },
				{ url: 'img/FriendsPlus/Page34/E3/22.jpg', input: 3 },
				{ url: 'img/FriendsPlus/Page34/E3/23.jpg' },
				{ url: 'img/FriendsPlus/Page34/E3/24.jpg', input: 3, isCorrect: true },
				{ url: 'img/FriendsPlus/Page34/E3/25.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page34/E3/26.jpg' }],
		],
	},
}

export default json
