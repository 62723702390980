import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import MatchDotsWrite from '../../components/ExcerciseTypes/LineTo/MatchDotsWrite'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 2',
		id: '4WB-U2-P19-E1',
		audio: 'Audios/Track 08.mp3',
		video: '',
		component: MatchDotsWrite,
		exerciseKey: 'img/FriendsPlus/Page19/E1/Key/E1answerKey.png',
		titleQuestion: [
		  {
		   
					num: '1',
					title:
						"Write and match. Listen and check. <headphone name='08' src='Audios/Track 08.mp3'></headphone>",
					color: '#234090',
		  },
		],
		// stylesTextInput: {
		// 		background: 'none',
		// 		borderBottom: 'none',
		// 		fontSize: 23,
		// 		height: 35,
		// 		width: 40,
		// 	},
		inputSize: 50,

		question: {
			Write: {
				underlineStyle: {},
				inputStyle: {
				  width: 40,
				  textAlign: "center",
				  fontSize: 24,
				  color: "black",
				  marginTop:"3px",
				  maxLength: 1,
				},
				isHiddenCheck:true,

				answers: ['o','p','o','p','p','n'],
				initialValue: [],
			  },
		  DrawLines: {
			multipleLine: false,
			boxMatch: [
			 
			  {
				boxMatchStyle: {
				  position: 'absolute',
				  top: '244px',
				  left: '94px',
				  width: '160px',
				  height: '90px',
				  border: 'none',
				  background: 'none',
				},
			  }, // 2
			  {
				boxMatchStyle: {
				  position: 'absolute',
				  top: '383px',
				  left: '94px',
				  width: '160px',
				  height: '90px',
				  border: 'none',
				  background: 'none',
				},
			  }, // 3
			  
			  {
				boxMatchStyle: {
				  position: 'absolute',
				  top: '107px',
				  left: '602px',
				  width: '160px',
				  height: '90px',
				  border: 'none',
				  background: 'none',
				},
			  }, // B
			  {
				boxMatchStyle: {
				  position: 'absolute',
				  top: '244px',
				  left: '602px',
				  width: '160px',
				  height: '90px',
				  border: 'none',
				  background: 'none',
				},
			  }, // C
			 
			 
			 
			],
			answers: ['0-2', '1-3'],
			initialValue: [],
		  },
		  Layout: `
				<headphone name='08' src=''></headphone>
	
			<div style='position: relative; width:100%; text-align:right' >
				<img style='width:18cm' src="img/FriendsPlus/Page19/E1/1.jpg" />    
				
				<div style=" position: absolute; top: 287px; left: 43px;">
				<input id='0'/></div>
				<div style=" position: absolute; top: 427px; left: 46px;">
				<input  id='1'/></div>
	
				<div style=" position: absolute; top: 152px; left: 565px;">
				<input  id='2'/></div>
				<div style=" position: absolute; top: 288px; left: 552px;">
				<input  id='3'/></div>
				<div style=" position: absolute; top: 288px; left: 598px;">
				<input  id='4'/></div>
				<div style=" position: absolute; top: 425px; left: 575px;">
				<input  id='5'/></div>
				</div>  
			  <input id='0' type= 'boxMatch' />
			  <input id='1' type= 'boxMatch' />
			  <input id='2' type= 'boxMatch' />
			  <input id='3' type= 'boxMatch' />
			
		  `,
		},
	  },
	
	2: {
		// Exercise num
		unit: 'Unit 2',
		id: '4WB-U2-P19-E2',
		audio: 'Audios/Track 09.mp3',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page19/E2/Key/E2answerKey.png',
		// exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
		inputSize: 105,
		textAlign: 'center',
		maxLength: 8,
		stylesTextInput: {
			background: 'none',
			borderBottom: 'none',
			fontSize: 21,
			height: 22,
			width: 120,
		},
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '2',
				title:
					"  Write. Listen and chant. <headphone name='09' src='Audios/Track 09.mp3'></headphone> ",
				color: '#234090',
			},
		],

		questions: [
			{
				title: `
        <headphone name='09' src='đường dẫn hồi a bỏ audio r thêm sau'></headphone>

        <div style='position: relative;margin-top:20px; width:100%; text-align:right' >
            <img style='width:20cm' src="img/FriendsPlus/Page19/E2/1.jpg" />    
            <div style=" width:125px; position: absolute; top: 85px; left: 393px;"><input id='1' ></input></div>
            <div style="width:120px; position: absolute; top: 131px; left: 273px"><input id='2' ></input></div>
            <div style="width:130px; position: absolute; top: 131px; left: 420px"><input id='3' ></input></div>
            <div style="width:125px; position: absolute; top: 176px; left: 327px"><input id='4' ></input></div>
            <div style="width:140px; position: absolute; top: 268px; left: 304px"><input id='4' ></input></div>
            <div style="width:125px; position: absolute; top: 313px; left: 318px"><input id='4' ></input></div>
            </div>
            
          `,
				answer: ['plum', 'nest', 'orange', 'purple', 'octopus', 'parrot'],
			},
		],
	},
}

export default json
