//import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
//import ScoreByColor from "../../components/ExcerciseTypes/Design/ScoreByColor";
// import UI from '../../components/ExcerciseTypes/Design/UserInterface'
//import UI from "../../components/ExcerciseTypes/Design/UserInterfaceV2";
//import PaintColorType from "../../components/ExcerciseTypes/Design/PaintColorType";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "4WB-U1-P10-E1",
    audio: "",
    // hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page10/Key/E1answerKey.png",
    component: T6,
    inputSize: 550,
    maxLength: 60,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 23,
      height: 29,
      // textTransform: "uppercase",
      borderStyle: "2px dotted",
      textAlign: "left",
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title: "Find and write the words.",
        color: "#234090",
      },
    ],
    questions: [
      {
        title: `

            <div> <img style='width:22cm' src='img/FriendsPlus/Page10/E1/1.jpg' /> </div>
  			<div>
  			<i style='font-size:18px; color: rgb(245,54,92)'>*Write the selected words in the sorted order of the list above and between words seperated by commas (,).</i></div>
        <div style='display: flex; margin-top: 30px'>
        <b>Answer:&ensp;</b><i style='color: gray'>firefighter,</i>#

        </div>
          `,
        answer: [
          "doctor, pilot, student / doctor, student, pilot / pilot, doctor, student / pilot, student, doctor / student, pilot, doctor / student, doctor, pilot /  student, pilot, doctor",
        ],
      },
    ],
  },

  // 1: {
  //   // Exercise num
  //   unit: "Unit 1",
  //   id: "4WB-U1-P10-E1",
  //   audio: "",
  //   video: "",
  //   component: MatchDots,
  //   exerciseKey: "img/FriendsPlus/Page4/E2/Key/E2answerKey.png",
  //   titleQuestion: [
  //     {
  //       num: "1",
  //       title: "Find and write the words.",
  //       color: "#234090",
  //     },
  //   ],
  //   question: {
  //     DrawLines: {
  //       multipleLine: true,
  //       boxMatch: [
  //         //above
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "377px",
  //             left: "510px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //1
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "372px",
  //             left: "535px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //2
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "361px",
  //             left: "557px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //3
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "343px",
  //             left: "574px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //4
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "321px",
  //             left: "589px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //5
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "296px",
  //             left: "602px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //6
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "263px",
  //             left: "618px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //7
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "230px",
  //             left: "639px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //8
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "209px",
  //             left: "663px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //9
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "193px",
  //             left: "692px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //10
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "180px",
  //             left: "725px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //11
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "175px",
  //             left: "765px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //12
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "179px",
  //             left: "798px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //13
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "192px",
  //             left: "833px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //14
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "212px",
  //             left: "863px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //15
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "233px",
  //             left: "891px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //16
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "248px",
  //             left: "913px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //17
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "255px",
  //             left: "936px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //18
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "248px",
  //             left: "960px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //19

  //         //below
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "203px",
  //             left: "314px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //1
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "199px",
  //             left: "335px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //2
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "197px",
  //             left: "356px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //3
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "198px",
  //             left: "379px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //4
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "199px",
  //             left: "397px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //5
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "205px",
  //             left: "412px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //6
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "213px",
  //             left: "431px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //7

  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "222px",
  //             left: "452px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //8
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "228px",
  //             left: "469px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //9
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "238px",
  //             left: "493px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //10
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "246px",
  //             left: "520px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //11
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "251px",
  //             left: "547px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //12
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "248px",
  //             left: "586px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //13
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "241px",
  //             left: "610px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //14
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "226px",
  //             left: "643px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //15
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "216px",
  //             left: "663px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //16
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "204px",
  //             left: "693px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //17
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "199px",
  //             left: "717px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //18
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "235px",
  //             left: "932px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //19
  //       ],
  //       answers: [
  //         "0-27",
  //         "33-6",
  //         "13-40",
  //         "16-43",
  //         "21-22",
  //         "23-51",
  //         "26-45",
  //         "0-1",
  //         "1-2",
  //         "2-3",
  //         "3-4",
  //         "4-5",
  //         "5-6",
  //         "27-28",
  //         "28-29",
  //         "29-30",
  //         "30-31",
  //         "31-32",
  //         "32-33",
  //         "6-7",
  //         "7-8",
  //         "8-9",
  //         "10-9",
  //         "10-11",
  //         "11-12",
  //         "12-13",
  //         "33-34",
  //         "34-35",
  //         "35-36",
  //         "36-37",
  //         "37-38",
  //         "38-39",
  //         "39-40",
  //         "13-14",
  //         "14-15",
  //         "15-16",
  //         "40-41",
  //         "41-42",
  //         "42-43",
  //         "16-17",
  //         "17-18",
  //         "18-19",
  //         "19-20",
  //         "20-21",
  //         "43-44",
  //         "44-46",
  //         "46-47",
  //         "47-48",
  //         "48-49",
  //         "49-50",
  //         "50-51",
  //         "22-23",
  //         "23-24",
  //         "24-25",
  //         "25-26",
  //         "51-52",
  //         "52-53",
  //         "45-53",
  //       ],
  //       initialValue: [],
  //     },
  //     Layout: `
  //     <div style='position: relative' >
  //     <img style='width:28cm; height: 13cm' src='img/FriendsPlus/Page10/E1/1.jpg' />

  //     <input id='0' type= 'boxMatch' />
  //     <input id='1' type= 'boxMatch' />
  //     <input id='2' type= 'boxMatch' />
  //     <input id='3' type= 'boxMatch' />
  //     <input id='4' type= 'boxMatch' />
  //     <input id='5' type= 'boxMatch' />
  //     <input id='6' type= 'boxMatch' />
  //     <input id='7' type= 'boxMatch' />
  //     <input id='8' type= 'boxMatch' />
  //     <input id='9' type= 'boxMatch' />
  //     <input id='10' type= 'boxMatch' />
  //     <input id='11' type= 'boxMatch' />
  //     <input id='12' type= 'boxMatch' />
  //     <input id='13' type= 'boxMatch' />
  //     <input id='14' type= 'boxMatch' />
  //     <input id='15' type= 'boxMatch' />
  //     <input id='16' type= 'boxMatch' />
  //     <input id='17' type= 'boxMatch' />
  //     <input id='18' type= 'boxMatch' />

  //     <input id='19' type= 'boxMatch' />
  //     <input id='20' type= 'boxMatch' />
  //     <input id='21' type= 'boxMatch' />
  //     <input id='22' type= 'boxMatch' />
  //     <input id='23' type= 'boxMatch' />
  //     <input id='24' type= 'boxMatch' />
  //     <input id='25' type= 'boxMatch' />
  //     <input id='26' type= 'boxMatch' />
  //     <input id='27' type= 'boxMatch' />
  //     <input id='28' type= 'boxMatch' />
  //     <input id='29' type= 'boxMatch' />
  //     <input id='30' type= 'boxMatch' />
  //     <input id='31' type= 'boxMatch' />
  //     <input id='32' type= 'boxMatch' />
  //     <input id='33' type= 'boxMatch' />
  //     <input id='34' type= 'boxMatch' />
  //     <input id='35' type= 'boxMatch' />
  //     <input id='36' type= 'boxMatch' />
  //     <input id='37' type= 'boxMatch' />

  //     </div>

  //     `,
  //   },
  // },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "4WB-U1-P10-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page10/Key/E2answerKey.png",

    component: DesignUnderLine,
    totalInput: 3,
    titleQuestion: [
      {
        num: "2",
        title: " Fill in the correct circle.",
        color: "#234090",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page10/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page10/E2/2.jpg" },
        { url: "img/FriendsPlus/Page10/E2/3.jpg", input: 1 },
      ],
      [{ url: "img/FriendsPlus/Page10/E2/4.jpg" }],
      [
        { url: "img/FriendsPlus/Page10/E2/5.jpg" },
        { url: "img/FriendsPlus/Page10/E2/6.jpg", input: 1, isCorrect: true },
      ],
      [{ url: "img/FriendsPlus/Page10/E2/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page10/E2/8.jpg" },
        { url: "img/FriendsPlus/Page10/E2/9.jpg", input: 1 },
      ],
      [{ url: "img/FriendsPlus/Page10/E2/10.jpg" }],
      [
        { url: "img/FriendsPlus/Page10/E2/11.jpg" },
        { url: "img/FriendsPlus/Page10/E2/12.jpg", input: 2 },
        { url: "img/FriendsPlus/Page10/E2/13.jpg" },
        { url: "img/FriendsPlus/Page10/E2/14.jpg", input: 3, isCorrect: true },
      ],
      [{ url: "img/FriendsPlus/Page10/E2/15.jpg" }],
      [
        { url: "img/FriendsPlus/Page10/E2/16.jpg" },
        { url: "img/FriendsPlus/Page10/E2/17.jpg", input: 2 },
        { url: "img/FriendsPlus/Page10/E2/18.jpg" },
        { url: "img/FriendsPlus/Page10/E2/19.jpg", input: 3 },
      ],
      [{ url: "img/FriendsPlus/Page10/E2/20.jpg" }],
      [
        { url: "img/FriendsPlus/Page10/E2/21.jpg" },
        { url: "img/FriendsPlus/Page10/E2/22.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page10/E2/23.jpg" },
        { url: "img/FriendsPlus/Page10/E2/24.jpg", input: 3 },
      ],
      [{ url: "img/FriendsPlus/Page10/E2/25.jpg" }],
    ],
  },
};

export default json;
