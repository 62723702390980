import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 4',
		id: '4WB-U4-P32-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page32/E1/Key/E1answerKey.png',

		component: T6,
		// totalInput: 2,
		titleQuestion: [
			{
				num: '1',
				title: 'Look and read. Write a check (✓) or a cross (✗) in the box.',
				color: '#234090',
			},
		],
		selectStyle: {
			height: 35,
			width: 35,
			textAlign: 'center',
		},
		selectOptionRandom: true,
		selectOptionValues: ['✓', '✗'],
		questionImage: [],
		questions: [
			{
				title: `
        <img  style='width:24cm' src='img/FriendsPlus/Page32/E1/1.jpg' />
        <div style='position: relative;'>
          <div style=" position: absolute; top: -40px; left: 267px; "><select id=0></select></div>
          <div style=" position: absolute; top: -100px; left: 831px; "><select id=1></select></div>
          <div style=" position: absolute; top: -41px; left: 831px; "><select id=2></select></div>
          
          
         
        </div> 
        `,
				answer: ['✗', '✓', '✗'],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 4',
		id: '4WB-U4-P32-E2',
		audio: 'Audios/Track 15.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page32/E2/Key/E2answerKey.png',

		component: DesignUnderLine,
		totalInput: 3,
		titleQuestion: [
			{
				num: '2',
				title:
					"Listen and check (✓). <headphone name='15' src='Audios/Track 15.mp3'></headphone>",
				color: '#234090',
			},
		],
		// isAllowSubmit: false,
		questionImage: [
			[{ url: 'img/FriendsPlus/Page32/E2/1.jpg' }],
			// Row 1
			[
				{ url: 'img/FriendsPlus/Page32/E2/2.jpg' },
				{ url: 'img/FriendsPlus/Page32/E2/3.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page32/E2/4.jpg' },
				{ url: 'img/FriendsPlus/Page32/E2/5.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page32/E2/6.jpg' },
				{ url: 'img/FriendsPlus/Page32/E2/7.jpg', input: 1, isCorrect: true },
			],
			// Row 2
			[{ url: 'img/FriendsPlus/Page32/E2/8.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page32/E2/9.jpg' },
				{ url: 'img/FriendsPlus/Page32/E2/10.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page32/E2/11.jpg' },
				{ url: 'img/FriendsPlus/Page32/E2/12.jpg', input: 2, isCorrect: true },
				{ url: 'img/FriendsPlus/Page32/E2/13.jpg' },
				{ url: 'img/FriendsPlus/Page32/E2/14.jpg', input: 2 },
			],
			// Row 3
			[{ url: 'img/FriendsPlus/Page32/E2/15.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page32/E2/16.jpg' },
				{ url: 'img/FriendsPlus/Page32/E2/17.jpg', input: 3, isCorrect: true },
				{ url: 'img/FriendsPlus/Page32/E2/18.jpg' },
				{ url: 'img/FriendsPlus/Page32/E2/19.jpg', input: 3 },
				{ url: 'img/FriendsPlus/Page32/E2/20.jpg' },
				{ url: 'img/FriendsPlus/Page32/E2/21.jpg', input: 3 },
			],
		],
	},
}

export default json
