import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
const json = {
  1: {
    // Exercise num
    unit: "Stater",
    id: "4WB-S-P4-E1",
    audio: "",
    component: T6,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page4/E1/Key/E1answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 130,
    textAlign: "center",
    maxLength: 8,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 22,
      height: 27,
      // textTransform: 'uppercase',
    },

    titleQuestion: [
      {
        num: "1",
        title: "Write.",
        color: "#234090",
      },
    ],
    hintBox: [
      {
        src: [
          "Tim",
          "brown",
          "straight",
          "short",
          "<s>Rosy</s>",
          "green",
          "curly",
          "Billy",
        ],
        width: 900,
        borderColor: "rgb(8 177 239)",
        bgColor: "none",
        fontSize: 23,
      },
    ],
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <div style='position: relative; display:flex;margin-top:20px; line-height:37px; font-size: 22px' >
          <img style="width:3.5cm; height: 3.5cm"   src="img/FriendsPlus/Page4/E1/1.jpg" />
          <div >
            Her name’s <u style='color:gray ' >&ensp;Rosy&ensp;</u> <br> She has#hair.<br> She has# eyes.
          </div>
        </div>
        <div style='display:flex;margin:20px 0px; line-height:37px; font-size: 22px' >
          <img style="width:3.5cm; height: 3.5cm"  src="img/FriendsPlus/Page4/E1/2.jpg" />
          <div >
            His name’s#.<br>
            He’s Rosy’s cousin.<br>
            He has#hair.<br>
            He has#eyes.<br>
          </div>
        </div>
        <div style='display:flex; line-height:37px; font-size: 22px' >
          <img style="width:3.5cm; height: 3.5cm"  src="img/FriendsPlus/Page4/E1/3.jpg" />
          <div >
            His name’s#.<br>
            He’s Rosy’s brother.<br>
            He has#hair.
          </div>
        </div>
            
          `,
        answer: [
          "straight",
          "brown",
          "Tim",
          "short",
          "green",
          "Billy",
          "curly",
        ],
      },
    ],
  },
  // 2: {
  //   // Exercise num
  //   unit: "Stater",
  //   id: "4WB-S-P4-E1",
  //   audio: "",
  //   video: "",
  //   component: MatchDots,
  //   exerciseKey: "img/FriendsPlus/Page4/E2/Key/E2answerKey.png",
  //   titleQuestion: [
  //     {
  //       num: "2",
  //       title: "Find and circle the family words.",
  //       color: "#234090",
  //     },
  //   ],
  //   question: {
  //     DrawLines: {
  //       multipleLine: true,
  //       boxMatch: [
  //         //above
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "162px",
  //             left: "304px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //1
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "156px",
  //             left: "329px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //2
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "152px",
  //             left: "354px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //3
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "152px",
  //             left: "382px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //4
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "155px",
  //             left: "402px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //5
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "160px",
  //             left: "424px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //6
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "170px",
  //             left: "449px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //7
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "179px",
  //             left: "474px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //8
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "187px",
  //             left: "493px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //9
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "195px",
  //             left: "512px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //10
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "201px",
  //             left: "533px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //11
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "203px",
  //             left: "555px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //12
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "205px",
  //             left: "579px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //13
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "202px",
  //             left: "597px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //14
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "192px",
  //             left: "626px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //15
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "183px",
  //             left: "646px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //16
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "167px",
  //             left: "681px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //17
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "158px",
  //             left: "710px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //18
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "156px",
  //             left: "736px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //19
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "161px",
  //             left: "763px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //20
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "168px",
  //             left: "788px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //21
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "174px",
  //             left: "813px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //22
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "182px",
  //             left: "834px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //23
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "188px",
  //             left: "857px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //24
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "193px",
  //             left: "881px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //25
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "196px",
  //             left: "899px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //26
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "195px",
  //             left: "927px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //27

  //         //below
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "203px",
  //             left: "314px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //1
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "199px",
  //             left: "335px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //2
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "197px",
  //             left: "356px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //3
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "198px",
  //             left: "379px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //4
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "199px",
  //             left: "397px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //5
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "205px",
  //             left: "412px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //6
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "213px",
  //             left: "431px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //7

  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "222px",
  //             left: "452px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //8
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "228px",
  //             left: "469px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //9
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "238px",
  //             left: "493px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //10
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "246px",
  //             left: "520px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //11
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "251px",
  //             left: "547px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //12
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "248px",
  //             left: "586px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //13
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "241px",
  //             left: "610px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //14
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "226px",
  //             left: "643px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //15
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "216px",
  //             left: "663px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //16
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "204px",
  //             left: "693px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //17
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "199px",
  //             left: "717px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //18
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "235px",
  //             left: "932px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //19
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "196px",
  //             left: "733px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //20
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "199px",
  //             left: "752px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //21
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "206px",
  //             left: "775px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //22
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "213px",
  //             left: "798px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //23
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "221px",
  //             left: "823px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //24
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "228px",
  //             left: "847px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //25
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "234px",
  //             left: "870px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //26
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "238px",
  //             left: "898px",
  //             width: 10,
  //             height: 10,
  //             backgroundColor: "rgb(243,164,181)",
  //             borderRadius: "50%",
  //             // border: "1px solid",
  //           },
  //         }, //27
  //       ],
  //       answers: [
  //         "0-27",
  //         "33-6",
  //         "13-40",
  //         "16-43",
  //         "21-22",
  //         "23-51",
  //         "26-45",
  //         "0-1",
  //         "1-2",
  //         "2-3",
  //         "3-4",
  //         "4-5",
  //         "5-6",
  //         "27-28",
  //         "28-29",
  //         "29-30",
  //         "30-31",
  //         "31-32",
  //         "32-33",
  //         "6-7",
  //         "7-8",
  //         "8-9",
  //         "10-9",
  //         "10-11",
  //         "11-12",
  //         "12-13",
  //         "33-34",
  //         "34-35",
  //         "35-36",
  //         "36-37",
  //         "37-38",
  //         "38-39",
  //         "39-40",
  //         "13-14",
  //         "14-15",
  //         "15-16",
  //         "40-41",
  //         "41-42",
  //         "42-43",
  //         "16-17",
  //         "17-18",
  //         "18-19",
  //         "19-20",
  //         "20-21",
  //         "43-44",
  //         "44-46",
  //         "46-47",
  //         "47-48",
  //         "48-49",
  //         "49-50",
  //         "50-51",
  //         "22-23",
  //         "23-24",
  //         "24-25",
  //         "25-26",
  //         "51-52",
  //         "52-53",
  //         "45-53",
  //       ],
  //       initialValue: [],
  //     },
  //     Layout: `
  //     <div style='position: relative' >
  //     <img style="width: 29cm; height: 9cm; margin-bottom: 100px"   src="img/FriendsPlus/Page4/E2/1.jpg" />

  //     <input id='0' type= 'boxMatch' />
  //     <input id='1' type= 'boxMatch' />
  //     <input id='2' type= 'boxMatch' />
  //     <input id='3' type= 'boxMatch' />
  //     <input id='4' type= 'boxMatch' />
  //     <input id='5' type= 'boxMatch' />
  //     <input id='6' type= 'boxMatch' />
  //     <input id='7' type= 'boxMatch' />
  //     <input id='8' type= 'boxMatch' />
  //     <input id='9' type= 'boxMatch' />
  //     <input id='10' type= 'boxMatch' />
  //     <input id='11' type= 'boxMatch' />
  //     <input id='12' type= 'boxMatch' />
  //     <input id='13' type= 'boxMatch' />
  //     <input id='14' type= 'boxMatch' />
  //     <input id='15' type= 'boxMatch' />
  //     <input id='16' type= 'boxMatch' />
  //     <input id='17' type= 'boxMatch' />
  //     <input id='18' type= 'boxMatch' />
  //     <input id='19' type= 'boxMatch' />
  //     <input id='20' type= 'boxMatch' />
  //     <input id='21' type= 'boxMatch' />
  //     <input id='22' type= 'boxMatch' />
  //     <input id='23' type= 'boxMatch' />
  //     <input id='24' type= 'boxMatch' />
  //     <input id='25' type= 'boxMatch' />
  //     <input id='26' type= 'boxMatch' />

  //     <input id='27' type= 'boxMatch' />
  //     <input id='28' type= 'boxMatch' />
  //     <input id='29' type= 'boxMatch' />
  //     <input id='30' type= 'boxMatch' />
  //     <input id='31' type= 'boxMatch' />
  //     <input id='32' type= 'boxMatch' />
  //     <input id='33' type= 'boxMatch' />
  //     <input id='34' type= 'boxMatch' />
  //     <input id='35' type= 'boxMatch' />
  //     <input id='36' type= 'boxMatch' />
  //     <input id='37' type= 'boxMatch' />
  //     <input id='38' type= 'boxMatch' />
  //     <input id='39' type= 'boxMatch' />
  //     <input id='40' type= 'boxMatch' />
  //     <input id='41' type= 'boxMatch' />
  //     <input id='42' type= 'boxMatch' />
  //     <input id='43' type= 'boxMatch' />
  //     <input id='44' type= 'boxMatch' />
  //     <input id='45' type= 'boxMatch' />
  //     <input id='46' type= 'boxMatch' />
  //     <input id='47' type= 'boxMatch' />
  //     <input id='48' type= 'boxMatch' />
  //     <input id='49' type= 'boxMatch' />
  //     <input id='50' type= 'boxMatch' />
  //     <input id='51' type= 'boxMatch' />
  //     <input id='52' type= 'boxMatch' />
  //     <input id='53' type= 'boxMatch' />
  //     </div>

  //     `,
  //   },
  // },

  2: {
    unit: "Stater",
    id: "4WB-S-P4-E2",
    audio: "",
    video: "",
    component: T6,
    inputSize: 610,
    maxLength: 60,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 23,
      height: 29,
      // textTransform: "uppercase",
      borderStyle: "2px dotted",
      textAlign: "left",
    },
    exerciseKey: "img/FriendsPlus/Page4/E2/Key/E2answerKey.png",
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "2",
        title: "Find and write the family words.",
        color: "#234090",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='position: relative; display:flex;margin-top:20px; line-height:37px; font-size: 22px' >
          <img style="width: 22.5cm; height: 7cm" src="img/FriendsPlus/Page4/E2/1.jpg" /><br>

        </div>
        <div><i style='font-size:18px; color: rgb(245,54,92)'>*Write the selected words in the sorted order of the list above and between words seperated by commas (,).</i></div>
        <div style='display: flex; margin-top: 30px'>
        <b>The family words:&ensp;</b><i style='color: gray'>brother,#

        </div>
          `,
        answer: [
          "cousin, grandma, mom, grandpa, dad / cousin, mom, grandpa, grandma, dad / cousin, grandpa, grandma, mom, dad / cousin, mom, grandpa, grandma, dad / cousin, grandma, grandpa, mom, dad",
          "cousin, dad, grandma, mom, grandpa / cousin, dad, mom, grandpa, grandma / cousin, dad, grandpa, grandma, mom / cousin, dad, mom, grandpa, grandma / cousin, dad, grandma, grandpa, mom",
          "grandma, mom, grandpa, dad, cousin / grandma, mom, dad, grandpa, cousin / grandma, grandpa, dad, mom, cousin / grandma, grandpa, mom, dad, cousin / grandma, dad, mom, grandpa, cousin / grandma, dad, grandpa, mom, cousin",
          "grandpa, dad, cousin, grandma, mom / grandpa, dad, grandma, cousin, mom / grandpa, cousin, grandma, dad, mom / grandpa, cousin, dad, grandma, mom / grandpa, grandma, dad, cousin, mom / grandpa, grandma, cousin, dad, mom",
          "grandpa, mom, dad, cousin, grandma / grandpa, mom, dad, grandma, cousin / grandpa, mom, cousin, grandma, dad / grandpa, mom, cousin, dad, grandma / grandpa, mom, grandma, dad, cousin / grandpa, mom, grandma, cousin, dad",
          "dad, cousin, grandma, mom, grandpa / dad, grandpa, cousin, grandma, mom / dad, mom, grandpa, cousin, grandma / dad, grandma, mom, grandpa, cousin / dad, cousin, grandma, mom, grandpa",
        ],
      },
    ],
  },
};
export default json;
