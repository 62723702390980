import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  4: {
    // Exercise num
    unit: "Review 3",
    id: "4WB-R3-P69-E4",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page69/E4/Key/E4answerKey.png",
    inputSize: 250,
    textAlign: "center",
    maxLength: 30,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "4",
        title: '<div style="font-size:23px">Write.</div>',
        color: "rgb(238,61,60)",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 22,
      height: 30,
      width: 180,
    },
    hintBox: [
      {
        src: ["<s>gets up</s>", "has dinner", "goes to school"],
        width: 700,
        borderColor: "rgb(238,61,60)",
        bgColor: "none",
      },
      {
        src: ["nine o’clock", "five o’clock", "<s>seven o’clock</s>"],
        width: 700,
        borderColor: "rgb(238,61,60)",
        bgColor: "none",
      },
    ],
    questions: [
      {
        title: `
        <div style='margin-bottom:20px'><hintbox id='0'></hintbox></div>
        <div style='margin-bottom:20px'><hintbox id='1'></hintbox></div>
				<div style='max-width: 750px; line-height:50px'>
					<img style="width:23cm;" src='img/FriendsPlus/Page69/E4/1.jpg' />
					<div style='display:flex; width: max-content'>
            <div style='margin-right:100px; margin-left: 20px'>
              <div>He <u style='color:gray'>gets up</u> at</div>
              <div><u style='color:gray'>seven o’clock</u>.</div>
            </div>
            <div style='margin-right:53px'>
              <div>He # at</div>
              <div># .</div>
            </div>
            <div>
              <div>He # at</div>
              <div># .</div>
            </div>
          </div>	 
				</div>   
          `,
        answer: [
          `goes to school`,
          `nine o'clock`,
          `has dinner`,
          `five o'clock`,
        ],
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Review 3",
    id: "4WB-R3-P69-E5",
    // audio: 'Audios/Track 26.mp3',
    video: "",
    exerciseKey: "img/FriendsPlus/Page69/E5/Key/E5answerKey.png",

    component: DesignUnderLine,
    totalInput: 4,
    titleQuestion: [
      {
        num: "5",
        title: "Check (✓) the pictures with the same sound. ",
        color: "rgb(238,61,60)",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row 1
      [{ url: "img/FriendsPlus/Page69/E5/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page69/E5/2.jpg" },
        { url: "img/FriendsPlus/Page69/E5/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page69/E5/4.jpg" },
        { url: "img/FriendsPlus/Page69/E5/5.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page69/E5/6.jpg" },
        { url: "img/FriendsPlus/Page69/E5/7.jpg", input: 3 },
        { url: "img/FriendsPlus/Page69/E5/8.jpg" },
        { url: "img/FriendsPlus/Page69/E5/9.jpg", input: 4 },
        { url: "img/FriendsPlus/Page69/E5/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page69/E5/11.jpg" }],
      [
        { url: "img/FriendsPlus/Page69/E5/12.jpg" },
        { url: "img/FriendsPlus/Page69/E5/13.jpg", input: 5 },
        { url: "img/FriendsPlus/Page69/E5/14.jpg" },
        { url: "img/FriendsPlus/Page69/E5/15.jpg", input: 6, isCorrect: true },
        { url: "img/FriendsPlus/Page69/E5/16.jpg" },
        { url: "img/FriendsPlus/Page69/E5/17.jpg", input: 7 },
        { url: "img/FriendsPlus/Page69/E5/18.jpg" },
        { url: "img/FriendsPlus/Page69/E5/19.jpg", input: 8, isCorrect: true },
        { url: "img/FriendsPlus/Page69/E5/20.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page69/E5/21.jpg" }],
    ],
  },
  6: {
    // Exercise num
    unit: "Review 3",
    id: "4WB-R3-P69-E6",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    inputSize: 80,
    hideBtnFooter: true,
    // textAlign: "center",
    // titleImage: "img/FriendsPlus/Page6/E4/title.jpg",

    stylesTextInput: {
      borderBottom: "dash",
    },
    checkUppercase: true,
    questionImage: [],
    mywork: [
      {
        text: "My favorite story in Units 7-9 is",
      },
      { text: "My favorite song in Units 7-9 is" },
      { text: "My favorite unit in Units 7-9 is" },
      { text: "I need to practice" },
      0,
    ],
    myImage: [{ url: "img/FriendsPlus/Page69/E6/1.jpg" }],
    colorBG: "#f25822",
    wordColor: "#fef5d6",
    boxColor: "red",
    questions: [
      {
        title: `
      <div style=' position: relative; '>
				<div><mywork></mywork></div>
          <div style=' position: absolute; top: 45px; left:350px; '><input id='0' width='520px'></input></div>
          <div style=' position: absolute; top: 83px; left:350px; '><input id='0' width='520px'></input></div>
          <div style=' position: absolute; top: 121px; left:340px; '><input id='0' width='530px'></input></div>
          <div style=' position: absolute; top: 159px; left:210px; '><input id='0' width='660px'></input></div>
                
      </div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
