// import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
// import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDotsWrite from "../../components/ExcerciseTypes/LineTo/MatchDotsWrite";

const json = {
  1: {
    // Exercise num
    unit: "Stater",
    id: "4WB-S-P6-E1",
    audio: "Audios/Track 01.mp3",
    exerciseKey: "img/FriendsPlus/Page6/E1/Key/E1answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    component: T6,
    inputSize: 108,
    textAlign: "center",
    maxLength: 2,
    // isHiddenCheck: true,
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 20,
      height: 22,
      width: 35,
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Order the days. Listen and check. <headphone name='01' src='Audios/Track 01.mp3'></headphone>",
        color: "#234090",
      },
    ],

    questions: [
      {
        title: `
        <div style=" position: relative">
						<div> <img style='width:25cm; margin-top: 20px' src='img/FriendsPlus/Page6/E1/1.jpg' /> </div>
            
            <div style=" position: absolute; top:177px; left: 370px; "><input id='0' font-size='23px'  border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 184px; left: 636px; "><input id='1' font-size='23px'  border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 202px; left: 893px; "><input id='2' font-size='23px'  border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 286px; left: 240px; "><input id='3' font-size='23px'  border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 277px; left: 500px; "><input id='4' font-size='23px'  border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 282px; left: 768px; "><input id='4' font-size='23px'  border-bottom='0px'></input></div>
				</div>
          `,
        answer: ["5", "6", "4", "3", "2", "7"],
      },
    ],
  },
  // 2: {
  //   // Exercise num
  //   unit: "Stater",
  //   id: "4WB-S-P6-E2",
  //   audio: "",
  //   // hideBtnFooter: true,
  //   exerciseKey: "img/FriendsPlus/Page6/E2/Key/E2answerKey.jpg",
  //   // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
  //   component: T6,
  //   inputSize: 400,
  //   textAlign: "center",
  //   maxLength: 20,
  //   isHiddenCheck: true,
  //   titleQuestion: [
  //     {
  //       num: "2",
  //       title: "Find and circle the days of the week. Write.",
  //       color: "#234090",
  //     },
  //   ],
  //   stylesTextInput: {
  //     background: "none",
  //     // borderBottom: "none",
  //     fontSize: 23,
  //     height: 27,
  //     // textTransform: 'uppercase',
  //     // borderStyle: "dotted",
  //     textAlign: "left",
  //   },
  //   hintBox: [
  //     {
  //       src: [
  //         "Sunday",
  //         "Monday",
  //         "<s style='color:gray'>Tuesday</s>",
  //         "Wednesday",
  //         "Thursday",
  //         "Friday",
  //         "Saturday",
  //       ],
  //       width: 1100,
  //       borderColor: "rgb(133,208,151)",
  //     },
  //   ],
  //   questions: [
  //     {
  //       title: `
  //       <hintbox id='0' style='border-radius:1px'></hintbox>
  //       <div style="align-items: flex-end; position: relative; padding-top:20px; display:flex; width: max-content">
  //           <div> <img style='width:15cm; margin-right:20px' src='img/FriendsPlus/Page6/E2/1.jpg' /> </div>
  //           <div>Today is &ensp;#&ensp;.</div>
  // 			</div>
  //         `,
  //       answer: [""],
  //     },
  //   ],
  // },
  2: {
    // Exercise num
    unit: "Stater",
    id: "4WB-S-P6-E2",
    // audio: 'Audios/Track 08.mp3',
    video: "",
    component: MatchDotsWrite,
    exerciseKey: "img/FriendsPlus/Page6/E2/Key/E2answerKey(1).jpg",
    titleQuestion: [
      {
        num: "2",
        title: "Find and circle the days of the week. Write.",
        color: "#234090",
      },
    ],
    // inputSize: 50,
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 300,
          height: 30,
          borderBottom: "1px solid black",
          textAlign: "left",
          fontSize: 24,
          // color: "black",
          marginTop: "3px",
          maxLength: 100,
          // backgroundColor: "blue",
        },
        isHiddenCheck: true,

        answers: [
          "sunday / monday / tuesday / wednesday / thursday / friday / saturday",
          // "Sunday | Monday | Tuesday | Wednesday | Thursday | Friday | Saturday",
        ],
        initialValue: [],
      },
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "179px",
              left: "46px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //T
          {
            boxMatchStyle: {
              position: "absolute",
              top: "179px",
              left: "103px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //S
          {
            boxMatchStyle: {
              position: "absolute",
              top: "179px",
              left: "160px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //a

          {
            boxMatchStyle: {
              position: "absolute",
              top: "179px",
              left: "217px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //t
          {
            boxMatchStyle: {
              position: "absolute",
              top: "179px",
              left: "274px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //u
          {
            boxMatchStyle: {
              position: "absolute",
              top: "179px",
              left: "331px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //r
          {
            boxMatchStyle: {
              position: "absolute",
              top: "179px",
              left: "388px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //d
          {
            boxMatchStyle: {
              position: "absolute",
              top: "179px",
              left: "445px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //a
          {
            boxMatchStyle: {
              position: "absolute",
              top: "179px",
              left: "502px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //y
          {
            boxMatchStyle: {
              position: "absolute",
              top: "179px",
              left: "560px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //S

          {
            boxMatchStyle: {
              position: "absolute",
              top: "233px",
              left: "46px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //h
          {
            boxMatchStyle: {
              position: "absolute",
              top: "288px",
              left: "46px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //u
          {
            boxMatchStyle: {
              position: "absolute",
              top: "343px",
              left: "46px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //r
          {
            boxMatchStyle: {
              position: "absolute",
              top: "397px",
              left: "46px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //s
          {
            boxMatchStyle: {
              position: "absolute",
              top: "452px",
              left: "46px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //d
          {
            boxMatchStyle: {
              position: "absolute",
              top: "507px",
              left: "46px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //a
          {
            boxMatchStyle: {
              position: "absolute",
              top: "561px",
              left: "46px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //y

          {
            boxMatchStyle: {
              position: "absolute",
              top: "343px",
              left: "160px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //F
          {
            boxMatchStyle: {
              position: "absolute",
              top: "343px",
              left: "217px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //r
          {
            boxMatchStyle: {
              position: "absolute",
              top: "343px",
              left: "274px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //i
          {
            boxMatchStyle: {
              position: "absolute",
              top: "343px",
              left: "331px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //d
          {
            boxMatchStyle: {
              position: "absolute",
              top: "343px",
              left: "388px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //a
          {
            boxMatchStyle: {
              position: "absolute",
              top: "343px",
              left: "445px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //y

          {
            boxMatchStyle: {
              position: "absolute",
              top: "233px",
              left: "560px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //u
          {
            boxMatchStyle: {
              position: "absolute",
              top: "288px",
              left: "560px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //n
          {
            boxMatchStyle: {
              position: "absolute",
              top: "399px",
              left: "560px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //d
          {
            boxMatchStyle: {
              position: "absolute",
              top: "397px",
              left: "560px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //a
          {
            boxMatchStyle: {
              position: "absolute",
              top: "452px",
              left: "560px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //y

          {
            boxMatchStyle: {
              position: "absolute",
              top: "452px",
              left: "103px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //W
          {
            boxMatchStyle: {
              position: "absolute",
              top: "452px",
              left: "160px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //e
          {
            boxMatchStyle: {
              position: "absolute",
              top: "452px",
              left: "217px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //d
          {
            boxMatchStyle: {
              position: "absolute",
              top: "452px",
              left: "274px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //n
          {
            boxMatchStyle: {
              position: "absolute",
              top: "452px",
              left: "331px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //e
          {
            boxMatchStyle: {
              position: "absolute",
              top: "452px",
              left: "388px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //s
          {
            boxMatchStyle: {
              position: "absolute",
              top: "452px",
              left: "445px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //d
          {
            boxMatchStyle: {
              position: "absolute",
              top: "452px",
              left: "502px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //a

          {
            boxMatchStyle: {
              position: "absolute",
              top: "561px",
              left: "274px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //M
          {
            boxMatchStyle: {
              position: "absolute",
              top: "561px",
              left: "331px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //o
          {
            boxMatchStyle: {
              position: "absolute",
              top: "561px",
              left: "388px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //n
          {
            boxMatchStyle: {
              position: "absolute",
              top: "561px",
              left: "445px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //d
          {
            boxMatchStyle: {
              position: "absolute",
              top: "561px",
              left: "503px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //a
          {
            boxMatchStyle: {
              position: "absolute",
              top: "561px",
              left: "560px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //y
        ],
        answers: ["0-16", "1-8", "27-9", "27-28", "36-41", "17-22"],
        initialValue: [],
      },
      Layout: `
      <img style='width:25cm; height: 3cm' src="img/FriendsPlus/Page6/E2/2.jpg" />
      <div><i style='font-size:21px; color: rgb(245,54,92)'>*Choose the first and last character of the word.</i></div>
			<div style='position: relative; text-align:left; margin-bottom: 100px; display: flex' >
       
				<img style='width:16cm; height: 12cm;' src="img/FriendsPlus/Page6/E2/1.jpg" />    
				
				<div style=" display: flex; align-items: flex-end; font-size: 23px">
				<span>Today is</span><input id='0'/>
        </div>
				
			</div>  
			  <input id='0' type= 'boxMatch' />
			  <input id='1' type= 'boxMatch' />
			  <input id='2' type= 'boxMatch' />
			  <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />
			  <input id='5' type= 'boxMatch' />
        <input id='6' type= 'boxMatch' />
			  <input id='7' type= 'boxMatch' />
        <input id='8' type= 'boxMatch' />
        
			  <input id='9' type= 'boxMatch' />
        <input id='10' type= 'boxMatch' />
        <input id='11' type= 'boxMatch' />
			  <input id='12' type= 'boxMatch' />
        <input id='13' type= 'boxMatch' />
			  <input id='14' type= 'boxMatch' />
        <input id='15' type= 'boxMatch' />
        <input id='16' type= 'boxMatch' />

        <input id='17' type= 'boxMatch' />
			  <input id='18' type= 'boxMatch' />
        <input id='19' type= 'boxMatch' />
			  <input id='20' type= 'boxMatch' />
        <input id='21' type= 'boxMatch' />
        <input id='22' type= 'boxMatch' />

        <input id='23' type= 'boxMatch' />
			  <input id='24' type= 'boxMatch' />
        <input id='25' type= 'boxMatch' />
			  <input id='26' type= 'boxMatch' />
        <input id='27' type= 'boxMatch' />

        <input id='28' type= 'boxMatch' />
			  <input id='29' type= 'boxMatch' />
        <input id='30' type= 'boxMatch' />
			  <input id='31' type= 'boxMatch' />
        <input id='32' type= 'boxMatch' />
        <input id='33' type= 'boxMatch' />
			  <input id='34' type= 'boxMatch' />
        <input id='35' type= 'boxMatch' />

        <input id='36' type= 'boxMatch' />
			  <input id='37' type= 'boxMatch' />
        <input id='38' type= 'boxMatch' />
        <input id='39' type= 'boxMatch' />
			  <input id='40' type= 'boxMatch' />
        <input id='41' type= 'boxMatch' />
		  `,
    },
  },
  3: {
    // Exercise num
    unit: "Stater",
    id: "4WB-S-P6-E3",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page6/E3/Key/E3answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    component: T6,
    inputSize: 45,
    textAlign: "center",
    textTransform: "uppercase",
    bgColor: "none",
    maxLength: 8,
    // isHiddenCheck: true,
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 23,
      height: 22,
      width: 35,
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "3",
        title: "Write.",
        color: "#234090",
      },
    ],
    questions: [
      {
        title: `
        <div style=" position: relative; padding-top:100px">
	          <div> <img style='width:25cm'src='img/FriendsPlus/Page6/E3/1.jpg' /> </div>
            <div style=" position: absolute; top: 346px; left: 265px; "><input id='0' font-size='22px' border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 346px; left: 326px; "><input id='1' font-size='22px' border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 346px; left: 674px; "><input id='2' font-size='22px' border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 346px; left: 732px; "><input id='3' font-size='22px' border-bottom='0px'></input></div>
            <div style=" position: absolute; top: 346px; left: 772px; "><input id='4' font-size='22px' border-bottom='0px'></input></div>
				</div>

          `,
        answer: ["N", "S", "A", "U", "R"],
      },
    ],
  },
};

export default json;
