import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 7",
    id: "4WB-U7-P50-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page50/E1/Key/E1answerKey.png",
    component: T6,
    inputSize: 167,
    textAlign: "center",
    maxLength: 15,
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 23,
      height: 30,
      width: 200,
    },
    titleQuestion: [
      {
        num: "1",
        title: "Write.",
        color: "#234090",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style=" position: relative; ">
						<div> <img style='width:23cm' src='img/FriendsPlus/Page50/E1/1.jpg' /> </div>
            <div style="width:180px; position: absolute; top:171px; left: 316px; "><input id='0' ></input></div>
            <div style="width:180px; position: absolute; top:171px; left: 622px; "><input id='1' ></input></div>
            <div style="width:180px; position: absolute; top:413px; left: 18px; "><input id='2' ></input></div>
            <div style="width:180px; position: absolute; top:413px; left: 316px; "><input id='3' ></input></div>
            <div style="width:180px; position: absolute; top:413px; left: 622px; "><input id='4' ></input></div>
					</div>
          `,
        answer: ["sunny", "rainy", "snowy", "hot", "cold"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 7",
    id: "4WB-U7-P50-E2",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page50/E2/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "Match the pictures with the sentences. There are two extra sentences.",
        color: "#2E479B",
      },
    ],
    question: {
      DrawLines: {
        // multipleLine: true,
        boxMatch: [
          //left
          {
            boxMatchStyle: {
              position: "absolute",
              top: "233px",
              left: "132px",
              width: 230,
              height: 143,
              //backgroundColor: 'rgb(67,79,161)',
              //border: '2px solid',
            },
          }, //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "385px",
              left: "132px",
              width: 230,
              height: 143,
              //backgroundColor: 'rgb(67,79,161)',
              //border: '2px solid',
            },
          }, //1
          //right
          {
            boxMatchStyle: {
              position: "absolute",
              top: "80px",
              left: "628px",
              width: 230,
              height: 143,
              //backgroundColor: 'rgb(67,79,161)',
              //border: '2px solid',
            },
          }, //2

          {
            boxMatchStyle: {
              position: "absolute",
              top: "233px",
              left: "628px",
              width: 230,
              height: 143,
              //backgroundColor: 'pink',
              //border: '2px solid',
            },
          }, //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "385px",
              left: "628px",
              width: 230,
              height: 143,
              //backgroundColor: 'black',
              //border: '2px solid',
            },
          }, //4
          //mid
          {
            boxMatchStyle: {
              position: "absolute",
              top: "124px",
              left: "375px",
              width: 131,
              height: 39,
              //backgroundColor: 'yellow',
              //border: '2px solid',
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "170px",
              left: "375px",
              width: 131,
              height: 39,
              //backgroundColor: 'green',
              //border: '2px solid',
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "216px",
              left: "364px",
              width: 110,
              height: 39,
              //backgroundColor: 'rgb(67,79,161)',
              //border: '2px solid',
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "262px",
              left: "375px",
              width: 131,
              height: 39,
              //backgroundColor: 'rgb(67,79,161)',
              //border: '2px solid',
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "308px",
              left: "373px",
              width: 120,
              height: 39,
              //backgroundColor: 'rgb(67,79,161)',
              //border: '2px solid',
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "354px",
              left: "368px",
              width: 112,
              height: 39,
              //backgroundColor: 'rgb(67,79,161)',
              //border: '2px solid',
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "402px",
              left: "378px",
              width: 138,
              height: 39,
              //backgroundColor: 'rgb(67,79,161)',
              //border: '2px solid',
            },
          }, //11
        ],
        answers: ["0-5", "1-11", "2-8", "3-7", "10-4"],
        initialValue: [],
      },
      Layout: `
      <img style='width: 20cm; margin-bottom:50px' src="img/FriendsPlus/Page50/E2/1.jpg" />
          <input id='0' type='boxMatch' />
          <input id='1' type='boxMatch' />

          <input id='2' type='boxMatch' />
          <input id='3' type='boxMatch' />
          <input id='4' type='boxMatch' />

          <input id='5' type='boxMatch' />
          <input id='6' type='boxMatch' />
          <input id='7' type='boxMatch' />
          <input id='8' type='boxMatch' />
          <input id='9' type='boxMatch' />
          <input id='10' type='boxMatch' />
          <input id='11' type='boxMatch' />
          
      `,
    },
  },
};

export default json;
