import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'
const json = {
	1: {
		// Exercise num
		unit: 'Unit 5',
		id: '4WB-U5-P39-E1',
		audio: 'Audios/Track 21.mp3',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page39/E1/Key/E1answerKey.png',
		inputSize: 1,
		textAlign: 'center',
		maxLength: 1,
		stylesTextInput: {
			background: 'none',
			borderBottom: 'none',
			fontSize: 24,
			height: 40,
			width: 40,
		},
		titleQuestion: [
			{
				num: '1',
				title:
					"Write. Listen and check. <headphone name='21' src='Audios/Track 21.mp3'></headphone>",
				color: '#234090',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `

        <div style='position: relative; width:100%; text-align:right' >
            <img style='width:18cm' src="img/FriendsPlus/Page39/E1/1.jpg" />    
            
            <div style=" position: absolute; top: 189px; left: 285px;">#</div>
            <div style=" position: absolute; top: 189px; left: 579px;">#</div>

            <div style=" position: absolute; top: 392px; left: 45px;">#</div>
            <div style=" position: absolute; top: 392px; left: 286px;">#</div>
            <div style=" position: absolute; top: 392px; left: 595px;">#</div>
            </div>
            
          `,
				answer: ['w', 'w', 'x', 'w', 'x'],
			},
		],
	},

	2: {
		// Exercise num
		unit: 'Unit 5',
		id: '4WB-U5-P39-E2',
		audio: 'Audios/Track 22.mp3',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page39/E2/Key/E2answerKey.png',
		// exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
		inputSize: 10,
		textAlign: 'center',
		maxLength: 10,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '2',
				title:
					"Write. Listen and chant. <headphone name='22' src='Audios/Track 22.mp3'></headphone>",
				color: '#234090',
			},
		],
		stylesTextInput: {
			background: 'none',
			borderBottom: 'none',
			fontSize: 19,
			height: 30,
			width: 120,
		},
		questions: [
			{
				title: `
       <div style='position:relative'>
       <img style='width:13cm; height: 13cm' src='img/FriendsPlus/Page39/E2/1.jpg' />

        <div style=" position: absolute; top: 96px; left: 134px; ">#</div>
        <div style=" position: absolute; top: 133px; left: 113px; ">#</div>
        <div style=" position: absolute; top: 133px; left: 296px; ">#</div>
        <div style=" position: absolute; top: 173px; left: 113px; ">#</div>
        <div style=" position: absolute; top: 173px; left: 296px; ">#</div>
     
      </div>
            
          `,
				answer: ['box', 'web', 'window', 'fox', 'box'],
			},
		],
	},
}

export default json
