import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 8",
    id: "4WB-U8-P61-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page61/E1/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
				Write the long and short forms. 
			  `,
        color: "#23408f",
      },
    ],
    titleImage: "",
    questionImage: [],
    inputSize: 50,
    maxLength: 35,
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 24,
      height: 30,
      // textTransform: 'uppercase',
      width: 440,
    },
    textAlign: "left",
    isHiddenCheck: true,
    checkUppercase: true,
    questions: [
      {
        title: `
					<div style=" position: relative ">
            <img style='width:25cm' src='img/FriendsPlus/Page61/E1/1.jpg'>
            <div style=" position: absolute; top: 120px; left: 40px; "><input id='0'></input></div>
            <div style=" position: absolute; top: 175px; left: 510px; "><input id='1'></input></div>
            <div style=" position: absolute; top: 228px; left: 40px; "><input id='2'></input></div>
            <div style=" position: absolute; top: 283px; left: 510px; "><input id='3'></input></div>
			<div style="position: absolute; top: 330px; left: 35px; "><input id='4'></input></div>
					</div>
			  `,
        answer: [
          `I don't like candy.`,
          "They do not like balloons.",
          "We don't like eggs.",
          "I do not like tigers.",
          "My friend doesn't like cake.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "4WB-U8-P61-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page43/key/E2answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "2",
        title: `
				Circle the things you like.
					`,
        color: "#23408f",
      },
    ],
    hideBtnFooter: true,
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "10px",
          color: "",
          borderRadius: "50%",
          fontWeight: "700",
        },
        selectWordStyle: { border: "2px solid rgb(255,127,80)" },
        limitSelect: 5,
        listWords: [
          `the_cake the_candy the_balloons the_music the_presents 
          `, // 0
        ],
        answers: [``],
        initialValue: {},
      },
      Layout: `
	  <div style="border: 2px solid rgb(255,127,80); border-radius: 10px">
		<div
			style="
			background-color: rgb(28, 190, 215);
			border-radius: 40px;
			margin: 10px 10px;
			"
		>
		</div> 
	  	<div style='width: 800px;margin: 10px 10px;'>
			 <input id='0' type='Circle' style="letter-spacing: 10px;"/> 
		  </div>  
    </div>
			
				`,
    },
  },
  3: {
    unit: "Unit 8",
    id: "4WB-U8-P61-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page61/E3/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `
				Draw and write a thank-you card for a family member.
			  `,
        color: "#23408f",
      },
    ],
    titleImage: "",
    questionImage: [],
    inputSize: 50,
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 23,
      height: 29,
      // textTransform: 'uppercase',
      width: 420,
    },
    maxLength: 140,
    textareaStyle: { width: 400 },
    textAlign: "left",
    hideBtnFooter: true,
    isHiddenCheck: true,
    selectStyle: {
      color: "#00ADFE",
      width: 60,
      textAlign: "center",
    },
    questions: [
      {
        title: `
					<div style=" position: relative; width:700px ">
            <img src='img/FriendsPlus/Page61/E3/1.jpg' style='width:25cm'>
             <div style=" position: absolute; margin-top: -399px; margin-left: 538px "><input id='0' width='182px'></input></div>
             <div style=" position: absolute; margin-top: -352px; margin-left: 638px "><input id='1' width='182px'></input></div>
             <div style=" position: absolute; margin-top:-299px; margin-left: 471px" ><input id='2 'width='458px'></input></div>
             <div style=" position: absolute; margin-top:-250px; margin-left: 538px" ><input id='3 'width='385px'></input></div>
             <div style=" position: absolute; margin-top: -201px; margin-left: 578px "><input id='4' width='350px'></input></div>
             <div style=" position: absolute; margin-top: -151px; margin-left: 694px "><input id='5' width='235px'></input></div>
             <div style=" position: absolute; margin-top: -51px; margin-left: 475px "><input id='6' width='182px'></input></div>
					</div>
			  `,
        answer: ["", "", "", "", "", ""],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "4WB-U8-P61-E4",
    audio: "",
    video: "",
    hideBtnFooter: true,
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: "Show your thank-you card and talk about it to a friend.",
        color: "#234090",
        // prefix: { icons: ["far fa-comment"] },
      },
    ],
    questionImage: [],
  },
};

export default json;
