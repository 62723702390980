// import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import Circle_Write2 from "../../components/ExcerciseTypes/Circle_Write2";

// import TB2 from '../../components/ExcerciseTypes/Table/TB2'
// import UI from '../../components/ExcerciseTypes/Design/UserInterface'
// import PaintColorType from '../../components/ExcerciseTypes/Design/PaintColorType'
const json = {
  1: {
    // Exercise num
    unit: "Review 1",
    id: "4WB-R1-P28-E1",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page28/E1/Key/E1answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 270,
    textAlign: "center",
    maxLength: 20,

    titleQuestion: [
      {
        num: "1",
        title: "Write.",
        color: "#234090",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 29,
      height: 50,
    },
    questions: [
      {
        title: `
       <div style='position:relative'>
       <img src='img/FriendsPlus/Page28/E1/1.jpg' />

        <div style=" position: absolute; top: 263px; left: 225px; ">#</div>
        <div style=" position: absolute; top: 63px; left: 763px; ">#</div>
        <div style=" position: absolute; top: 312px; left: 759px; ">#</div>
        
     
      </div>
            
          `,
        answer: ["a fire station", "a farm", "They're pilots"],
      },
    ],
  },
  2: {
    unit: "Review 1",
    id: "4WB-R1-P28-E2",
    exerciseKey: "img/FriendsPlus/Page28/E2/Key/E2answerKey.png",
    video: "",
    // audio: 'Audios/2.12.mp3',
    titleQuestion: [
      {
        num: "2",
        title: `Draw a circle on the stress. Draw a line under the schwa sound.`,
        color: "#234090",
      },
    ],
    component: Circle_Write2,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: {
          width: 149,
          fontSize: 23,
          borderBottom: "none",
          textAlign: "center",
        },
        answers: [""],
        initialValue: [],
      },

      Circle: {
        initialWordStyle: {
          padding: 0,
          // borderRadius: "50%",

          borderColor: "transparent",
        },
        selectWordStyle: {
          color: "black",

          //  borderTop: "4px solid gray",
          borderRadius: "50%",
        },
        limitSelect: 1,
        listWords: [
          "p|a|r|r|o|t", //0
          "p|i|l|o|t", //1
          "s|o|f|a", //2

          "o o|o|o |o|o", //5
          "o|o|o|o|o", //5
          "o |o |o|o", //5
        ],
        answers: ["3-2", "0-8", "4-2", "1-6", "5-4", "2-6"],
        initialValue: [],
      },
      Layout: `
        <div style='position: relative;background:none'>
          <img style='width: 24cm' src='img/FriendsPlus/Page28/E2/1.jpg' /><br>
          <div style='  display: flex;margin-left:200px; margin-top: -46px;font-size:28px; justify-content: space-around'>
            <div style='margin-right: 20px'><input id='0'  type='Underline' /></div>
            <div style='margin-right: 20px'><input id='1'  type='Underline' /></div>
            <div><input id='2'  type='Underline' /></div>
          </div>
          <div style='position: absolute; top:223px; left:286px;font-size:18px;color:white'>
            <input id='3' type='Circle'>
          </div>
          <div style='position: absolute; top:223px; left:540px;font-size:18px;color:white'>
            <input id='4' type='Circle'>
          </div>
          <div style='position: absolute; top:223px; left:773px;font-size:18px;color:white'>
            <input id='5' type='Circle'>
          </div>
          
         
        </div> 
      `,
    },
  },
  3: {
    // Exercise num
    unit: "Review 1",
    id: "4WB-R1-P28-E3",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page28/E3/Key/E3answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 200,
    textAlign: "center",
    maxLength: 17,
    isHiddenCheck: true,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 23,
      height: 27,
      // textTransform: 'uppercase',
    },

    titleQuestion: [
      {
        num: "3",
        title: " Write the words in the correct boxes.",
        color: "#234090",
      },
    ],
    hintBox: [
      {
        src: [
          "<s style='color:gray'>airport</s>",

          "bank",
          "chicken",
          "doctor",
          "farmer",
          "firefighter",
          "fries",
          "hospital",
          "noodles",
          "office",
          "office worker",
          "rice",
        ],
        width: 750,
        borderColor: "rgb(133,208,151)",
      },
    ],
    questions: [
      {
        title: `
        <div style='position:relative'></div>
        <hintbox id='0' ></hintbox>
        <div style='position: absolute; display:flex; top: 185px'>
            <div style='padding:6px; position:relative; border-radius:10px; text-align:center ;background-color:rgb(218,235,194); border:none; color:rgb(32,174,92); margin:35px 37px 0px 89px'>jobs</div>
            <div style='padding:6px; position:relative; width:fit-content; border-radius:10px;text-align:center ;background-color:rgb(218,235,194); border:none; color:rgb(32,174,92); margin:37px 54px 0px 108px'>places of work</div>
            <div style='padding:6px; position:relative; border-radius:10px; text-align:center ;background-color:rgb(218,235,194); border:none; color:rgb(32,174,92); margin:35px 37px 0px 90px '>food</div>
        </div>
            
         

        <div style='display:flex;margin-top:40px; line-height:50px; text-align:center' >
        <div style='width:30%; border: 2px solid rgb(202, 202, 202); border-radius:30px; padding-top:40px; margin:20px; padding-bottom:20px'>
              <div> # </div>
              <div> # </div>
              <div> # </div>
              <div> # </div>
          </div>
          <div style='line-height:2.3;width:30%; border: 2px solid rgb(202, 202, 202); border-radius:30px; padding-top:40px; margin:20px; padding-bottom:20px'>
              <div  style='margin-top:-10px; font-size: 23px;border-bottom:2px solid gray;width:196px;margin-left:5px' >&ensp;airport&ensp;</div>
              <div> # </div>
              <div> # </div>
              <div> # </div>
          </div>
          
          <div style='width:30%; border: 2px solid rgb(202, 202, 202); border-radius:30px; padding-top:40px; margin:20px; padding-bottom:20px''>
              <div> # </div>
              <div> # </div>
              <div> # </div>
              <div> # </div>
          </div>
          
        </div>
            
          `,
        answer: [
          "doctor/farmer/firefighter/office worker",
          "doctor/farmer/firefighter/office worker",
          "doctor/farmer/firefighter/office worker",
          "doctor/farmer/firefighter/office worker",
          "bank/hospital/office",
          "bank/hospital/office",
          "bank/hospital/office",
          "chicken/fries/noodles/rice",
          "chicken/fries/noodles/rice",
          "chicken/fries/noodles/rice",
          "chicken/fries/noodles/rice",
        ],
      },
    ],
  },
};

export default json;
