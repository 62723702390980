import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	4: {
		// Exercise num
		unit: 'Review 1',
		id: '4WB-R1-P29-E4',
		audio: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page29/E4/Key/E4answerKey.png',
		// exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
		inputSize: 150,
		textAlign: 'center',
		maxLength: 20,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '4',
				title: 'Write.',
				color: '#234090',
			},
		],
		stylesTextInput: {
			background: 'none',
			borderBottom: 'none',
			fontSize: 17,
			height: 50,
			width: 200,
		},
		questions: [
			{
				title: `
       <div style='position:relative'>
       <img style='width:16cm' src='img/FriendsPlus/Page29/E4/1.jpg' />

        <div style=" position: absolute; top: 399px; left: -41px; ">#</div>
        <div style=" position: absolute; top: 40px; left: 343px; ">#</div>
        <div style=" position: absolute; top: 256px; left: 387px; ">#</div>
     
      </div>
            
          `,
				answer: ['Do you', 'I would like', 'They would like'],
			},
		],
	},

	5: {
		// Exercise num
		unit: 'Review 1',
		id: '4WB-R1-P29-E5',
		audio: '',
		component: T6,
		// exerciseKey: "img/FriendsPlus/Page29/E4/Key/E4answerKey.png",
		// exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
		inputSize: 350,
		textAlign: 'center',
		maxLength: 40,
		isHiddenCheck: true,
		hideBtnFooter: true,
		stylesTextInput: {
			background: 'none',
			borderBottom: 'none',
			fontSize: 24,
			height: 50,
			width: 540,
		},
		questions: [
			{
				title: `
       <div style='position:relative'>
       <img src='img/FriendsPlus/Page29/E5/1.jpg' />

        <div style=" position: absolute; top: 67px; left: 370px; ">#</div>
        <div style=" position: absolute; top: 109px; left: 365px; ">#</div>
        <div style=" position: absolute; top: 151px; left: 367px; ">#</div>
        <div style=" position: absolute; top: 196px; left: 295px; ">#</div>
     
      </div>
            
          `,
				answer: ['', '', '', ''],
			},
		],
	},
}

export default json
