import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Review 2",
    id: "4WB-R2-P48-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page48/E1/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
				Look and write.
			  `,
        color: "rgb(240,93,36)",
      },
    ],
    titleImage: "",
    questionImage: [],
    inputSize: 120,
    maxLength: 15,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 24,
      height: 29,
      // textTransform: 'uppercase',
    },
    textAlign: "center",
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style=" position: relative; margin-bottom: 300px; width: max-content ">
            <img src='img/FriendsPlus/Page48/E1/1.jpg'>
            <div style=" position: absolute; top: 250px; left: 7px; "><span>I'm </span><u style="color:gray">&ensp;snorkeling&ensp;</u><span>.</span></div>
            <div style=" position: absolute; top: 250px; left: 369px; width:300px "><span>He's</span><input id='0'></input><span>in the sea.</span></div>
            <div style=" position: absolute; top: 250px; left: 750px; width:300px  "><span>We're</span><input id='1'></input><span>on a boat.</span></div>
			  </div>
			  `,
        answer: ["surfing", "going"],
      },
    ],
  },
  2: {
    unit: "Review 2",
    id: "4WB-R2-P48-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page48/E2/Key/E2answerKey.png",
    component: Circle_Write,
    checkUppercase: true,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "2",
        title: "Circle the correct word. Then write the answer.",
        color: "rgb(240,93,36)",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 20,
        inputStyle: {
          width: 250,
          fontSize: 23,
          textAlign: "center",
          height: 27,
        },
        answers: [`they are`, `He's watching`, `collecting shells`],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: "50%",
          border: "2px solid white",

          borderColor: "transparent",
          fontWeight: "bold",
          color: "black",
        },
        selectWordStyle: {
          border: "2px solid white",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `I / they`, //0
          `are / is`, //1
          `she / they`, //2
        ],
        answers: ["0-4", "1-4", "2-4"],
        initialValue: [],
      },
      Layout: `
      <div style=' margin-top: 20px; line-height: 50px'>
        <div><b>1. </b>Is <b>you / <span style='border: 1px solid gray; border-radius: 50%; padding: 5px'>he</span> reading a book? No, <u style='color: gray'>&ensp;&ensp;he isn’t&ensp;&ensp;</u></b>.</div>
        <div><b>2. </b>Are <input id='0' type='Circle'/> swimming? Yes, <input id='0' ></input> .</div>
        <div><b>3. </b>What <input id='1' type='Circle'/> he watching? <input id='1' ></input> the penguins.</div>
        <div><b>4. </b>What are <input id='2' type='Circle'/> collecting? They’re <input id='2' ></input> .</div>

        
	    </div>
      `,
    },
  },
  3: {
    // Exercise num
    unit: "Review 2",
    id: "4WB-R2-P48-E3",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page48/E3/Key/E3answerKey.png",
    inputSize: 200,
    textAlign: "center",
    maxLength: 50,
    checkUppercase: true,
    isHiddenCheck: true,
    stylesTextInput: {
      // background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 27,
      // textTransform: 'uppercase',
    },

    titleQuestion: [
      {
        num: "3",
        title: "Write the words in the correct boxes.",
        color: "rgb(240,93,36)",
      },
    ],
    hintBox: [
      {
        src: [
          "<s>art</s>",
          "lizard",
          "snorkel",
          "art room",
          "computer room",
          "surf",
          "crocodile",
          "English",
          "zebra",
          "math",
          "schoolyard",
          "swim",
        ],
        width: 800,
        borderColor: "rgb(240,93,36)",
      },
    ],
    questions: [
      {
        title: `
        <div style='position:relative'></div>
        <hintbox id='0' ></hintbox>
        <div style='display:flex; position: absolute; top: 150px; width: -webkit-fill-available'>
          <div style='font-size:22px; border-radius:5px; margin:18px; margin-left:90px; text-align:center ;background-color:rgb(240,93,36); border:none; color:white; padding:6px; position:absolute; top: 52px; left:-44px'>school subjects</div>
          <div style='font-size:22px; border-radius:5px; margin:18px; margin-left:90px; text-align:center ;background-color:rgb(240,93,36); border:none; color:white; padding:6px; position:absolute; top: 52px; left:211px'>school places</div>
          <div style='font-size:22px; border-radius:5px; margin:18px; margin-left:90px; text-align:center ;background-color:rgb(240,93,36); border:none; color:white; padding:6px; position:absolute; top: 52px; left:459px'>beach activities</div>
          <div style='font-size:22px; border-radius:5px; margin:18px; margin-left:90px; text-align:center ;background-color:rgb(240,93,36); border:none; color:white; padding:6px; position:absolute; top: 52px; left:726px'>zoo animals</div>
        </div> 
            
         

        <div style='display:flex;margin-top:40px; line-height:50px; text-align:center' >
        <div style='width:25%; border: 2px solid rgb(202, 202, 202); border-radius:30px; padding-top:40px; margin:20px; padding-bottom:20px'>
              <div style='color:gray'><u>&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;art&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;</u> </div>
              <div> # </div>
							<div> # </div>
          </div>
          <div style='width:25%; border: 2px solid rgb(202, 202, 202); border-radius:30px; padding-top:40px; margin:20px; padding-bottom:20px'>
              <div> # </div>  
              <div> # </div>
              <div> # </div>
          </div>
          
          <div style='width:25%; border: 2px solid rgb(202, 202, 202); border-radius:30px; padding-top:40px; margin:20px; padding-bottom:20px'>
              <div> # </div>
              <div> # </div>
              <div> # </div>
          </div>

          <div style='width:25%; border: 2px solid rgb(202, 202, 202); border-radius:30px; padding-top:40px; margin:20px; padding-bottom:20px'>
              <div> # </div>
              <div> # </div>
              <div> # </div>
          </div>
        </div>
            
          `,
        answer: [
          "English/math",
          "math/English",
          "art room/computer room/schoolyard",
          "art room/computer room/schoolyard",
          "art room/computer room/schoolyard",
          "snorkel/ surf/ swim",
          "snorkel/ surf/ swim",
          "snorkel/ surf/ swim",
          "lizard/ crocodile/ zebra",
          "lizard/ crocodile/ zebra",
          "lizard/ crocodile/ zebra",
        ],
      },
    ],
  },
};

export default json;
