import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 7',
		id: '4WB-U7-P53-E1',
		audio: 'Audios/Track 27.mp3',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page53/E1/Key/E1answerKey.png',
		inputSize: 205,
		textAlign: 'center',
		maxLength: 4,
		stylesTextInput: {
			background: 'none',
			borderBottom: 'none',
			fontSize: 23,
			height: 40,
			width: 70,
		},
		titleQuestion: [
			{
				num: '1',
				title:
					"Write. Listen and check.  <headphone name='27' src='Audios/Track 27/tieude.mp3'></headphone>",
				color: '#234090',
			},
		],
		hintBox: [
			{
				src: ['<s>ng</s>', 'sion', 'ng', 'sure', 'ng', 'sure'],
				width: 600,
				borderColor: 'rgb(250,196,197)',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
        <hintbox id='0' style='border-radius:2px'></hintbox>
        <div style='position: relative; width:100%; text-align:right' >
            <img style='width:20cm' src="img/FriendsPlus/Page53/E1/1.jpg" />    
            
            <div style=" position: absolute; top: 149px; left: 342px;">#</div>
            <div style=" position: absolute; top: 150px; left: 591px;">#</div>

            <div style=" position: absolute; top: 357px; left: 75px;">#</div>
            <div style=" position: absolute; top: 355px; left: 311px;">#</div>
            <div style=" position: absolute; top: 355px; left: 565px;">#</div>
            </div>
            
          `,
				answer: ['sion', 'sure', 'sure', 'ng', 'ng'],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 7',
		id: '4WB-U7-P53-E2',
		audio: 'Audios/Track 28.mp3',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page53/E2/Key/E2answerKey.png',
		// exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
		// inputSize: 1,
		textAlign: 'center',
		maxLength: 1,
		stylesTextInput: {
			background: 'none',
			borderBottom: 'none',
			fontSize: 23,
			height: 20,
			width: 40,
		},
		titleQuestion: [
			{
				num: '2',
				title:
					"Listen and number the pictures. <headphone name='28' src='Audios/Track 28/tieude.mp3'></headphone>",
				color: '#234090',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `

        <div style='position: relative; width:100%; text-align:right' >
            <img style='width:20cm' src="img/FriendsPlus/Page53/E2/1.jpg" />    
            
            <div style=" position: absolute; top: 106px; left: 165px;">#</div>
            <div style=" position: absolute; top: 105px; left: 685px;">#</div>
            </div>
            
          `,
				answer: ['2', '3'],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 7',
		id: '4WB-U7-P53-E3',
		audio: 'Audios/Track 29.mp3',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page53/E3/Key/E3answerKey.png',
		inputSize: 10,
		textAlign: 'center',
		maxLength: 20,
		stylesTextInput: {
			background: 'none',
			// borderBottom: 'none',
			fontSize: 23,
			height: 28,
			width: 140,
		},
		titleQuestion: [
			{
				num: '3',
				title:
					"Write. Listen and chant. <headphone name='29' src='Audios/Track 28/tieude.mp3'></headphone>",
				color: '#234090',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `

        <div style='position: relative; width:100%; text-align:right; display:flex; line-height:70px' >
            <img style='width:5cm; height:8cm' src="img/FriendsPlus/Page53/E3/1.jpg" />    
            <div style='display:flex; flex-direction:column; align-items: flex-start'>
              <div>Can you <sup>1</sup><u style='color:gray'>measure</u> all this <sup>2</sup># ?</div>
              <div>Can you <sup>3</sup># and are you <sup>4</sup># ?</div>
              <div>Can you shout '<sup>5</sup># '? Big <sup>6</sup># !</div>
              <div>Can you <sup>7</sup># a funny <sup>8</sup># ?</div>
            
            </div>
            <img style='width:5cm; height:8cm' src="img/FriendsPlus/Page53/E3/2.jpg" />    
            </div>
            
          `,
				answer: [
					'treasure',
					'swing',
					'strong',
					'bang',
					'explosion',
					'sing',
					'song',
				],
			},
		],
	},
}

export default json
