import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DrawColor from "../../components/ExcerciseTypes/DrawColor";

const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "4WB-U4-P35-E1",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page35/E1/Key/E1answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 500,
    textAlign: "center",
    maxLength: 40,
    // isHiddenCheck: true,
    checkUppercase: true,
    titleQuestion: [
      {
        num: "1",
        title: "Write the sentences with capital letters.",
        color: "#234090",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "solid 2px gray",
      fontSize: 23,
      height: 30,
      width: 500,
    },
    questions: [
      {
        title: `
       <div style='position:relative; font-size: 25px; margin-top: 30px'>

       <div style='margin-bottom: 20px'><b>1.&ensp;</b>we have Vietnamese on tuesdays.&ensp;<u style='color:gray ' >&ensp;We have Vietnamese on Tuesdays.</div>
        
       <div style='margin-bottom: 20px'><b>2.&ensp;</b>what do we have on thursdays?&ensp;<input id='0' width='570px'></input></div>
        <div style='margin-bottom: 20px'><b>3.&ensp;</b>do we have science on mondays? &ensp;<input id='1' width='538px'></input></div>
        <div style='margin-bottom: 20px'><b>4.&ensp;</b>we don’t have school on saturdays.&ensp;<input id='2' width='533px'></input></div>
        <div style='margin-bottom: 20px'><b>5.&ensp;</b>we have history on wednesdays.&ensp;<input id='3' width='563px'></input></div>
       
     
      </div>
            
          `,
        answer: [
          "What do we have on Thursdays?",
          "Do we have science on Mondays?",
          `We don't have school on Saturdays.`,
          "We have history on Wednesdays.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "4WB-U4-P35-E2",
    audio: "",
    video: "",
    // exerciseKey: 'img/FriendsPlus/Page43/key/E2answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: "2",
        title: `
				Circle your favorite room in your school.
					`,
        color: "#23408f",
      },
    ],
    hideBtnFooter: true,
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "10px",
          color: "",
          borderRadius: "50%",
          fontWeight: "700",
        },
        selectWordStyle: { border: "2px solid rgb(240,93,36)" },
        limitSelect: 4,
        listWords: [
          `art_room classroom computer_room schoolyard 
          `, // 0
        ],
        answers: [``],
        initialValue: {},
      },
      Layout: `
	  <div style="border: 2px solid rgb(240,93,36); border-radius: 10px">
		<div
			style="
			background-color: rgb(28, 190, 215);
			border-radius: 40px;
			margin: 10px 10px;
			"
		>
		</div> 
	  	<div style='width: 650px ; margin: 10px 10px;'>
			 <input id='0' type='Circle' style="letter-spacing: 10px;"/> 
		  </div>  
    </div>
			
				`,
    },
  },
  3: {
    // Exercise num
    unit: "Unit 4",
    id: "4WB-U4-P35-E3",
    audio: "",
    video: "",
    // exerciseKey: 'img/FriendsPlus/Page87/E3/Key/E3answerKey.png',
    hideBtnFooter: true,
    component: T6,
    // totalInput: 2,
    textAlign: "center",
    isHiddenCheck: true,
    maxLength: 30,
    titleQuestion: [
      {
        num: "3",
        title: "Draw and write about your favorite room in your school.",
        color: "#234090",
      },
    ],
    textareaStyle: {
      // background: 'gray',
      width: 320,
      fontSize: 21,
      height: 270,
    },
    questionImage: [],
    questions: [
      {
        title: `
        <img  style='width: 20cm' src='img/FriendsPlus/Page35/E3/1.jpg' />
        <div style='position: relative; font-size:21px'>

          <div style="position: absolute; top: -365px; left: 396px; "><textarea id='0' row='3'></div>
        
        </div> 
        `,
        answer: [""],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "4WB-U4-P35-E4",
    audio: "",
    video: "",
    hideBtnFooter: true,
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Tell a friend about your favorite room in your school and what the students do there.",
        color: "#234090",
        // prefix: { icons: ["far fa-comment"] },
      },
    ],
    questionImage: [],
  },
};

export default json;
