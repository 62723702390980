import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Review 3",
    id: "4WB-R3-P68-E1",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page68/E1/Key/E1answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    // inputSize: 350,
    textAlign: "center",
    maxLength: 30,
    isHiddenCheck: true,
    checkUppercase: true,
    titleQuestion: [
      {
        num: "1",
        title:
          '<div style="font-size:23px">Look at the pictures and write. Use <i>because</i>.</div>',
        color: "rgb(238,61,60)",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 22,
      height: 30,
      width: 300,
    },
    questions: [
      {
        title: `
				<div style='max-width: 850px'>
					<img style="width:22cm;" src='img/FriendsPlus/Page68/E1/1.jpg' />
					<div style='display:flex'>

					 <div style='margin-right:50px; line-height: 60px'>
						 <div style=''>
							 <div><b>1. </b>Don’t wear your boots. </div>
							 <div>Why? <u style='color:gray; width:250px'>Because it’s hot.</u></div>
						 </div>
						 <div>
							 <div><b>2. </b>Put on your scarf. </div>
							 <div>Why? # . </div>
						 </div>
					 </div>
				
					 <div style=' line-height: 60px'>
						 <div style=''>
							 <div><b>3. </b>Take your umbrella. </div>
							 <div>Why? # . </div>
						 </div>
						 <div>
							 <div><b>4. </b>Don’t wear your hat. </div>
							 <div>Why? # . </div>
						 </div>
					 </div>
			
			 		</div>
				</div>
			
            
          `,
        answer: [
          `Because it's snowy / Because it's cold`,
          `Because it's rainy`,
          `Because it's windy`,
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Review 3",
    id: "4WB-R3-P68-E2",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page68/E2/Key/E2answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 250,
    textAlign: "center",
    maxLength: 10,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "2",
        title: '<div style="font-size:23px">Write and match.</div>',
        color: "rgb(238,61,60)",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 30,
      width: 100,
    },
    questions: [
      {
        title: `
				<div style='max-width: 750px; position:relative'>
					
					<div style='display:flex; position:relative'>
					 	<div style='margin-right:50px; width:50%'>
							 <div><b>1. </b> g <u style='color:gray; width:100px'>ift</u> card</div>
							 <div><b>2. </b>go to s #</div>
							 <div><b>3. </b> f # a kite</div>
						</div>

					 <div style='width:50%'>
							 <div><b>4. </b> have d #</div>
							 <div><b>5. </b>like p #</div>
							 <div><b>6. </b> m # a snowman</div>
					 </div>
			
			 		</div>
					<div style='position: relative'>
						<img style="width:22cm;" src='img/FriendsPlus/Page68/E2/1.jpg' />
						<div style="position: absolute; top:77px; left: 97px "><input id='5' width='60px' border-bottom='none'></input></div>
						<div style="position: absolute; top:77px; left: 229px"><input id='6' width='60px' border-bottom='none'></input></div>
						<div style="position: absolute; top:77px; left: 361px"><input id='7' width='60px' border-bottom='none'></input></div>
						<div style="position: absolute; top:77px; left: 493px"><input id='8' width='60px' border-bottom='none'></input></div>
						<div style="position: absolute; top:77px; left: 758px"><input id='9' width='60px' border-bottom='none'></input></div>
					</div>
					
				</div>
			
            
          `,
        answer: [
          `chool`,
          `ly`,
          `inner`,
          `resents`,
          `ake`,
          `4`,
          `6`,
          `2`,
          `5`,
          `3`,
        ],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Review 3",
    id: "4WB-R3-P68-E3",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page68/E3/Key/E3answerKey.png",
    inputSize: 200,
    textAlign: "center",
    maxLength: 50,
    isHiddenCheck: true,
    stylesTextInput: {
      // background: "none",
      // borderBottom: 'none',
      fontSize: 24,
      height: 27,
      // textTransform: 'uppercase',
    },

    titleQuestion: [
      {
        num: "3",
        title: "Write the words in the correct boxes.",
        color: "rgb(238,61,60)",
      },
    ],
    hintBox: [
      {
        src: [
          "balloon",
          "candle",
          '<s style="color:gray">cold</s>',
          "get up",
          "go home",
          "go to bed",
          "have breakfast",
          "invite",
          "neighbor",
          "rainy",
          "sunny",
          "windy",
        ],
        width: 800,
        borderColor: "rgb(238,61,60)",
      },
    ],
    questions: [
      {
        title: `
        <div style='position:relative'></div>
        <hintbox id='0' ></hintbox>
        <div style='display:flex; position: absolute; top: 199px; width: -webkit-fill-available'>
          <div style='font-size:22px; border-radius:5px; margin:18px; margin-left:90px; text-align:center ;background-color:rgb(222,46,57); border:none; color:white; padding:6px; position:absolute; top: 52px; left:-5px'>weather</div>
          <div style='font-size:22px; border-radius:5px; margin:18px; margin-left:90px; text-align:center ;background-color:rgb(222,46,57); border:none; color:white; padding:6px; position:absolute; top: 52px; left:211px'>everyday activities</div>
          <div style='font-size:22px; border-radius:5px; margin:18px; margin-left:90px; text-align:center ;background-color:rgb(222,46,57); border:none; color:white; padding:6px; position:absolute; top: 52px; left:510px'>special days</div>
        </div> 
            
         

        <div style='display:flex;margin-top:40px; line-height:50px; text-align:center' >
        <div style='width:30%; border: 2px solid rgb(202, 202, 202); border-radius:30px; padding-top:40px; margin:20px; padding-bottom:20px'>
              <div style='color:gray'><u>&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;cold&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;</u> </div>
              <div> # </div>
							<div> # </div>
							<div> # </div>
          </div>
          <div style='width:30%; border: 2px solid rgb(202, 202, 202); border-radius:30px; padding-top:40px; margin:20px; padding-bottom:20px'>
              <div> # </div>  
              <div> # </div>
              <div> # </div>
							<div> # </div>
          </div>
          
          <div style='width:30%; border: 2px solid rgb(202, 202, 202); border-radius:30px; padding-top:40px; margin:20px; padding-bottom:20px'>
              <div> # </div>
              <div> # </div>
              <div> # </div>
							<div> # </div>
          </div>
        </div>
            
          `,
        answer: [
          "rainy/sunny/windy",
          "rainy/sunny/windy",
          "rainy/sunny/windy",
          "get up/go home/go to bed/have breakfast",
          "get up/go home/go to bed/have breakfast",
          "get up/go home/go to bed/have breakfast",
          "get up/go home/go to bed/have breakfast",
          "balloon/candle/invite/neighbor",
          "balloon/candle/invite/neighbor",
          "balloon/candle/invite/neighbor",
          "balloon/candle/invite/neighbor",
        ],
      },
    ],
  },
};

export default json;
