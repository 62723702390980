import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "4WB-U2-P21-E1",
    audio: "Audios/Track 10.mp3",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page21/E1/Key/E1answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 250,
    textAlign: "center",
    maxLength: 30,

    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and write. <headphone name='10' src='Audios/Track 10.mp3'></headphone> ",
        color: "#234090",
      },
    ],

    questions: [
      {
        title: `
       
       <div style='position:relative'>

        <div style='display:flex; margin-top:20px; line-height:50px; width:100%' >
          <div>
              <div ><b>1.</b>&ensp; Dad works at:<u style='color:gray; padding-left:200px; text-align:center' > a zoo</div>
              <div style='display:flex'><b>2.</b>&ensp;Takes care of:<div style='padding-left:202px'>#</div></div>
              <div style='display:flex'><b>3.</b>&ensp; Dad’s favorite animals:<div style='padding-left:102px'>#</div></div>
              <div style='display:flex'><b>4.</b>&ensp; Number of lions in the zoo:<div style='padding-left:59px'>#</div></div>
              <div style='display:flex'><b>5.</b>&ensp; The girl’s favorite animals:<div style='padding-left:66px'>#</div></div>
          </div>
          <img style='width:8cm; height: 8cm' src="img/FriendsPlus/Page21/E1/1.jpg" />
        </div>
     
      </div>
            
          `,
        answer: ["big animals", "lions", "eleven", "birds"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "4WB-U2-P21-E2",
    audio: "",
    video: "",
    // exerciseKey: 'img/FriendsPlus/Page43/key/E2answerKey.png',
    component: Circle_Write,

    titleQuestion: [
      {
        num: "2",
        title: `
				Circle the places where your family works.
					`,
        color: "#23408f",
      },
    ],
    hideBtnFooter: true,
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "10px",
          color: "",
          borderRadius: "50%",
          fontWeight: "400",
        },
        selectWordStyle: { border: "2px solid rgb(117,186,117)" },
        limitSelect: 7,
        listWords: [
          `hospital school airport police_station fire_station &ensp;&ensp;&ensp;&ensp;&ensp;&ensp; store farm station bank office 
          `, // 0
        ],
        answers: [],
        initialValue: {},
      },
      Layout: `
			

	  <div style="border: 2px solid rgb(117,186,117); border-radius: 10px">
		<div
			style="
			background-color: rgb(28, 190, 215);
			border-radius: 40px;
			margin: 10px 10px;
			"
		>
		</div> 
	  	<div style='width: 650px;margin: 10px 10px;color:gray'>
			 <input id='0' type='Circle' style="letter-spacing: 10px;"/> 
		  </div>  
    </div>
			
				`,
    },
  },
  3: {
    // Exercise num
    unit: "Unit 2",
    id: "4WB-U2-P21-E3",
    audio: "",
    video: "",
    // exerciseKey: 'img/FriendsPlus/Page87/E3/Key/E3answerKey.png',
    hideBtnFooter: true,
    component: T6,
    // totalInput: 2,
    textAlign: "center",
    isHiddenCheck: true,
    maxLength: 30,
    titleQuestion: [
      {
        num: "3",
        title: "Draw and write about a family member’s job.",
        color: "#234090",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 20,
      height: 22,
      // textTransform: 'uppercase',
    },
    textareaStyle: {
      // background: 'gray',
      width: 385,
      fontSize: 21,
      height: 100,
    },
    questionImage: [],
    questions: [
      {
        title: `
        <img  style='width: 22cm' src='img/FriendsPlus/Page21/E3/1.jpg' />
        <div style='position: relative; font-size:21px'>
					<div style="position: absolute; top: -295px; left: 449px; "><input id='0' width='100px'></div>
					<div style="position: absolute; top: -295px; left: 667px; "><input id='1' width='120px'></div>

          <div style="position: absolute; top: -193px; left: 413px; "><textarea id='2' row='3'></div>
        
        </div> 
        `,
        answer: [""],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "4WB-U2-P21-E4",
    audio: "",
    video: "",
    hideBtnFooter: true,
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: "Ask and answer about where your family works.",
        color: "#234090",
        // prefix: { icons: ["far fa-comment"] },
      },
    ],
    questionImage: [],
  },
};

export default json;
