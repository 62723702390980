import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 10',
		id: '4WB-U10-P74-E1',
		audio: '',
		video: '',
		component: T6,
		component: MatchDots,
		exerciseKey: 'img/FriendsPlus/Page74/E1/Key/E1answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title: 'Match.',
				color: '#2E479B',
			},
		],
		question: {
			DrawLines: {
				multipleLine: true,
				boxMatch: [
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '142px',
							left: '93px',
							width: 136,
							height: 36,
							// backgroundColor: "blue",
							// border: "1px solid",
						},
					}, //0
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '199px',
							left: '93px',
							width: 136,
							height: 36,
							// backgroundColor: "red",
							// border: "1px solid",
						},
					}, //1
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '254px',
							left: '93px',
							width: 136,
							height: 36,
							// backgroundColor: "yellow",
							// border: "1px solid",
						},
					}, //2
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '59px',
							left: '559px',
							width: 130,
							height: 37,
							// backgroundColor: "pink",
							// border: "1px solid",
						},
					}, //3
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '113px',
							left: '566px',
							width: 154,
							height: 37,
							// backgroundColor: "blue",
							// border: "1px solid",
						},
					}, //4
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '168px',
							left: '538px',
							width: 100,
							height: 37,
							// backgroundColor: "red",
							// border: "1px solid",
						},
					}, //5
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '221px',
							left: '538px',
							width: 100,
							height: 37,
							// backgroundColor: "yellow",
							// border: "1px solid",
						},
					}, //6
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '278px',
							left: '538px',
							width: 100,
							height: 37,
							// backgroundColor: "black",
							// border: "1px solid",
						},
					}, //7
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '333px',
							left: '556px',
							width: 130,
							height: 37,
							// backgroundColor: "white",
							// border: "1px solid",
						},
					}, //8
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '673px',
							left: '478px',
							width: 318,
							height: 90,
							// backgroundColor: "black",
							// border: "1px solid",
						},
					}, //9
				],
				answers: ['0-6', '1-5', '2-4', '1-7', '2-8'],
				initialValue: [],
			},
			Layout: `
      <img style='padding-top:30px; margin-bottom: 50px' src="img/FriendsPlus/Page74/E1/1.jpg" />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
      `,
		},
	},
	2: {
		// Exercise num
		unit: 'Unit 10',
		id: '4WB-U10-P74-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page74/E2/Key/E2answerKey.png',
		isHiddenCheck:true,
		titleQuestion: [
			{
				num: '2',
				title: 'Read the text in the Student Book. Fill in the correct circle.',
				color: '#234090',
			},
		],
		totalInput: 3,
		component: DesignUnderLine,

		// isAllowSubmit: false,
		questionImage: [	
			[
				{ url:  'img/FriendsPlus/Page74/E2/1.jpg' },],[		
				{ url:  'img/FriendsPlus/Page74/E2/2.jpg' },	
				{ url:  'img/FriendsPlus/Page74/E2/3.jpg',input:1 },],[	
				{ url:  'img/FriendsPlus/Page74/E2/4.jpg' },	
				{ url:  'img/FriendsPlus/Page74/E2/5.jpg',input:1 },],[	
				{ url:  'img/FriendsPlus/Page74/E2/6.jpg' },	
				{ url:  'img/FriendsPlus/Page74/E2/7.jpg',input:1,isCorrect:true, },],[	
				{ url:  'img/FriendsPlus/Page74/E2/8.jpg' },],[	
				{ url:  'img/FriendsPlus/Page74/E2/9.jpg' },	
				{ url: 'img/FriendsPlus/Page74/E2/10.jpg',input:2 },	
				{ url: 'img/FriendsPlus/Page74/E2/11.jpg' },	
				{ url: 'img/FriendsPlus/Page74/E2/12.jpg',input:3 },],[	
				{ url: 'img/FriendsPlus/Page74/E2/13.jpg' },	
				{ url: 'img/FriendsPlus/Page74/E2/14.jpg',input:2,isCorrect:true, },	
				{ url: 'img/FriendsPlus/Page74/E2/15.jpg' },	
				{ url: 'img/FriendsPlus/Page74/E2/16.jpg',input:3 },],[	
				{ url: 'img/FriendsPlus/Page74/E2/17.jpg' },	
				{ url: 'img/FriendsPlus/Page74/E2/18.jpg',input:2 },	
				{ url: 'img/FriendsPlus/Page74/E2/19.jpg' },	
				{ url: 'img/FriendsPlus/Page74/E2/20.jpg',input:3,isCorrect:true, },],[	
				{ url: 'img/FriendsPlus/Page74/E2/21.jpg' },],[	
				{ url: 'img/FriendsPlus/Page74/E2/22.jpg' },	
				{ url: 'img/FriendsPlus/Page74/E2/23.jpg',input:4 },	
				{ url: 'img/FriendsPlus/Page74/E2/24.jpg' },	
				{ url: 'img/FriendsPlus/Page74/E2/25.jpg',input:5,isCorrect:true, },],[	
				{ url: 'img/FriendsPlus/Page74/E2/26.jpg' },	
				{ url: 'img/FriendsPlus/Page74/E2/27.jpg',input:4 },	
				{ url: 'img/FriendsPlus/Page74/E2/28.jpg' },],[	
				{ url: 'img/FriendsPlus/Page74/E2/29.jpg' },	
				{ url: 'img/FriendsPlus/Page74/E2/30.jpg',input:4,isCorrect:true, },	
				{ url: 'img/FriendsPlus/Page74/E2/31.jpg' },	
				{ url: 'img/FriendsPlus/Page74/E2/32.jpg',input:5 },],[	
				{ url: 'img/FriendsPlus/Page74/E2/33.jpg' },	
				{ url: 'img/FriendsPlus/Page74/E2/34.jpg',input:5 },	
				
				
				
		],
			
		],
	},
}

export default json
