import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "4WB-U4-P30-E1",
    audio: "",
    // hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page30/E1/Key/E1answerKey.png',
    isHiddenCheck: true,
    component: T6,
    inputSize: 550,
    textAlign: "left",
    maxLength: 108,
    fontSize: 23,
    titleQuestion: [
      {
        num: "1",
        title: "Write the words.",
        color: "#234090",
      },
    ],
    questions: [
      {
        title: `	
       
            <div> <img style='width:22cm' src='img/FriendsPlus/Page30/E1/1.jpg' /> </div>
            <div>
            <i style='font-size:18px; color: rgb(245,54,92)'>*Write the selected words in the sorted order of the list above and between words seperated by commas (,).</i></div>
            <div style='display: flex; margin-top: 30px'>
            <b>Answer:&ensp;</b><i style='color: gray; font-size: 23px'>art, </i>#
            
            </div>
          `,
        answer: [`math, English, P.E., Vietnamese / English, Vietnamese, P.E., math / P.E., Vietnamese, math, English / Vietnamese, P.E., math, English / Vietnamese, English, math, P.E. / English, P.E., math, Vietnamese / P.E., math, English, Vietnamese / math, P.E., English, Vietnamese / Vietnamese, math, P.E., English / English, math, Vietnamese, P.E. / P.E., math, Vietnamese, English / Vietnamese, English, P.E., math / English, math, P.E., Vietnamese / math, Vietnamese, English, P.E. / Vietnamese, P.E., English, math / English, Vietnamese, math, P.E. / math, P.E., Vietnamese, English / Vietnamese, math, English, P.E. / P.E., English, Vietnamese, math / Vietnamese, English, P.E., math / English, P.E., Vietnamese, math / math, English, Vietnamese, P.E. / P.E., math, English, Vietnamese / English, Vietnamese, P.E., math / Vietnamese, P.E., math, English / English, math, P.E., Vietnamese / math, Vietnamese, P.E., English / Vietnamese, math, P.E., English`],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 4",
    id: "4WB-U4-P30-E2",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page30/E2/Key/E2answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 100,
    textAlign: "center",
    maxLength: 20,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "2",
        title: "Now write.",
        color: "#234090",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 24,
      height: 50,
      width: 280,
    },
    questions: [
      {
        title: `
       <div style='position:relative'>
       <img src='img/FriendsPlus/Page30/E2/1.jpg' />

        <div style=" position: absolute; top: 156px; left: 356px; ">#</div>
        <div style=" position: absolute; top: 156px; left: 734px; ">#</div>
        <div style=" position: absolute; top: 395px; left: 222px; ">#</div>
        <div style=" position: absolute; top: 395px; left: 587px; ">#</div>
     
      </div>
            
          `,
        answer: ["Vietnamese", "P.E.", "English", "math"],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 4",
    id: "4WB-U4-P30-E3",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page30/E3/Key/E3answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 100,
    textAlign: "left",
    maxLength: 45,
    isHiddenCheck: true,
    checkUppercase: true,
    titleQuestion: [
      {
        num: "3",
        title: " Order the words.",
        color: "#234090",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 23,
      height: 27,
      width: 300,
    },
    questions: [
      {
        title: `
       <div style='position:relative'>
       <img style="width:22cm; height:9cm" src='img/FriendsPlus/Page30/E3/1.jpg' />

        <div style=" position: absolute; top: 123px; left: 514px"><input id='0' width='470px'></input></div>
        <div style=" position: absolute; top: 175px; left: 581px"><input id='1' width='410px'></input></div>
        <div style=" position: absolute; top: 223px; left: 468px"><input id='2' width='520px'></input></div>
        <div style=" position: absolute; top: 274px; left: 488px"><input id='3' width='500px'></input></div>
     
      </div>
            
          `,
        answer: [
          "I have Vietnamese on Friday.",
          "Do you have art on Wednesday?",
          "I have P.E. on Thursday.",
          "When do you have music?",
        ],
      },
    ],
  },
};

export default json;
