import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
const json = {
  1: {
    // Exercise num
    unit: "Unit 5",
    id: "4WB-U5-P37-E1",
    video: "",
    exerciseKey: "img/FriendsPlus/Page37/E1/Key/E1answerKey.png",

    component: DesignUnderLine,
    totalInput: 3,
    titleQuestion: [
      {
        num: "1",
        title: "Look at the picture. Read and check (✓).",
        color: "#234090",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page37/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page37/E1/2.jpg" },
        { url: "img/FriendsPlus/Page37/E1/3.jpg", input: 1 },
      ],
      [{ url: "img/FriendsPlus/Page37/E1/4.jpg" }],
      [
        { url: "img/FriendsPlus/Page37/E1/5.jpg" },
        { url: "img/FriendsPlus/Page37/E1/6.jpg" },
        { url: "img/FriendsPlus/Page37/E1/7.jpg" },
        { url: "img/FriendsPlus/Page37/E1/8.jpg", input: 1, isCorrect: true },
      ],
      [{ url: "img/FriendsPlus/Page37/E1/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page37/E1/10.jpg" },
        { url: "img/FriendsPlus/Page37/E1/11.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page37/E1/12.jpg" },
        { url: "img/FriendsPlus/Page37/E1/13.jpg", input: 3, isCorrect: true },
      ],
      [{ url: "img/FriendsPlus/Page37/E1/14.jpg" }],
      [
        { url: "img/FriendsPlus/Page37/E1/15.jpg" },
        { url: "img/FriendsPlus/Page37/E1/16.jpg", input: 2 },
        { url: "img/FriendsPlus/Page37/E1/17.jpg" },
        { url: "img/FriendsPlus/Page37/E1/18.jpg", input: 3 },
      ],
      [{ url: "img/FriendsPlus/Page37/E1/19.jpg" }],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 5",
    id: "4WB-U5-P37-E2",
    audio: "Audios/Track 19.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page37/E2/Key/E2answerKey.png",

    component: DesignUnderLine,
    totalInput: 3,
    titleQuestion: [
      {
        num: "2",
        title:
          "<div style='font-size: 24px'>Listen and check (✓). <headphone name='19' src='Audios/Track 19.mp3'></headphone></div>",
        color: "#234090",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page37/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page37/E2/2.jpg" },
        { url: "img/FriendsPlus/Page37/E2/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page37/E2/4.jpg" },
        { url: "img/FriendsPlus/Page37/E2/5.jpg", input: 1 },
        { url: "img/FriendsPlus/Page37/E2/6.jpg" },
        { url: "img/FriendsPlus/Page37/E2/7.jpg", input: 1, isCorrect: true },
      ],
      //Row 2
      [{ url: "img/FriendsPlus/Page37/E2/8.jpg" }],
      [
        { url: "img/FriendsPlus/Page37/E2/9.jpg" },
        { url: "img/FriendsPlus/Page37/E2/10.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page37/E2/11.jpg" },
        { url: "img/FriendsPlus/Page37/E2/12.jpg", input: 2 },
        { url: "img/FriendsPlus/Page37/E2/13.jpg" },
        { url: "img/FriendsPlus/Page37/E2/14.jpg", input: 2 },
      ],
      //Row 3
      [{ url: "img/FriendsPlus/Page37/E2/15.jpg" }],
      [
        { url: "img/FriendsPlus/Page37/E2/16.jpg" },
        { url: "img/FriendsPlus/Page37/E2/17.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page37/E2/18.jpg" },
        { url: "img/FriendsPlus/Page37/E2/19.jpg", input: 3 },
        { url: "img/FriendsPlus/Page37/E2/20.jpg" },
        { url: "img/FriendsPlus/Page37/E2/21.jpg", input: 3 },
      ],
    ],
  },
};

export default json;
