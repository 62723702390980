import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 5",
    id: "4WB-U5-P40-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page40/E1/Key/E1answerKey.png",
    isHiddenCheck:true,
    component: DesignUnderLine,
    totalInput: 3,
    titleQuestion: [
      {
        num: "1",
        title: "Look and circle the correct words.",
        color: "#234090",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [

      // Q1
      [
        { url: "img/FriendsPlus/Page40/E1/1.jpg",input:1 },
        { url: "img/FriendsPlus/Page40/E1/2.jpg" },
        { url: "img/FriendsPlus/Page40/E1/3.jpg",input:2,isCorrect:true, },
        { url: "img/FriendsPlus/Page40/E1/4.jpg" },],[
        { url: "img/FriendsPlus/Page40/E1/6.jpg" },
        { url: "img/FriendsPlus/Page40/E1/7.jpg",input:3 },],[
        { url: "img/FriendsPlus/Page40/E1/8.jpg" },],[
        { url: "img/FriendsPlus/Page40/E1/9.jpg",input:4 },
        { url: "img/FriendsPlus/Page40/E1/10.jpg" },
        { url: "img/FriendsPlus/Page40/E1/11.jpg",input:5,isCorrect:true, },
        { url: "img/FriendsPlus/Page40/E1/12.jpg" },],[
        { url: "img/FriendsPlus/Page40/E1/14.jpg" },
        { url: "img/FriendsPlus/Page40/E1/15.jpg" ,input:6,isCorrect:true,},
        { url: "img/FriendsPlus/Page40/E1/16.jpg" },
        { url: "img/FriendsPlus/Page40/E1/17.jpg",input:7 },
        ],
 
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB7-U5-P40-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page40/E2/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "Read the text in the Student Book. Write <i>T</i> (true) or <i>F</i> (false).",
        color: "#234090",
      },
    ],
    selectStyle: {
      color: "#00ADFE",
      width: 60,
      textAlign: "center",
      fontSize: 22,
      height: 30,
    },
    selectOptionRandom: true,
    selectOptionValues: ["T", "F"],
    questionImage: [],
    questions: [
      {
        title: `

        <div style='position: relative; padding-top:30px; display:flex; line-height: 50px'>
          <div>
            <div><b>1. </b>Minh is in Phan Thiet.<u style='color:gray; margin-left:300px; width: 60px'>F</u></div>
            <div><b>2. </b>The sand is red.</div>
            <div><b>3. </b>Quang is sitting on the beach.</div>
            <div><b>4. </b>There are seafood restaurants.</div>
            <div><b>5. </b>Quang’s cousins are playing with a ball.</div>
          </div>
          <div>
          
          <div style=" position: absolute; top: 89px; left:550px; "><select id=0></select></div>
          <div style=" position: absolute; top: 139px; left:550px; "><select id=1></select></div>
          <div style=" position: absolute; top: 188px; left:550px; "><select id=2></select></div>
          <div style=" position: absolute; top: 234px; left:550px; "><select id=3></select></div>
          </div>
          
        </div> 
        `,
        answer: ["F", "T", "T", "F"],
      },
    ],
  },
};

export default json;
