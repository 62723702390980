import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    // Exercise num
    unit: "Unit 7",
    id: "4WB-U7-P51-E1",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page51/E1/Key/E1answerKey.png",
    inputSize: 500,
    textAlign: "left",
    checkUppercase: true,
    maxLength: 60,
    isHiddenCheck: true,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 22,
      height: 29,
    },
    titleQuestion: [
      {
        num: "1",
        title: "Order the words.",
        color: "#234090",
      },
    ],

    questions: [
      {
        title: `
       <div style='display:flex'>
       <img style='width:15cm' src="img/FriendsPlus/Page51/E1/1.jpg" />
        <div style='font-size:22px; margin-top:20px; line-height:40px; width:100%' >
          <div style='padding-bottom: 15px;'><u style='color:rgb(174,176,177)'>What’s the weather like?</u></div>
          <div style='padding-bottom: 15px;'>#</div>
          <div style='padding-bottom: 15px;'>#</div>
          <div style='padding-bottom: 15px;'>#</div>
          <div style='padding-bottom: 15px;'>#</div>
          <div style='padding-bottom: 15px;'>#</div>
        </div>
       </div>
            
          `,
        answer: [
          "Wear your scarf because it's cold.",
          "Fly a kite because it's windy.",
          "Don't go out because it's rainy.",
          "Drink water because it's hot.",
          "Close the window because it's windy.",
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 7",
    id: "4WB-U1-P51-E2",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page51/E2/Key/E2answerKey.png",
    inputSize: 40,
    textAlign: "center",
    maxLength: 20,
    stylesTextInput: {
      background: "none",
      fontSize: 22,
      height: 30,
      width: 250,
      borderBottom: "none",
    },
    titleQuestion: [
      {
        num: "2",
        title: "Write.",
        color: "#234090",
      },
    ],
    hintBox: [
      {
        src: ["it's rainy", "it's snowy", "<s>it's windy</s>", "it's hot"],
        width: 600,
        borderColor: "rgb(246,201,201)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <hintbox id='0' style='border-radius:2px'></hintbox>
        <div style='position:relative'>
        <img style='width:18cm' src="img/FriendsPlus/Page51/E2/1.jpg"/>
        <div style="position: absolute; top:436px; left: 23px; "><input id='0' ></input></div>
        <div style="position: absolute; top:183px; left: 375px; "><input id='1' ></input></div>
        <div style="position: absolute; top:436px; left: 375px; "><input id='2' ></input></div>
        </div>
            
          `,
        answer: ["it's snowy", "it's rainy", "it's hot"],
      },
    ],
  },
};

export default json;
