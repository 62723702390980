import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
const json = {
	1: {
		// Exercise num
		unit: 'Unit 12',
		id: '4WB-U12-P83-E1',
		// audio: 'Audios/Track 26.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page83/E1/Key/E1answerKey.png',

		component: DesignUnderLine,
		totalInput: 5,
		titleQuestion: [
			{
				num: '1',
				title: 'Look. Read and check (✓) the correct sentence.',
				color: '#234090',
			},
		],
		// isAllowSubmit: false,
		questionImage: [
			// Row 1
			[{ url: 'img/FriendsPlus/Page83/E1/1.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page83/E1/2.jpg' },
				{ url: 'img/FriendsPlus/Page83/E1/3.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page83/E1/4.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page83/E1/5.jpg' },
				{ url: 'img/FriendsPlus/Page83/E1/6.jpg', input: 1, isCorrect: true },
				{ url: 'img/FriendsPlus/Page83/E1/7.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page83/E1/8.jpg' },
				{ url: 'img/FriendsPlus/Page83/E1/9.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page83/E1/10.jpg' },
				{ url: 'img/FriendsPlus/Page83/E1/11.jpg', input: 3, isCorrect: true },
				{ url: 'img/FriendsPlus/Page83/E1/12.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page83/E1/13.jpg' },
				{ url: 'img/FriendsPlus/Page83/E1/14.jpg', input: 2, isCorrect: true },
				{ url: 'img/FriendsPlus/Page83/E1/15.jpg' },
				{ url: 'img/FriendsPlus/Page83/E1/16.jpg', input: 3 },
				{ url: 'img/FriendsPlus/Page83/E1/17.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page83/E1/18.jpg' },
				{ url: 'img/FriendsPlus/Page83/E1/19.jpg', input: 4, isCorrect: true },
				{ url: 'img/FriendsPlus/Page83/E1/20.jpg' },
				{ url: 'img/FriendsPlus/Page83/E1/21.jpg', input: 5, isCorrect: true },
				{ url: 'img/FriendsPlus/Page83/E1/22.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page83/E1/23.jpg' },
				{ url: 'img/FriendsPlus/Page83/E1/24.jpg', input: 4 },
				{ url: 'img/FriendsPlus/Page83/E1/25.jpg' },
				{ url: 'img/FriendsPlus/Page83/E1/26.jpg', input: 5 },
				{ url: 'img/FriendsPlus/Page83/E1/27.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page83/E1/28.jpg' }],
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 12',
		id: '4WB-U12-P83-E2',
		// audio: 'Audios/Track 27.mp3',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page83/E2/Key/E2answerKey.png',
		inputSize: 205,
		textAlign: 'center',
		maxLength: 8,
		stylesTextInput: {
			background: 'none',
			// borderBottom: 'none',
			fontSize: 22,
			height: 27,
			width: 200,
		},
		titleQuestion: [
			{
				num: '2',
				title:
					'Look. Complete the sentences with <i>was, wasn’t, were</i> or <i>weren’t</i>.',
				color: '#234090',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
        <div style='' >
          <img style='width:20cm' src="img/FriendsPlus/Page83/E2/1.jpg" />    
          <div>Yesterday ...</div>
          <div><b>1. </b>he <u style='color:gray'>wasn’t</u> in the living room in the morning.</div>
          <div><b>2. </b>he # hungry at breakfast.</div>
					<div><b>3. </b>they # in the swimming pool.</div>
					<div><b>4. </b>he # with his grandparents for lunch.</div>
					<div><b>5. </b>his parents # at home for dinner.</div>
					<div><b>6. </b>they # sad.</div>
        
        </div>  
          `,
				answer: ['was', 'were', `wasn't`, 'were', `weren't`],
			},
		],
	},
}

export default json
